import styled from 'styled-components';
import theme from 'styles/theme';
import { useRef } from 'react';

interface SaveDetailsFloatingButtonWrapperProps {
  [key: string]: any;
}
const SaveDetailsFloatingButtonWrapper = styled.div<SaveDetailsFloatingButtonWrapperProps>`
 ${(props) =>
   props.lessThanSmallPageBottom
     ? `
 position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  `
     : `position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;`}
  

  height: 60px;
  background-color: ${(props) =>
    props.lessThanSmallPageBottom && (props.loading || props.disabled)
      ? '#e3e4e7'
      : theme.primaryColor};

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: none;
    border: 0;
    color: #ffffff;
    font-size: 16px;

    :disabled {
      cursor: not-allowed;
      background-color: #e3e4e7;
      color: #8c8c8c;
  }
`;

const SaveDetailsFloatingButton = ({
  width,
  loading = false,
  disabled = false,
  onClick,
  btnText,
  lessThanSmall,
  lessThanSmallPageBottom,
}: {
  width?: any;
  loading?: boolean;
  disabled?: boolean;
  onClick?: Function;
  btnText?: string;
  lessThanSmall?: boolean;
  lessThanSmallPageBottom?: boolean;
}) => {
  const buttonRef = useRef(null);
  return (
    <div style={{ position: 'relative' }}>
      {/*// @ts-ignore */}
      {lessThanSmallPageBottom && <div style={{ height: buttonRef?.current?.clientHeight }} />}
      <SaveDetailsFloatingButtonWrapper
        ref={buttonRef}
        className='saveDetails'
        style={
          lessThanSmall
            ? { position: 'absolute', bottom: 0, width: '100%', borderRadius: '0px' }
            : {
                width: lessThanSmallPageBottom ? '100%' : width ? width : '66.7%',
                backgroundColor: btnText === 'Skip to Secondary Benaficiaries' ? '#B0C6E3' : '',
                ...(lessThanSmallPageBottom
                  ? {
                      boxSizing: 'border-box',
                      padding: '12px',
                      //borderRadius: '12px',
                    }
                  : {}),
              }
        }
        onClick={() => (onClick ? onClick() : {})}
        lessThanSmallPageBottom={lessThanSmallPageBottom}
        loading={loading}
        disabled={disabled}
      >
        <button
          type='submit'
          disabled={disabled}
          style={{ color: btnText === 'Skip to Secondary Beneficiaries' ? '#1D2939' : '' }}
        >
          {loading ? 'Please Wait...' : btnText ? btnText : 'Confirm'}
        </button>
      </SaveDetailsFloatingButtonWrapper>
    </div>
  );
};

export default SaveDetailsFloatingButton;
