import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from 'styles/Breakpoints';

import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';

import AddOnDrawer from '../../common/AddOnDrawer';
import AddOnPopup from '../../common/AddOnPopup';

import { AddOn, FeatureData } from 'utils/choosePlanTypes';

import assetTypeIcon from 'assets/svg/assetType-financial-rupee.svg';
import evenIcon from 'assets/svg/even_icon.svg';
import basicWillIcon from 'assets/svg/faq-basicWill-V2-no-bg.svg';
import talkToExpertIcon from 'assets/svg/talkToExpert-no-bg.svg';
import { Pages } from 'routes';
import { useNavigate } from 'react-router-dom';
import { selectYellowPlusPlansForAgeLocationConfig } from 'store/user/selectors';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { fetchYellowPlusConfig } from 'store/user/slice';
import { useDispatch } from 'react-redux';

const oneSolutionCardsContent = [
  {
    icon: basicWillIcon,

    color: '#FFE171',
    text: 'Create your Will quickly and easily',
    subText: '',
  },
  {
    icon: talkToExpertIcon,

    color: '#FAD3D1',
    text: 'Schedule a call with legal experts',
    subText: '',
  },
  {
    icon: assetTypeIcon,

    color: '#D4D1FA',
    text: 'Asset transfer assistance for your family',
    subText: '',
  },
  {
    icon: evenIcon,

    color: '#DDEEFF',
    text: 'Get access to OPD insurance',
    subText: '(covers doctors visits and diagnostics)',
  },
];

const OneSolutionCardsWrapper = styled.div`
  .cardHolder {
    display: flex;
    margin-top: 16px;
    gap: 16px;

    .oneSolutionCards {
      cursor: pointer;

      display: flex;
      flex-direction: column;

      align-items: center;
      text-align: center;

      .textContainerCards {
        margin: 10px 8px 14px 8px;
        p {
          font-size: 14px;
        }

        .subText {
          font-size: 10px;
          color: rgba(16, 24, 40, 0.64);
        }
      }

      overflow: hidden;
      .avatar {
        width: 102%;
        height: 95px;

        border-radius: 0rem 0rem 10rem 10rem;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .cardHolder {
      flex-direction: column;
      .oneSolutionCards {
        height: 70px;
        flex-direction: row;
        text-align: left;

        .textContainerCards {
          margin: 12px 8px 12px 8px;
        }

        .avatar {
          min-width: 90px;
          width: 90px;
          flex-direction: column;
          border-radius: 0rem 10rem 10rem 0rem;
        }
      }
    }
  }
`;

const OneSolutionCards = ({
  setShowModal = () => {},
}:{
  setShowModal?: Function
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const yellowPlusConfig = useAppSelector(selectYellowPlusPlansForAgeLocationConfig);
  const [addOnPopupIndex, setAddOnPopupIndex] = useState<string | FeatureData | null>(null);

  const datadialog = yellowPlusConfig?.addOns?.map((addOns) =>
    [addOns].map((data) =>
      data?.priceListFeatureText?.body.map((point: any) => point?.viewMorePopup),
    ),
  );

  console.log(datadialog);

  useEffect(() => {
    dispatch(fetchYellowPlusConfig());
  }, []);

  return (
    <OneSolutionCardsWrapper>
      <Grid item lg={12} md={12} sm={12} xs={12} className='cardHolder'>
        {/* Card-1 */}
        <Grid
          className='oneSolutionCards'
          sx={{
            width: '100%',
            border: `2px solid ${oneSolutionCardsContent[0].color}`,
            backgroundColor: `${oneSolutionCardsContent[0].color}`,
            borderRadius: '12px',
            '&:hover': { backgroundColor: `${oneSolutionCardsContent[0].color}` },
          }}
          onClick={() => {
            (window as any).webengage.track("Create Will Clicked");
            navigate(Pages.WILL_EDITOR);
          }}
        >
          <div
            className='avatar'
            style={{ backgroundColor: `${oneSolutionCardsContent[0].color}` }}
          >
            <img src={oneSolutionCardsContent[0].icon} />
          </div>
          <span className='textContainerCards'>
            <p>{oneSolutionCardsContent[0].text}</p>
            <p className='subText'>{oneSolutionCardsContent[0].subText}</p>
          </span>
        </Grid>
        {/* Card-2 */}
        <Grid
          className='oneSolutionCards'
          sx={{
            width: '100%',
            border: `2px solid ${oneSolutionCardsContent[1].color}`,
            borderRadius: '12px',
            '&:hover': { backgroundColor: `${oneSolutionCardsContent[1].color}` },
          }}
          onClick={() => setShowModal(true)}
        >
          <div
            className='avatar'
            style={{ backgroundColor: `${oneSolutionCardsContent[1].color}` }}
          >
            <img src={oneSolutionCardsContent[1].icon} />
          </div>
          <span className='textContainerCards'>
            <p>{oneSolutionCardsContent[1].text}</p>
            <p className='subText'>{oneSolutionCardsContent[1].subText}</p>
          </span>
        </Grid>
        {/* Card-3 */}
        <Grid
          className='oneSolutionCards'
          sx={{
            width: '100%',
            border: `2px solid ${oneSolutionCardsContent[2].color}`,
            borderRadius: '12px',
            '&:hover': { backgroundColor: `${oneSolutionCardsContent[2].color}` },
          }}
          onClick={() => setAddOnPopupIndex('smart')}
        >
          <div
            className='avatar'
            style={{ backgroundColor: `${oneSolutionCardsContent[2].color}` }}
          >
            <img src={oneSolutionCardsContent[2].icon} />
          </div>
          <span className='textContainerCards'>
            <p>{oneSolutionCardsContent[2].text}</p>
            <p className='subText'>{oneSolutionCardsContent[2].subText}</p>
          </span>
        </Grid>
        {/* Card-4 */}
        {/* <Grid */}
        {/*   className='oneSolutionCards' */}
        {/*   sx={{ */}
        {/*     width: '100%', */}
        {/*     border: `2px solid ${oneSolutionCardsContent[3].color}`, */}
        {/*     borderRadius: '12px', */}
        {/*     '&:hover': { backgroundColor: `${oneSolutionCardsContent[3].color}` }, */}
        {/*   }} */}
        {/*   onClick={() => setAddOnPopupIndex('even')} */}
        {/* > */}
        {/*   <div */}
        {/*     className='avatar' */}
        {/*     style={{ backgroundColor: `${oneSolutionCardsContent[3].color}` }} */}
        {/*   > */}
        {/*     <img src={oneSolutionCardsContent[3].icon} /> */}
        {/*   </div> */}
        {/*   <span className='textContainerCards'> */}
        {/*     <p>{oneSolutionCardsContent[3].text}</p> */}
        {/*     <p className='subText'>{oneSolutionCardsContent[3].subText}</p> */}
        {/*   </span> */}
        {/* </Grid> */}
      </Grid>
      <AddOnDrawer
        // add_on={
        //   typeof addOnPopupIndex === 'string'
        //     ? yellowPlusConfig?.addOns?.find((addOn) => addOn.id === addOnPopupIndex) ||
        //     yellowPlusConfig?.yellowAddOns?.find((addOn) => addOn.id === addOnPopupIndex)
        //     : ({ popUpFeatureText: addOnPopupIndex } as Partial<AddOn>)
        // }
        // @ts-ignore
        add_on={addOnPopupIndex === "smart" ? smart : addOnPopupIndex} open={lessThanSmall && addOnPopupIndex !== null}
        onClose={() => setAddOnPopupIndex(null)}
      />
      <AddOnPopup
        // add_on={
        //   typeof addOnPopupIndex === 'string'
        //     ? yellowPlusConfig?.addOns?.find((addOn) => addOn.id === addOnPopupIndex) ||
        //     yellowPlusConfig?.yellowAddOns?.find((addOn) => addOn.id === addOnPopupIndex)
        //     : ({ popUpFeatureText: addOnPopupIndex } as Partial<AddOn>)
        // }
        // @ts-ignore
        add_on={addOnPopupIndex === "smart" ? smart : addOnPopupIndex}
        open={!lessThanSmall && addOnPopupIndex !== null}
        onClose={() => {
          setAddOnPopupIndex(null);
        }}
      />
    </OneSolutionCardsWrapper>
  );
};

const smart: Partial<AddOn> = {
  id: "smart",
  popUpFeatureText: {
    header: 'Yellow Smart',
    headerParagraph: "Asset transfer services to support your Executor and family.",
    body: [
      {
        point:
          'Personalised asset claim and transfer assistance for your beneficiaries and/or legal heirs in accordance with your Will or applicable succession laws',
      },
      {
        point:
          'Transmission of all types of assets including property, shares, mutual funds, Provident Fund, bank accounts, fixed deposits and many others',
      },
      {
        point:
          'Drafting of any legal documents and other paperwork required, including a legal heir certificate, succession certificate, probate assistance, etc.',
      },
      { point: 'Real-time progress tracking' },
      {
        point:
          'Yellow’s experienced estate planners, empanelled lawyers, and claims experts are your family’s personal safety net',
      },
    ],
  }
}

export default OneSolutionCards;
