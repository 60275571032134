import { PayloadAction } from '@reduxjs/toolkit';
import {
  AccessRole,
  Asset,
  AuthSlice,
  AuthUser,
  Contact,
  Content,
  Liability,
  PaymentInfo,
  RequestLoader,
  UnloggedInUserSessionData,
  UserNotification,
  UserProfile,
  Will,
  WillRecipientInput
} from '../../utils/types';
import { UserStatus } from './slice';
import { PlanData } from '../../utils/choosePlanTypes';
import { PaymentHistoryResponse } from '../../utils/paymentHistoryTypes';
import { CartAddOn, CartAddOnForOthers, CartData, CouponInput } from 'utils/cartDataTypes';
import { InitiateCartPaymentResponseData } from '../../utils/paymentSummaryTypes';
import { CurrentUserPlansResponse } from '../../utils/currentUserPlansTypes';
import set from 'lodash.set';
import { WillMailRecipient, WillMailRecipientInput } from '../../utils/willmailrecipienttypes';
import { MyCartStates } from '../../Pages/YessPlus/Plans';
export interface SetProfilePayload {
  profile: UserProfile | null;
  accessRole: AccessRole;
  contacts: Array<Contact>;
  will: Will;
  assets: Array<Asset>;
  liabilities: Array<Liability>;
  remainingProfileFields: Array<string>;
  createdAt?: string;
}
import { ConfigResponse } from 'utils/config/interfaces';
const reducers = {
  initiateAuthFetch: (state: AuthSlice) => {
    state.requestStatus = RequestLoader.LOADING;
  },
  setLoggedOutConfig: (state: AuthSlice, action: PayloadAction<any>) => {
    state.loggedOutConfig = JSON.parse(action.payload.data.loggedOutAppConfig);
  },
  addToUserStatus: (state: AuthSlice, action: PayloadAction<UserStatus>) => {
    const status = action.payload;
    if (
      [UserStatus.DETERMINING_AUTH_STATUS, UserStatus.LOGGED_IN, UserStatus.LOGGED_OUT].includes(
        status,
      )
    ) {
      state.userStatus = [status];
    } else if (
      [
        UserStatus.PROFILE_LOADED,
        UserStatus.PROFILE_INCOMPLETE,
        UserStatus.CONFIG_LOADED,
        // UserStatus.YELLOW_PLUS_CONFIG_LOADED,
        UserStatus.WILL_DOC_NOT_CREATED,
        UserStatus.PAYMENT_INCOMPLETE,
        UserStatus.FIRST_WILL_COMPLETED,
        UserStatus.WILL_CREATION_INCOMPLETE,
        UserStatus.YELLOW_PLUS_PLAN_LOADED,
      ].includes(status)
    ) {
      if (state.userStatus.includes(UserStatus.LOGGED_IN)) {
        state.userStatus.push(status);
      } else {
        //TODO: Throw error
        console.error('User status is being set to a wrong value', UserStatus[status]);
      }
    } else if (
      [
        UserStatus.ACTION_SET_PROFILE_COMPLETED,
        UserStatus.ACTION_SET_WILL_DOC_CREATED,
        UserStatus.ACTION_SET_PAYMENT_COMPLETED,
        UserStatus.ACTION_SET_WILL_CREATION_COMPLETED,
        UserStatus.ACTION_SET_YELLOW_PLUS_PLAN_LOADING,
        UserStatus.ACTION_SET_PROFILE_NOT_LOADED,
      ].includes(status)
    ) {
      if (state.userStatus.includes(UserStatus.LOGGED_IN)) {
        if (status === UserStatus.ACTION_SET_PROFILE_NOT_LOADED) {
          state.userStatus = state.userStatus.filter((it) => it !== UserStatus.PROFILE_LOADED);
        }
        if (status === UserStatus.ACTION_SET_PROFILE_COMPLETED) {
          state.userStatus = state.userStatus.filter((it) => it !== UserStatus.PROFILE_INCOMPLETE);
        }
        if (status === UserStatus.ACTION_SET_WILL_DOC_CREATED) {
          state.userStatus = state.userStatus.filter(
            (it) => it !== UserStatus.WILL_DOC_NOT_CREATED,
          );
        }
        if (status === UserStatus.ACTION_SET_PAYMENT_COMPLETED) {
          state.userStatus = state.userStatus.filter((it) => it !== UserStatus.PAYMENT_INCOMPLETE);
        }
        if (status === UserStatus.ACTION_SET_WILL_CREATION_COMPLETED) {
          state.userStatus = state.userStatus.filter(
            (it) => it !== UserStatus.WILL_CREATION_INCOMPLETE,
          );
        }
        if (status === UserStatus.ACTION_SET_YELLOW_PLUS_PLAN_LOADING) {
          state.userStatus = state.userStatus.filter(
            (it) => it !== UserStatus.YELLOW_PLUS_PLAN_LOADED,
          );
        }
      } else {
        //TODO: Throw error
        console.error('User status is being set to a wrong value', UserStatus[status]);
      }
    }

    let userStatusString = '';
    [...new Set(state.userStatus)].forEach((st) => {
      userStatusString += UserStatus[st];
      userStatusString += ' ';
    });
    console.log('UserStatus', userStatusString);
  },
  setAuthLoadingComplete: (state: AuthSlice) => {
    state.authLoading = false;
  },
  setAuth: (state: AuthSlice, action: PayloadAction<AuthUser | null>) => {
    state.authUser = action.payload;
  },
  initiateProfileFetch: (state: AuthSlice) => {
    state.requestStatus = RequestLoader.LOADING;
  },
  setContacts: (state: AuthSlice, action: PayloadAction<Contact[]>) => {
    state.userContacts = action.payload;
  },
  setProfile: (state: AuthSlice, action: PayloadAction<SetProfilePayload>) => {
    state.requestStatus = RequestLoader.LOADING;
    state.isUserProfileLoaded = true;
    state.userProfile = action.payload.profile;
    state.accessRole = action.payload.accessRole;
    state.userContacts = action.payload.contacts;
    state.will = action.payload.will;
    state.userAssets = action.payload.assets;
    state.userLiabilities = action.payload.liabilities;
    state.remainingProfileFields = action.payload.remainingProfileFields;
    state.createdAt = action.payload.createdAt;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  setContent: (
    state: AuthSlice,
    action: PayloadAction<{
      content: Content;
    }>,
  ) => {
    state.requestStatus = RequestLoader.LOADING;
    state.content = action.payload.content;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  updateProfile: (
    state: AuthSlice,
    action: PayloadAction<{ profile: UserProfile | any; remainingProfileFields?: Array<string> }>,
  ) => {
    state.userProfile = {
      ...state.userProfile,
      ...action.payload.profile,
    };
    state.remainingProfileFields =
      action.payload.remainingProfileFields || state.remainingProfileFields;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  updateUnloggedInUserSessionData: (
    state: AuthSlice,
    action: PayloadAction<Partial<UnloggedInUserSessionData>>,
  ) => {
    state.unloggedInUserSessionData = {
      ...state.unloggedInUserSessionData,
      ...action.payload,
    };
  },
  updateYellowPaymentSummaryData: (
    state: AuthSlice,
    action: PayloadAction<Partial<InitiateCartPaymentResponseData>>,
  ) => {
    state.yellowPaymentSummaryData = {
      ...state.yellowPaymentSummaryData,
      fetchedAt: Date.now(),
      ...action.payload,
    };
  },
  setConfigLoadingStatus: (
    state: AuthSlice,
    action: PayloadAction<{
      success: boolean;
      isLoading: boolean;
      getYellowAppConfigs?: ConfigResponse;
    }>,
  ) => {
    const { success, isLoading, getYellowAppConfigs } = action.payload;
    state.isConfigLoading = isLoading;
    if (success) {
      state.config = getYellowAppConfigs!;
    }
  },
  setYellowPlusConfig: (state: AuthSlice, action: PayloadAction<Partial<PlanData>>) => {
    state.yellowPlusConfig = action.payload;
  },
  setYellowPlusCart: (state: AuthSlice, action: PayloadAction<CartData>) => {
    state.yellowCartData = action.payload;
    state.yellowPaymentSummaryData.fetchedAt = Date.now();
  },
  setAddOns: (state: AuthSlice, action: PayloadAction<Partial<CartAddOn>[]>) => {
    set(
      state.unloggedInUserSessionData,
      'chosenAddOns',
      action?.payload?.map((addOn) => addOn?.id),
    );
    set(state.yellowCartData, 'data.0.add_ons', action.payload);
    set(state.yellowPaymentSummaryData, 'data', undefined);
  },
  setAddOnsForOthers: (state: AuthSlice, action: PayloadAction<Partial<CartAddOnForOthers>[]>) => {
    set(state.yellowCartData, 'data.0.add_ons_for_others', action.payload);
    set(state.yellowPaymentSummaryData, 'data', undefined);
  },
  setYellowPlusCartPromocode: (
    state: AuthSlice,
    action: PayloadAction<CouponInput | undefined>,
  ) => {
    state.yellowCartData = { ...(state.yellowCartData || {}), couponInput: action.payload };
    if (state.yellowPaymentSummaryData.data?.initiateCartPayment?.couponDiscountAmount)
      state.yellowPaymentSummaryData.data.initiateCartPayment.couponDiscountAmount = 0;
    state.yellowPaymentSummaryData.fetchedAt = Date.now();
  },
  updateActiveWill: (state: AuthSlice, action: PayloadAction<{ will: Will }>) => {
    state.will = {
      ...state.will,
      ...action.payload.will,
    };
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },

  updatePaymentInfo: (state: AuthSlice, action: PayloadAction<PaymentInfo>) => {
    state.will = {
      ...state.will,
      paymentInfo: {
        ...action.payload,
      },
    } as Will;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },

  // addContact: (state: AuthSlice, action: PayloadAction<{ contact: Contact }>) => {
  //   state.userContacts.push(action.payload.contact);
  //   state.requestStatus = RequestLoader.LOAD_COMPLETE;
  // },
  // deleteContact: (state: AuthSlice, action: PayloadAction<{ documentIDToBeDeleted: string }>) => {
  //   state.userContacts = state.userContacts.filter(
  //     ({ documentId }) => documentId !== action.payload.documentIDToBeDeleted,
  //   );
  //   state.requestStatus = RequestLoader.LOAD_COMPLETE;
  // },
  updateContactsList: (state: AuthSlice, action: PayloadAction<{ contacts: Contact[] }>) => {
    state.userContacts = action.payload.contacts;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  addAsset: (state: AuthSlice, action: PayloadAction<{ asset: Asset }>) => {
    state.userAssets.push(action.payload.asset);
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  deleteAssset: (state: AuthSlice, action: PayloadAction<{ documentIDToBeDeleted: string }>) => {
    state.userAssets = state.userAssets.filter(
      ({ documentId }) => documentId !== action.payload.documentIDToBeDeleted,
    );
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  setAuthFailed: (state: AuthSlice) => {
    state.authUser = null;
    state.userProfile = null;
    state.requestStatus = RequestLoader.LOAD_COMPLETE;
  },
  setShowGuardianSection: (state: AuthSlice, action: PayloadAction<boolean>) => {
    state.showGuardianSection = action.payload;
  },

  removeAllCartItems: (state: AuthSlice) => {
    state.yellowCartData = { ...state.yellowCartData, data: [] };
    state.yellowPaymentSummaryData = { fetchedAt: undefined };
  },
  invalidatePaymentData: (state: AuthSlice) => {
    state.yellowPaymentSummaryData = { fetchedAt: Date.now() };
  },
  setCurrentUserPlans: (
    state: AuthSlice,
    action: PayloadAction<Partial<CurrentUserPlansResponse>>,
  ) => {
    state.currentUserPlansResponse = {
      errors: undefined,
      ...state.currentUserPlansResponse,
      ...(action.payload || {}),
    };
  },
  setPaymentHistory: (state: AuthSlice, action: PayloadAction<Partial<PaymentHistoryResponse>>) => {
    state.paymentHistoryResponse = {
      ...state.paymentHistoryResponse,
      ...(action.payload || {}),
    };
  },
  sendWillTo: (state: AuthSlice, action: PayloadAction<WillMailRecipientInput>) => {
    state.willRecipientMailSlice.sendWill = {
      request: action.payload,
    };
  },
  updateSendWillRequest: (state: AuthSlice, action: PayloadAction<WillMailRecipient>) => {
    state.willRecipientMailSlice.sendWill = {
      response: action.payload,
    };
  },
  updatePreviousWillRecipients: (
    state: AuthSlice,
    action: PayloadAction<Partial<WillMailRecipient>[]>,
  ) => {
    state.willRecipientMailSlice.previousWillRecipients = action.payload;
  },
  updateWillRecipients: (
    state: AuthSlice,
    action: PayloadAction<WillRecipientInput[]>,
  ) => {
    state.willRecipients = action.payload;
    console.log("Reducer Recipient Payload: ", action.payload);
    console.log("Reducer Recipient state: ", state.willRecipients);
  },
  showAddEvenForOthers: (
    state: AuthSlice,
    action: PayloadAction<{
      addOnsForOthers: any[];
      myCartCurrentlyShowing: MyCartStates;
      addingFor?: string | null | undefined;
    }>,
  ) => {
    set(state.yellowCartData, 'data.0.add_ons_for_others', action.payload.addOnsForOthers);
    set(state.yellowPaymentSummaryData, 'data', undefined);
    set(
      state.unloggedInUserSessionData,
      'myCartCurrentlyShowing',
      action.payload.myCartCurrentlyShowing,
    );
    set(state.unloggedInUserSessionData, 'addingFor', action.payload.addingFor);
  },
  updateUserNotifications: (
    state: AuthSlice,
    action: PayloadAction<UserNotification[]>,
  ) => {
    state.userNotifications = action.payload;
    console.log("Reducer Notifications Payload: ", action.payload);
    console.log("Reducer Notifications state: ", state.userNotifications);
  },
};
export default reducers;
