import styled from 'styled-components';
import { breakpoints } from '../../styles/Breakpoints';

export const InputWrapper = styled.div`
  width: 100%;
  input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #f6f6f7;
    width: 100%;
    height: 55px;
    border-radius: 12px;
    border: 0;
    font-size: 16px;
    padding: 0px 15px;
    font-family: Nunito Sans, sans-serif;
    :focus {
      outline: 0;
    }
    :invalid {
      border: 0;
    }
    :valid {
      border: 2px solid;
    }
    :placeholder-shown {
      border: 0;
    }
    /* for input type 'date' */
    ::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin-right: 10px;
    }
    ::-webkit-date-and-time-value {
      text-align: left;
    }
    -webkit-appearance: none;
    color: #000000;
  }

  .errorMessage {
    margin: 10px 0px;
    color: #ba1b1b;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
  .errorMessage {
    margin: 10px 0px;
    color: #ba1b1b;
  }
  .country-input {
    background-color: #f6f6f6;
    border-bottom: none;
    border-radius: 12px;
    border-bottom: none !important;
    padding-left: 5px;
    height: 50px;
    color: #101828;
    padding-bottom: 5px;
    label {
      top: 7px;
      left: 7px;
    }
    .MuiInput-root:before {
      border-bottom: none;
    }
    .MuiInput-root:after {
      border-bottom: none;
    }
    .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: none;
    }
  }
  .country-input:focus {
    border-bottom: none;
  }
  .phone-input {
    background-color: #efeff0;
    border-bottom: none;
    border-radius: 12px;
    border-bottom: none !important;
    padding-left: 5px;
    // height: 50px;
    color: #101828;

    .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
      border-bottom: none;
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: none;
    }
    .css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
      border-bottom: none;
    }
  }
  .phone-input:focus {
    border-bottom: none;
  }
`;

export const StyledSelect = styled.select`
  height: 55px;
  width: 100%;
  @media screen and (max-width: ${breakpoints.md}) {
    max-width: 100%;
    min-width: 100%;
    box-sizing: border-box;
  }
  padding: 5px 10px;
  margin-top: 5px;
  border-radius: 12px;
  background-color: #f6f6f7;
  border: ${(props: { valid: boolean }) => (props.valid ? '2px solid' : '0')};
  opacity: 0.8;
  font-size: 16px;
  :focus {
    outline: 0;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  :disabled {
    cursor: not-allowed;
  }
`;

export const StyledImage = styled.img`
  position: absolute;
  top: ${(props: { hiddenLabel: boolean }) => (props.hiddenLabel ? '25px' : '46px')};
  right: 20px;
`;

export const InputFieldWrapper = styled.div`
  .clhzNE input {
    width: 100%;
    height: 55px;
    border-radius: 12px;
    font-size: 16px;
    padding: 0px 15px;
    :focus {
      outline: 0;
    }
    :invalid {
      border: 0;
    }
    :valid {
      border: 2px solid;
    }
    :placeholder-shown {
      border: 0;
    }
  }

  .clhzNE .errorMessage {
    color: red;
    font-size: 12px;
    margin: 5px 0 0 15px;
  }
`;

export const Outer = styled.div`
  .loader-wrapper {
    background: #000000a3;
    transform: translate(50%, -39%);
    width: 100%;
    justify-content: center;
    display: flex;
    z-index: 999;
    align-items: center;
    height: 90rem;
  }
  .loader-img {
    border-radius: 5px;
  }
`;
