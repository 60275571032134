// import { useAppSelector } from 'store/hooks';
// import { selectActiveWill } from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import { Pages } from 'routes';

import theme from 'styles/theme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';
import { breakpoints } from 'styles/Breakpoints';

// import { useAppSelector } from 'store/hooks';
// import {
//   selectConfig
// } from "store/payments";

import YessDetailsModal from 'Pages/YessPlus/QuickCart/YessDetailsModal';

// import CustomButton from 'common/Reusables/Button';

import enhanceYourWillImg from 'assets/svg/Dashboard/EnhanceYourWillImage.svg';
import { useState } from 'react';

export const EnhanceWillWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3.5rem;

  p{
    color: ${theme.pricingHeadingColor};
    font-family: 'Merriweather Regular';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .enhanceWill{
    padding: 1rem 1.5rem 1rem 1rem;
    display: flex;
    // justify-content: space-between;
    gap: 1.25rem;
    border-radius: 0.75rem;
    background: #FFFFFF;
    border: 1px solid #e3e4e7;
    // background: #EFEADC;

    .enhanceWillBody{
      // max-width: 65%;
      padding:0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 1.25rem;

      p{
        color: rgba(16, 24, 40, 0.64);
        font-family: 'Roboto Regular';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 24px */
      }

      .detailsButton{
        color: #1270E8;
        font-family: 'Roboto Medium';
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;	
        cursor: pointer;
      }

      .enhanceWillContent{
        width: 100%;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        gap: 1rem;

        .enhanceWillContentHeader{
          display: flex;
          align-items: center;
          gap: 0.625rem;
          flex-wrap: wrap;

          h2{
            color: ${theme.pricingHeadingColor};
            font-family: 'Roboto Bold';
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.625rem; /* 26px */
          }
          span{
            font-size: 0.875rem;
            line-height: 1.375rem;
          }
        }
        
      }
      .customPrice{
        padding: 0.438rem 1rem;
        height: fit-content;
        min-width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 1.5rem;
        background: #FFE171;
        color: ${theme.pricingHeadingColor};
        font-family: 'Roboto Bold';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        
      }
    }
  }

  .enhanceWillYES{
    padding-left: 1rem;
    color: #000;
    font-family: 'Roboto Regular';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span{
      color: #1270E8;
      font-family: 'Roboto Medium';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-bottom: 2.5rem;

    p{
      width: 70%;
      font-size: 1.125rem;
    }

    .enhanceWill{
      padding: 1rem;
      flex-direction: column;
      gap: 0.875rem;
      position: relative;

      p{
        width: 100%;
      }

      .enhanceWillBody{
        padding: 0;
        gap: 0.875rem;

        .enhanceWillContent{
          flex-direction: column;

          .enhanceWillContentHeader{
            gap: 0;
            flex-direction: column;
            align-items: start;
          }
        }
        .customPrice{
          position: absolute;
          top: 1.5rem;
          left: 1.5rem;
        }
      }
    }
    .enhanceWillYES{
      width: 100%;
      padding: 0;
    }
  } 
`;


const EnhanceWillCard = ({
  showYess,
  yessPrice
}:{
  showYess?: boolean,
  yessPrice: string | undefined
}) => {
  const navigate = useNavigate();
  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  // const config = useAppSelector(selectConfig);

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  // const [yessPrice, setYessPrice] = useState<string | undefined>(undefined);


  // useEffect(() => {
  //   if(config?.data.fetchPlanByUserInfo.plans){
  //     const yessPlan = config?.data.fetchPlanByUserInfo.plans.find(plan => plan.id === 'yess')
  //     if(yessPlan)
  //       setYessPrice(yessPlan.price.finalPrice.toLocaleString('en-US', {
  //         style: 'currency', currency: 'INR', currencyDisplay: 'code', minimumFractionDigits: 0,
  //         maximumFractionDigits: 0
  //       }));
  //   }

  // },[config])

  return (
    <EnhanceWillWrapper>
      <p>Enhance your Will: Asset transfer services</p>
      <div className="enhanceWill">
        <img src={enhanceYourWillImg} alt="Enhance Your Will Image" />
        <div className="enhanceWillBody">
          <div className="enhanceWillContent">
            <div className="enhanceWillContentHeader">
              <h2>
                Yellow SMART Remote Support
              </h2>
            </div>
            {!lessThanSmall && <span className="customPrice">₹ 499/ year</span>}
          </div>
          <p>
            Online support from our experts to execute your Will. 
            <span className="detailsButton"
              style={{'marginLeft': '0.5rem'}}
              onClick={() => {
                setShowDetailsModal(true);
              }
              }>View Details</span>
            <br/>
            <span className="detailsButton"
              onClick={() => {
                {navigate({pathname: Pages.PAYMENT, search: '?selectedPlans=yess'})}
              }
              }>Buy Now</span> &rarr; 
          </p>
          {lessThanSmall && <span className="customPrice">₹ 499/ year</span>}
        </div>
      </div>
      {showYess  &&
        <p className="enhanceWillYES">or for in-depth on ground services try <span onClick={() => window.open('https://getyellow.in/yellow-smart/')}>Yellow SMART.</span></p>
      }
      {
        showDetailsModal &&
        <YessDetailsModal
            YessPrice={yessPrice}
            setShowDetailsModal={setShowDetailsModal}
            showDetailsModal={showDetailsModal}
          />
      }
    </EnhanceWillWrapper>
  );
};

export default EnhanceWillCard;
