import { useState } from 'react';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/Breakpoints';

type WillPreviewWrapperProps = {
  darken?: boolean;
};

const WillPreviewWrapper = styled.div<WillPreviewWrapperProps>`
  // position: relative;
  height: 85%;

  .page,
  .react-pdf__Page__canvas {
    border-radius: 8px;
    ${(props) => (props.darken ? 'height: 100%;' : '')}
  }

  ${(props) =>
    props.darken
      ? '.react-pdf__Page { min-height:100%; min-width: 100%; aspect-ratio: 1 / 1.414;}'
      : ''}

  .react-pdf__Document {
    height: 100%;
    width: auto;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    height: 100%;
  }
`;

export const WillPreviewContainer = ({
  url,
  pageToRender,
  darken,
}: {
  url: string;
  pageToRender?: number;
  darken?: boolean;
}) => {
  const [numPages, setNumPages] = useState();
  return (
    <WillPreviewWrapper
      darken={darken}
      {...(darken ? { style: { height: '100%', width: '100%', overflow: 'hidden' } } : {})}
    >
      <Document
        loading={''}
        onLoadSuccess={({ numPages }) => setNumPages(numPages as any)}
        onLoadError={(error) => console.log({ error })}
        file={url}
        {...(darken
          ? {
            pageLayout: 'singlePage',
            pageMode: 'useNone',
            style: { height: '100%', width: 'auto' },
          }
          : {})}
      >
        {pageToRender ? (
          <Page {...(darken ? {} : {})} pageNumber={pageToRender} loading={''} />
        ) : (
          <>
            {
              Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(
                  (page) => (
                    <>
                      <Page pageNumber={page} loading={""} className="page" />
                      <div style={{ height: "20px" }}></div>
                    </>
                  )
                )
            }
          </>
        )}
      </Document>
      {darken && <div style={{
        zIndex: "1",
        background: "rgba(0, 0, 0, 0.45)",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: "0"
      }}></div>}
    </WillPreviewWrapper>
  )
}
