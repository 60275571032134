import styled from 'styled-components';
// import loading from 'assets/images/loading.gif';
// import loadingGIF from 'assets/images/loadingGIFOptimized.gif';
import loadingGIFYellow from 'assets/Yellow_Loader_WHITEBG.gif';

const Loading = ({ backgroundColor = 'transparent' }: { backgroundColor?: string }) => {
  return (
    <LoaderWrapper className='loader-wrapper' style={{ backgroundColor }}>
      <img className='loader-img' src={loadingGIFYellow} alt='' />
    </LoaderWrapper>
  );
};

export default Loading;

const LoaderWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 10000;
  align-items: center;
  justify-content: center;
  .loader-img {
    width: 100px;
    height: 100px;
  }
`;
