import { useState } from 'react';

import { Chip, Dialog, Grid } from '@mui/material';
import { breakpoints } from 'styles/Breakpoints';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import howItWorksThumbnail from 'assets/images/howItWorksVideoThumbnail.png';

// const Wrapper = styled(Grid)`
const Wrapper = styled.div`
  padding: 1rem 1rem 1.5rem 1rem;
  background: white;
  //   box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
  border: 2px solid #ebe5d4;
  border-radius: 16px;
  width:100%;
  height: 80%;
  object-fit: cover;

  .thumbnail {
    border-radius: 8px;
    width: 100%;
    height: 160px;
    // flex-basis: 200px;
    object-fit: cover;
  }

  .title {
    margin: 12px 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  .description {
    flex: 1;
    font-size: 14px !important;
  }

  .authorInfo {
    color: rgba(16, 24, 40, 0.44);
    font-size: 12px !important;
  }

  .bottom {
    flex: 0;
    flex-basis: auto;
  }

  @media screen and (max-width: ${breakpoints.xs}){
    height: 85%;
  }
`;

const ResourcesVideoCard = () => {
  // const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Wrapper
        // item
        // lg={3.8}
        // md={5.8}
        // sm={11.9}
        className='popularArticle cursor-pointer'
        // onClick={() => navigate(`//getyellow.in/resources/${article.slug}`)}
        // display='flex'
        // flexDirection={'column'}
        // justifyContent={'space-between'}
      >
        <Dialog
          PaperProps={{
            sx: { maxHeight: '70%' },
          }}
          onClose={handleClose}
          open={open}
          sx={{ width: '100%', height: '100%' }}
          maxWidth='lg'
          fullWidth={true}
        >
          <iframe
            src='https://www.youtube.com/embed/mpxMsfdhhr0'
            title='YouTube video player'
            height={'800'}
            //@ts-ignore
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          ></iframe>
        </Dialog>
        <Grid
          onClick={() => {
            setOpen(true);
            console.log('video play');
          }}
        >
          <Grid>
            <img src={howItWorksThumbnail} alt='' className='thumbnail' />
            <p className='subText font-semibold title'>{'How it Works'}</p>
            <p className='description'>{'A step‒by‒step guide for using the Yellow app'}</p>
          </Grid>
        </Grid>
        <Grid container xs={12} pt={2} className='bottom'>
            <Grid
              item
              xs={12}
              container
              display='flex'
              justifyContent={'space-between'}
              alignItems='center'
            >
              <Chip
                label={'Video'}
                sx={{
                  backgroundColor: '#D4D1FA',
                  fontSize: '14px',
                  fontWeight: '700',
                  color: '#626363',
                }}
              />
            </Grid>
          </Grid>
      </Wrapper>
    </>
  );
};

export default ResourcesVideoCard;
