import { useAppSelector } from 'store/hooks';
import { selectActiveWill, selectUserProfile } from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import { Pages } from 'routes';

import theme from 'styles/theme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import styled from 'styled-components';
import { breakpoints } from 'styles/Breakpoints';

import CustomButton from 'common/Reusables/Button';
import { ShimmerSectionHeader, ShimmerDiv } from 'shimmer-effects-react';

import willFolderImg from 'assets/images/dashboard/yellowWillFolder.png';
import { useEffect, useState } from 'react';

export const ContinueWillWrapper = styled.div`
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  border-radius: 0.75rem;
  background: #FFE171;

  .shimmerContentLoader{
    width: 20rem;
  }

  .shimmerLoader{
    width: 100%;
    height: 30vh;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;  
  }

  .yellowWillBody{
    max-width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 2.5rem;

    .yellowWillContent{
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      h2{
        color: ${theme.pricingHeadingColor};
        font-family: 'Roboto Bold';
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.625rem; /* 26px */
      }
      p{
        color: ${theme.pricingHeadingColor};
        font-family: 'Roboto Regular';
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem; /* 22px */
      }

      .couponBlock{
        background-color: white;
        border-radius: 1.5rem;
        padding: 0.125rem 0.875rem;
        width: fit-content;

        > p{
          color: black;
          font-family: 'Roboto Bold';
          font-size: 0.75rem;
          // font-style: normal;
          font-weight: 700;
          line-height: 1.125rem; /* 18px */
          text-align: left;
        }
      }
    }

    .yellowWillButtons__unpaid{
      display: flex;
      flex-direction: row;
      gap: 1.125rem;
    }

    > * > .buttonText{
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 1.5rem 1rem;
    .yellowWillBody{
      max-width: 100%;
      gap: 1.25rem;

      .yellowWillButtons__unpaid{
        flex-direction: column;
        gap: 0.625rem;
        width: 100%;
      }
    }
  } 
`;


const ContinueWillCard = ({
  neverRegistered = localStorage.getItem('neverRegistered') === null ? null : localStorage.getItem('neverRegistered') === 'true' ? true : false,
}:{
  neverRegistered?: Boolean | null
}) => {
  const navigate = useNavigate();
  const userActiveWill = useAppSelector(selectActiveWill);
  const userProfile = useAppSelector(selectUserProfile);
  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const [couponCode, setCouponCode] = useState<any>(undefined);

  useEffect(() => {
    //Coupon Code Block render logic - Future Use
    if(false){
      setCouponCode({
        code: 'SENIOR30',
        discount: 0.30
      });
    }
  }, [])

  return (
    <ContinueWillWrapper>
      <div className="yellowWillBody" style={{ gap: neverRegistered ? '1.5rem' : '2.5rem'}}>
        <div className="yellowWillContent">
          {
            userProfile === null ? (
              <div className="shimmerContentLoader">
                <ShimmerSectionHeader mode="light" center={false} subtitleLine={2} titleHeight={24} subtitleHeight={12} />
              </div>
            ) : (
              userProfile?.wills.length === 0 ? (
                <>
                  <h2>Yellow Wills</h2>
                  <p>Save your family from future quarrels, unnecessary stress, and high costs. Make your legally valid and secure Will today.</p>
                </>
              ) : (
                <>
                  <h2>Continue your Will</h2>
                  <p>You’ve made great progress, and are almost done.<br/>{lessThanSmall && <br/>}Complete your Will journey</p>
                </> 
              )
            )
          }

          {
            neverRegistered && couponCode !== undefined &&
            <div className="couponBlock">
              <p>Use code {couponCode.code} at checkout to get {couponCode.discount * 100}% off</p>
            </div>
          }
        </div>
        {
          neverRegistered === null ? (
            <div className="shimmerLoader">
              <ShimmerDiv mode="light" height={'100%'} width={'100%'} rounded={1} />
            </div>
          ) : (
            neverRegistered ? (
              <div className="yellowWillButtons__unpaid">
                <CustomButton
                  style={{
                    width: lessThanSmall ? "100%" : "fit-content",
                    // marginTop: 16,
                    height: 'fit-content',
                    borderRadius: '0.5rem',
                    padding: '0.75rem 1.5rem',
                  }}
                  onClick={() => {
                    window.open('https://getyellow.in/pricing-wills');
                  }}
                  //If will in progress text is "Continue Will" else it is "Start Now"
                  text={'Purchase a plan'}
                />
                <CustomButton
                  style={{
                    width: lessThanSmall ? "100%" : "fit-content",
                    // marginTop: 16,
                    height: 'fit-content',
                    borderRadius: '0.5rem',
                    border: `1px solid ${theme.primaryColor}`,
                    padding: '0.75rem 0.625rem',
                  }}
                  backgroundColor={'transparent'}
                  fontColor={theme.primaryColor}
                  onClick={() => {
                    navigate(Pages.WILL_EDITOR);
                  }}
                  //If will in progress text is "Continue Will" else it is "Start Now"
                  text={'Continue Will Journey'}
                />
              </div>
            ) : (
              <CustomButton
                style={{
                  width: lessThanSmall ? "100%" : "fit-content",
                  // marginTop: 16,
                  height: 'fit-content',
                  borderRadius: '0.5rem',
                  padding: '0.75rem 1.5rem',
                }}
                onClick={() => {
                  navigate(Pages.WILL_EDITOR);
                }}
                //If will in progress text is "Continue Will" else it is "Start Now"
                text={userActiveWill !== null ? 'Continue Will' : 'Start Now'}
              />
            )
          ) 
        }
      </div>
      {
        !lessThanSmall &&
        <img src={willFolderImg} alt="Will Folder Image" />
      }
    </ContinueWillWrapper>
      
  );
};

export default ContinueWillCard;
