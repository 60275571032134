import { useEffect } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectUserProfile, selectUserStatus } from 'store/user/selectors';
import { UserStatus } from 'store/user/slice';
// import { UserStatus } from 'store/user/slice';

const useReferralFactoryScript = () => {
  const url = 'https://referral-factory.com/assets/js/popup.js?code=nbgI4X';
  const userStatus = useAppSelector(selectUserStatus);
  const userProfile = useAppSelector(selectUserProfile);
  useEffect(() => {
    const script = document.createElement('script');
    if (userStatus.includes(UserStatus.LOGGED_IN)) {
      if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
        window.RF = {
          user: {
            first_name: `${userProfile?.fullName}`,
            email: `${userProfile?.email}`,
          },
        };

        script.src = url;
        script.className = 'rf-popup-script';
        script.setAttribute('data-code', 'nbgI4X');
        script.async = true;

        document.body.appendChild(script);

        console.log(userProfile?.fullName ? userProfile.fullName : null);
      }
    }
    // Fix - Referral Factory popup LOGGED_IN state only works after refresh
    // if (userStatus.includes(UserStatus.LOGGED_OUT)) {
    //   script.src = url;
    //   script.className = 'rf-popup-script';
    //   script.setAttribute('data-code', 'nbgI4X');
    //   script.async = true;

    //   document.body.appendChild(script);

    //   console.log(userProfile?.fullName ? userProfile.fullName : null);
    // }
    return () => {
      window.RF = {};
    };
  }, [url, userStatus]);
};

export default useReferralFactoryScript;
