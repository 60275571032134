import { put, takeLatest } from 'redux-saga/effects';
import firebase from 'utils/firebase';
import {
  addToUserStatus,
  fetchContentAction,
  fetchUserProfileAction,
  fetchYellowMembershipConfig,
  fetchYellowPaymentHistoryConfig,
  fetchYellowPlusCart,
  initiateAuthFetch,
  setAuth,
  setAuthFailed,
  setAuthLoadingComplete,
  UserStatus,
  fetchCurrentUserPlans,
  getWillMailRecipients,
} from './slice';
import store from 'store';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';

function* initAuth() {
  yield put(addToUserStatus(UserStatus.DETERMINING_AUTH_STATUS));
  yield put(initiateAuthFetch());
  yield put(fetchYellowMembershipConfig());
  yield put(fetchYellowPaymentHistoryConfig());
  yield put(fetchYellowPlusCart());

  // Get Content even for non-auth user.
  yield put(fetchContentAction());
  // eslint-disable-next-line
  const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const authToken: string | null = await user.getIdToken();
      if (!authToken) {
        return await store.dispatch(setAuthFailed());
      }

      Mixpanel.setUserProperties({
        'Date of Last Login': new Date(),
      });

      store.dispatch(
        setAuth({
          id: user.uid,
          phoneNumber: user.phoneNumber as string,
          authToken: authToken,
        }),
      );

      Mixpanel.track('User Authenticated');
      Intercom.track('User Authenticated');

      // localStorage.setItem('authToken', authToken);
      store.dispatch(fetchUserProfileAction());
      const { userProfile } = store.getState().user;
      if (!userProfile) {
        setTimeout(() => {
          store.dispatch(fetchUserProfileAction());
        }, 5000);
      }

      store.dispatch(fetchCurrentUserPlans());
      // console.log("USer from Auth Saga ************",user)
      // store.dispatch(fetchConfig());
      store.dispatch(addToUserStatus(UserStatus.LOGGED_IN));
      store.dispatch(setAuthLoadingComplete());
      store.dispatch(getWillMailRecipients());
    } else {
      // Set User as Unauthorized.
      store.dispatch(setAuthFailed());
      store.dispatch(addToUserStatus(UserStatus.LOGGED_OUT));
      await store.dispatch(setAuthLoadingComplete());
    }
  });
  return unsubscribe;
}

function* resetPassword(action: any) {
  try {
    const { email } = action.payload;
    var actionCodeSettings = {
      url: `${import.meta.env.VITE_SELF_URL}/login`,
    };
    const auth = firebase.auth();
    yield auth.sendPasswordResetEmail(email, actionCodeSettings);
    const toast: ToastMessage = {
      type: ToastType.SUCCESS,
      message: 'Reset link has been successfully sent.',
    };
    yield put(showToast(toast));
  } catch (error) {
    const toast: ToastMessage = {
      type: ToastType.ERROR,
      message: 'Failed to send the reset link. Please try again.',
    };
    yield put(showToast(toast));
    console.log(error);
  }
}

function* doLogout() {
  // (window as any).webengag/e.track("User Logout");
  (window as any).webengage.user.logout();
  yield firebase.auth().signOut();
  // localStorage.removeItem('authToken');
  window.location.reload();
  yield put(setAuth(null));
  yield store.dispatch(fetchUserProfileAction());
  // IMP: should dispatch an action to fetch user even when not loggedin
  //   This makes sure anonymous access role is always fetched from server.
}

export default function* authSaga() {
  yield takeLatest('INIT_AUTH', initAuth);
  yield takeLatest('RESET_PASSWORD', resetPassword);
  yield takeLatest('LOGOUT_ATTEMPT', doLogout);
}
