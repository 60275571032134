import dayjs from 'dayjs'
import { Chip, Grid } from '@mui/material';
import { breakpoints } from 'styles/Breakpoints';
// import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Article } from 'utils/types';

// const Wrapper = styled(Grid)`
const Wrapper = styled.div`
  padding: 1rem 1rem 1.5rem 1rem;
  background: white;
  //   box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
  border: 2px solid #ebe5d4;
  border-radius: 16px;
  width:100%;
  height: 80%;
  object-fit: cover;


  .thumbnail {
    border-radius: 8px;
    width: 100%;
    height: 160px;
    // flex-basis: 200px;
    object-fit: cover;
  }

  .title {
    margin: 12px 0px;
    max-width: 100%;
    font-size: 14px !important;
  }

  .description {
    flex: 1;
    font-size: 14px !important;
  }

  .authorInfo {
    color: rgba(16, 24, 40, 0.44);
    font-size: 12px !important;
  }

  .bottom {
    flex: 0;
    flex-basis: auto;
  }

  @media screen and (max-width: ${breakpoints.xs}){
    height: 85%;
  }
`;

const ArticleListingCard = (article: Article) => {
  // const navigate = useNavigate();
  const maxLengthOfSummary = 70;
  const maxLines = 2;
  const ellipsis = '...';

  const descriptionArticle = article?.summary.split('\n');
  descriptionArticle.splice(maxLines);

  let resDescription = descriptionArticle.join('\n');

  if (resDescription.length > maxLengthOfSummary) {
    resDescription = resDescription.slice(0, maxLengthOfSummary - ellipsis.length) + ellipsis;
  }

  let categoriesChip: any = {
    'e2a32e64-5a81-4e11-b9fd-949dd077965f': {
      label: 'Legal',
      color: '#BAE4D9',
    },
    'de22d9eb-fc44-458b-a1f2-57283a918b26': {
      label: 'Basics',
      color: '#D1E3FA',
    },
    '99b1388e-dc93-4637-8f29-8f176e1f8635': {
      label: 'People',
      color: '#D4D1FA',
    },
    '30daf42f-fd85-454a-af18-fbd8a18359dc': {
      label: 'Life',
      color: '#FAE6D1',
    },
    '1cd800c4-65d9-4592-af29-1df5b21aae92': {
      label: 'Assets',
      color: '#FFEDBA',
    },
  };

  return (
    <Wrapper
      // item
      // lg={3.8}
      // md={5.8}
      // sm={11.9}
      className='popularArticle cursor-pointer'
      // onClick={() => navigate(`//getyellow.in/resources/${article.slug}`)}
      onClick={() => window.open(`https://getyellow.in/resources/${article.slug}`, '_blank')}
      // display='flex'
      // flexDirection={'column'}
      // justifyContent={'space-between'}
      // alignItems={'center'}
    >
      <Grid>
        <img src={article.mainImage} alt='' className='thumbnail' />
        <p className='subText font-semi-bold title'>{article.title}</p>
        <p className='description'>{resDescription}</p>
      </Grid>
      <Grid container xs={12} pt={2} className='bottom'>
        <Grid
          item
          xs={12}
          container
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
        >
          <Chip
            label={categoriesChip[article.categoryIds[0]].label}
            sx={{
              backgroundColor: categoriesChip[article.categoryIds[0]].color,
              fontSize: '14px',
              fontWeight: '700',
              color: '#626363',
            }}
          />
          <p className='authorInfo'>
            {article?.dateOfUpdation &&
              dayjs(new Date(article?.dateOfUpdation)).format('D MMM YYYY')}
            ・{article?.readTime} read
          </p>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ArticleListingCard;
