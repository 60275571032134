import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill, selectCurrentUserPlans, selectUserProfile, selectUserStatus } from 'store/user/selectors';
import { UserStatus } from 'store/user/slice';
// import { runMutation } from 'utils/graphQL';
import { WILL_CREATION_STATES } from 'utils/types';
// import { GENERATE_PREVIEW_OF_WILL } from './queries';
import CustomButton from './Reusables/Button';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useGenerateWill } from './useGenerateWill';
import KYCShowModal from 'Pages/MyWill/KYCShowModal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRedirectToPaymentOrMyWill } from './useRedirectToPaymentOrMyWill';
import { useVendor } from './useVendor';
import { Pages } from 'routes';

const Wrapper = styled.div`
  width: 100%;

  .generateWillBtn {
    // width: 175px;
    width:100%;
  }
`;

const GenerateWill = ({ setKYCHeroScreen }: { setKYCHeroScreen?: Function }) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const willId = useAppSelector(selectActiveWill)?.documentId;
  const userProfile = useAppSelector(selectUserProfile);
  const userActiveWill = useAppSelector(selectActiveWill);
  const userStatus = useAppSelector(selectUserStatus);
  const { redirectToPaymentOrMyWill } = useRedirectToPaymentOrMyWill();
  const { generateWill } = useGenerateWill();
  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);
  const [isGenerateWill, setIsGenerateWill] = useState(false);
  const [generatingWill, setGeneratingWill] = useState(false);
  const [showKYCModal, setShowKYCModal] = useState(false);
  const [isKYCCompleted, setIsKYCCompleted] = useState(false);
  const { isAcko } = useVendor();
  const currentUserPlansResponse = useAppSelector(selectCurrentUserPlans);
  const currentPlan = currentUserPlansResponse?.data?.fetchPlans?.[0];

  useEffect(() => {
    console.log('in GenerateWill, showKYCModal is: ' + showKYCModal);
  }, [showKYCModal]);

  // const willPDFSignedURL = async (willId: string) => {
  //   try {
  //     const response = await runMutation({
  //       mutation: GENERATE_PREVIEW_OF_WILL,
  //       variables: {
  //         key: 'input',
  //         value: {
  //           willId,
  //           forPreview: true,
  //         },
  //       },
  //     });
  //     console.log({ response });
  //     return response;
  //   } catch (exception) {
  //     console.log({ exception });
  //   }
  // };

  const getWillStatus = () => {
    let status = true;
    if (userActiveWill?.will_progress) {
      if (!userActiveWill.will_progress.includes('BASIC_DETAILS_FILLED' as WILL_CREATION_STATES)) {
        return false;
      }
    } else {
      return false;
    }
    console.log('Will progress', userActiveWill, status);
    return status;
  };

  useEffect(() => {
    if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
      setIsPaymentComplete(!userStatus.includes(UserStatus.PAYMENT_INCOMPLETE));
    }
  }, [userStatus]);

  useEffect(() => {
    if (getWillStatus()) {
      setIsGenerateWill(true);
    }
  }, [userActiveWill, userProfile]);

  useEffect(() => {
    if (userProfile?.kycDetails) {
      if (userProfile.kycDetails.kycStatus === 'DONE') {
        setIsKYCCompleted(true);
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if(isAcko){
      setIsKYCCompleted(true);
    }
  },[isAcko])

  useEffect(() => {
    if(userProfile?.wills?.length && userProfile?.wills?.length > 1){
      setIsKYCCompleted(true);
    }
  },[userProfile])

  return (
    <Wrapper>
      {!isPaymentComplete ? (
        userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE) ||
          userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) ? (
          isGenerateWill ? (
            <CustomButton
              text={generatingWill ? 'Generating Will...' : 'Generate Will'}
              className='generateWillBtn'
              onClick={async () => {
                console.log(
                  'in GenerateWill, in GenerateWill button 1, userStatus is:\n' + userStatus,
                );
                if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) {
                  (window as any).webengage.track("Generate Will Clicked",{
                    "Will Generated": "no"
                  });
                  return true;
                }
                console.log('in GenerateWill, in GenerateWill button 1, willId is:\n' + willId);
                if (willId) {
                  setGeneratingWill(true);
                  await generateWill(willId);
                  setGeneratingWill(false);
                  // navigate(Pages.MY_WILL);
                  if(!isKYCCompleted || (Date.now() > Number(currentPlan?.expireAt))){
                    (window as any).webengage.track("Generate Will Clicked",{
                      "Will Generated": "no"
                    });
                  }
                  !isKYCCompleted ? setShowKYCModal(true) : ((Date.now() > Number(currentPlan?.expireAt)) ? navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true }) : redirectToPaymentOrMyWill());
                }
              }}
              lessThanSmallPageBottom={lessThanSmall}
            />
          ) : (
            <CustomButton
              text={`${userActiveWill?.willCompletionProgress && userActiveWill?.willCompletionProgress > 0
                  ? 'Continue Will'
                  : 'Start Will'
                }`}
              className='generateWillBtn'
              onClick={() => {
                // (window as any).webengage.track("Start Will Clicked");
                navigate(0);
              }
              }
            />
          )
        ) : (
          <CustomButton
            text={'Generate Will'}
            className='generateWillBtn'
            onClick={() => {
              console.log('in GenerateWill, in GenerateWill button 2, userStatus is:');
              if(!isKYCCompleted || (Date.now() > Number(currentPlan?.expireAt))){
                (window as any).webengage.track("Generate Will Clicked",{
                  "Will Generated": "no"
                });
              }
              return !isKYCCompleted ? setShowKYCModal(true) : ((Date.now() > Number(currentPlan?.expireAt)) ? navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true }) : redirectToPaymentOrMyWill());
            }}
            lessThanSmallPageBottom={lessThanSmall}
          />
        )
      ) : (
        <CustomButton
          text={'Generate Will'}
          className='generateWillBtn'
          onClick={() => {
            console.log('in GenerateWill, in GenerateWill button 3, userStatus is:');
            if(!isKYCCompleted || (Date.now() > Number(currentPlan?.expireAt))){
              (window as any).webengage.track("Generate Will Clicked",{
                "Will Generated": "no"
              });
            }
            return !isKYCCompleted ? setShowKYCModal(true) : ((Date.now() > Number(currentPlan?.expireAt)) ? navigate(Pages.PAYMENT + '?selectedPlans=will', { replace: true }) : redirectToPaymentOrMyWill());
          }}
          lessThanSmallPageBottom={lessThanSmall}
        />
      )}
      <KYCShowModal
        isShowModal={showKYCModal}
        handleOpenCloseModal={() => setShowKYCModal(false)}
        handleKYC={() => {
          setShowKYCModal(false);
          setKYCHeroScreen && setKYCHeroScreen(true);
        }}
      />
    </Wrapper>
  );
};
export default GenerateWill;
