import { useEffect, useState } from 'react';
import MuiDrawer from './Reusables/Drawer';
import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import blueIcon from 'assets/images/blue-plus-icon.png';
import close from 'assets/images/close.png';
import { Button, Grid } from '@mui/material';
import SelectField from 'common/Reusables/Select';
import InputField from 'common/Reusables/Inputs';
import {
  Countries,
  FormRegisterInputs,
  HTMLInputTypes,
  NewStates,
  Will,
  WillLocation,
  WillTypes,
} from 'utils/types';
import { useForm, UseFormRegister } from 'react-hook-form';
import { getEmptyAddress, regex } from 'utils';
import CountrySelect from 'common/Reusables/Select/CountrySelect';
import { handlePincodeChange } from './Reusables/pincodeService';
import { Outer } from './forms/Wrappers';
import Loading from './Loading';
import { ToastMessage, ToastType, showToast } from 'store/toast/slice';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { fetchUserProfileAction, updateActiveWill } from 'store/user/slice';
import { UPDATE_USER_WILL } from './queries';
import { runMutation } from 'utils/graphQL';
import { selectActiveWill } from 'store/user/selectors';

const MyPeopleOverlayWrapper = styled.div`
  padding: 30px 20px;
  .errorMessage {
    margin-bottom: 16px;
    margin-top: 0;
  }
  .slide-inner {
    padding: 16px;
    background: #ffffff;
    border-radius: 12px;
    margin-right: 12px !important;
    box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
    width: 92% !important;
    margin-right: 12px !important;
    width: 100%;
    margin-bottom: 24px;
    margin-top: 32px;
  }
  .active {
    border: 1px solid black;
  }
  .dropdown_icon {
    top: 16px;
    color: #475467;
    width: 12px;
  }
  input,
  select {
    margin-bottom: 16px;
    background: rgba(71, 84, 103, 0.05);
    border-radius: 12px;
    padding: 0px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(71, 84, 103, 0.64);
  }
  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #101828;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    .close {
      width: 14px;
      cursor: pointer;
    }
  }
  .solid-black-btn {
    background-color: #1d2939;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fcfcfc;
    padding: 14px;
    width: 100%;
    text-transform: capitalize;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    border-radius: 0px;
    :hover {
      background-color: #1d2939 !important;
    }
  }
  .add-new-address {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #1270e8;
    align-items: center;
    display: flex;
    cursor: pointer;
    img {
      margin-right: 12px;
    }
  }
  .slick-slide {
    width: 280px !important;
  }
  .Mui-checked {
    color: #000000 !important;
  }
  .MuiRadio-root {
    padding: 0px;
    margin-right: 13px;
  }
  .detail-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #101828;
  }
  h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(16, 24, 40, 0.64);
  }

  .slick-arrow {
    box-shadow: 0px 1px 8px rgb(29 41 57 / 24%);
    width: 40px;
    height: 40px;
    border-radius: 450%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    &:before {
      content: unset;
    }
  }
  .slick-disabled {
    background: transparent;
    box-shadow: none;
    background-image: none !important;
  }
`;

export type PickMyAddressOverlayProps = {
  isOpen?: boolean;
  setIsOpen?: any;
  existingAddresses: any;
  setExecutor: any;
  selectedExecutor: any;
  additionalInstructions: string;
  setIsNewAddressOpen: Function;
  isNewAddressOpen: boolean;
};

export const PickMyAddressOverlay = ({
  isOpen,
  setIsOpen,
  existingAddresses,
  setExecutor,
  selectedExecutor,
  additionalInstructions,
  setIsNewAddressOpen,
  isNewAddressOpen,
}: PickMyAddressOverlayProps) => {
  const [isCountryIndia, setIsCountryIndia] = useState<boolean>(true);
  const [pincodeErrorMessage, setPincodeErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [pincode, setPincode] = useState('');

  const dispatch = useTypedDispatch();
  const userActiveWill = useAppSelector(selectActiveWill);

  const defaultValues = {
    fullName: '',
    address: getEmptyAddress(),
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField,
    clearErrors,
    setError,
    setValue,
    reset,
  } = useForm<WillLocation>({
    defaultValues,
  });

  const handleWillLocationUpdate = async (data: WillLocation) => {
    try {
      const updateWillRequestBody: Will = {
        documentId: userActiveWill?.documentId || ('' as string),
        type: WillTypes.PREMIUM_WILL,
        willLocation: {
          address: data.address,
          additionalInstructions:
            additionalInstructions || userActiveWill?.willLocation?.additionalInstructions,
        },
      } as Will;

      const {
        data: { updateWill: updatedWill },
      }: any = await runMutation({
        mutation: UPDATE_USER_WILL,
        variables: {
          key: 'input',
          value: updateWillRequestBody,
        },
      });

      await new Promise((resolve, reject) => {
        dispatch(fetchUserProfileAction(undefined, resolve, reject));
      });

      if (updatedWill) {
        dispatch(
          updateActiveWill({
            will: updatedWill,
          }),
        );
      }

      const toast: ToastMessage = {
        type: ToastType.SUCCESS,
        message: 'Successful',
      };
      dispatch(showToast(toast));
    } catch (error) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Error in Updating your Will Location. Please try again.',
      };
      dispatch(showToast(toast));
    }
  };

  const onSubmit = async (data: any) => {
    await handleWillLocationUpdate(data);
    reset();
    setIsNewAddressOpen(false);
  };

  useEffect(() => {
    if (pincode !== '') {
      const pincodeData = {
        pincode,
        clearErrors,
        setShowLoader,
        setPincodeErrorMessage,
        setError,
        setValue,
      };
      handlePincodeChange(pincodeData);
    }
  }, [pincode]);

  return (
    <Outer>
      {showLoader && <Loading />}
      <MuiDrawer open={isOpen!!} width={'min(100%, 500px)'} hideDrawer={() => setIsOpen(false)}>
        <MyPeopleOverlayWrapper>
          <h3 className='title'>
            Existing addresses
            <img className='close' src={close} alt='close' onClick={() => setIsOpen(false)} />
          </h3>
          <div>
            {existingAddresses &&
              existingAddresses.map((address: any, index: any) => {
                const isChecked = selectedExecutor === index + 1 ? true : false;

                return (
                  <div
                    onClick={() => setExecutor(index + 1)}
                    className={`slide-inner ${!!isChecked ? 'active ' : ''}`}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Radio
                        checked={isChecked}
                        onChange={() => setExecutor(index + 1)}
                        value='a'
                        name='radio-buttons'
                        inputProps={{ 'aria-label': 'A' }}
                      />
                      <div>
                        <h5 className='detail-title'>{address.addressLine1}</h5>
                        <h3>
                          {address.pinCode}, {address.addressLine1}
                        </h3>
                      </div>
                    </Box>
                  </div>
                );
              })}
          </div>
          <Grid item>
            <h4
              className='add-new-address'
              onClick={() => {
                setIsNewAddressOpen(true);
              }}
            >
              <img src={blueIcon} alt='plus' />
              Add a new address
            </h4>
          </Grid>
          <Button className='solid-black-btn' onClick={() => setIsOpen(false)}>
            Confirm
          </Button>
        </MyPeopleOverlayWrapper>
      </MuiDrawer>

      <MuiDrawer
        open={isNewAddressOpen!!}
        width={'min(100%, 500px)'}
        hideDrawer={() => setIsNewAddressOpen(false)}
      >
        <MyPeopleOverlayWrapper>
          <h3 className='title'>
            Add a new address
            <img
              className='close'
              src={close}
              alt='close'
              onClick={() => setIsNewAddressOpen(false)}
            />
          </h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid lg={12} md={12} sm={12}>
              {/* <div */}
              {/*   style={{ */}
              {/*     position: 'relative', */}
              {/*   }} */}
              {/* > */}
              {/*   <InputField */}
              {/*     type={HTMLInputTypes.TEXT} */}
              {/*     register={register as UseFormRegister<FormRegisterInputs>} */}
              {/*     errorMessage='This field is required' */}
              {/*     fieldToRegister='fullName' */}
              {/*     placeHolder='Search here' */}
              {/*     required={true} */}
              {/*   /> */}
              {/*   <img */}
              {/*     style={{ */}
              {/*       position: 'absolute', */}
              {/*       top: '10px', */}
              {/*       right: '14px', */}
              {/*       width: '18px', */}
              {/*     }} */}
              {/*     src={search} */}
              {/*     alt='search' */}
              {/*   /> */}
              {/* </div> */}
              {/*<img style={{ width: '100%', marginBottom: '16px' }} src={map} alt='map' />*/}
              <InputField
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                errorMessage='This field is required'
                fieldToRegister='fullName'
                placeHolder='Name of Property e.g.: Home'
                required={true}
              />
              <InputField
                required={true}
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                fieldToRegister='address.addressLine1'
                placeHolder='Address line 1'
                errors={errors}
                errorMessage='Please enter your address'
              />
              <InputField
                required={false}
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                fieldToRegister='address.addressLine2'
                placeHolder='Address line 2'
              />
              <CountrySelect
                selectOptions={Countries}
                placeHolder={'Country/Region'}
                hidePlaceHolder={true}
                fieldToRegister='address.country'
                register={register as UseFormRegister<FormRegisterInputs>}
                /*
                 // @ts-ignore*/
                getValues={getValues}
                errors={errors}
                required={true}
                errorMessage={'Please select your country'}
                onChangeEvent={(value: string) => {
                  if (value === 'India') {
                    resetField('address.state');
                    setIsCountryIndia(true);
                  } else {
                    resetField('address.state');
                    clearErrors('address.pinCode');
                    setIsCountryIndia(false);
                  }
                }}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <InputField
                    type={HTMLInputTypes.TEXT}
                    register={register as UseFormRegister<FormRegisterInputs>}
                    fieldToRegister='address.pinCode'
                    placeHolder='Pin Code'
                    pattern={isCountryIndia ? regex.pinCodeIndia : regex.pinCodeInternational}
                    errors={errors}
                    errorMessage={pincodeErrorMessage}
                    required={isCountryIndia ? true : false}
                    onChangeValue={(value: string) => {
                      setPincode(value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputField
                    type={HTMLInputTypes.TEXT}
                    register={register as UseFormRegister<FormRegisterInputs>}
                    fieldToRegister='address.city'
                    placeHolder='City'
                    required={true}
                    errors={errors}
                    errorMessage='Please enter your city'
                  />
                </Grid>
              </Grid>
              <div className='multipleInputsContainer'></div>
              <SelectField
                selectOptions={NewStates}
                placeHolder={'State/Province/County'}
                hidePlaceHolder={true}
                fieldToRegister='address.state'
                register={register as UseFormRegister<FormRegisterInputs>}
                required={false}
                errors={errors}
                errorMessage='Please select your state'
              />
            </Grid>
            <Button type='submit' className='solid-black-btn'>
              Confirm
            </Button>
          </form>
        </MyPeopleOverlayWrapper>
      </MuiDrawer>
    </Outer>
  );
};
