export enum SectionSummaryScreens {
  SUMMARY = 'SUMMARY',
  PERSONAL_DETAILS = 'PERSONAL_DETAILS',
  PEOPLE = 'PEOPLE',
  ASSETS = 'ASSETS',
  LIABILITIES = 'LIABILITIES',
  ASSETS_DISTRIBUTION = 'ASSETS_DISTRIBUTION',
  GUARDIANS = 'GUARDIANS',
  EXECUTOR = 'EXECUTOR',
  WITNESSES = 'WITNESSES',
}
