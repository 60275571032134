import { breakpoints } from 'styles/Breakpoints';
import styled from 'styled-components';
import theme from 'styles/theme';
import closeButton from 'assets/svg/close.svg'
import YessDetailsModalImg from 'assets/images/dashboard/YessDetailsModal.png'
import CustomButton from 'common/Reusables/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { useState } from 'react';
import MuiDrawer from 'common/Reusables/Drawer';
import { useNavigate } from 'react-router-dom';
import { Pages } from 'routes';

const YessDetailsWrapper = styled.div`
 	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	background: rgba(29, 41, 57, 0.70);

	@media screen and (max-width: ${breakpoints.sm}) {
		z-index: 0;
	}
`;

const DetailsModal = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	
	transform: translate(-50%, -50%);
	
	display: flex;
	flex-direction: row;
	
	background: #FFF;
	border-radius: 1.25rem;
	
	.info{
		display: flex;
		flex-direction: column;
		gap: 2.625rem;
		justify-content: start;
		padding: 1.75rem;
		width: 38rem;
	}

	.heading{
		display: flex;
		flex-direction: column;

		.yessCloseButton{
			//position: relative;
			// top: -1.5rem;
			display: flex;
			justify-content: end;
			cursor: pointer;
		}

		h2 {
			padding: 0 2.5rem;
			color: ${theme.pricingHeadingColor};
			font-family: 'Merriweather Regular';
			font-size: 2rem;
			font-style: normal;
			font-weight: 400;
			line-height: 150%; /* 3rem */
		}

	}

	.pricingInfo{
		padding: 0 2.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;

		h3{
			color: ${theme.pricingHeadingColor};
			font-family: Roboto;
			font-size: 1.375rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			width: 70%;
			background: #F5F2E9;
			padding: 0.4rem 1rem;
			border-radius: 1.25rem;
		}
		p{
			padding: 0.5rem 1.25rem;
			color: ${theme.pricingHeadingColor};
			text-align: right;
			font-family: Roboto;
			font-size: 1.375rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			border-radius: 1.25rem;
			background: #FFDE59;
		}
	}

	.details{
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		padding: 0 3.625rem;

		.detail{
			display: flex;
			gap: 1rem;

			.bulletPoint{
				font-size: 0.625rem;
			}

			p{
				color: ${theme.pricingHeadingColor};
				font-family: Roboto;
				font-size: 1.125rem;
				font-style: normal;
				font-weight: 400;
				line-height: 150%; /* 1.688rem */

				span{
					font-weight: 600;
					font-style: 'Roboto Bold';
				}
			}			
		}
	}
	
	.buyButton{
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 0 3.625rem;


		p{
			font-family: Roboto;
			font-size: 12px;
			font-weight: 400;
			line-height: 14.06px;
			text-align: left;
		}
	}

	@media screen and (max-width: ${breakpoints.sm}) {
		width: auto;
		padding-bottom: 1.5rem;
		position: static;
		transform: none;
		display: block;
		flex-direction: none;
		background: auto;
		border-radius: auto;

		.info{
			gap: 1.25rem;
			padding: 1.75rem 1.5rem 3.5rem 1.5rem;
			width: auto;
		}

		.heading{
			gap: 0.5rem;
			h2 {
				padding: 0;
				color: ${theme.pricingHeadingColor};
				font-family: 'Roboto Bold';
				font-size: 1.25rem;
				font-weight: 700;
				line-height: 1.5rem;
			}

		}

		.pricingInfo{
			padding: 0;

			h3{
				font-size: 1rem;
				font-family: 'Roboto Regular';
				width: 60%;
			}
			p{
				font-size: 1rem;
				padding: 0.5rem 1rem;
				font-family: 'Roboto Bold';
			}
		}

		.detailsImage{
			height: 18.75rem;
			border-radius: 1.25rem;
		}

		.details{
			gap: 0.875rem;
			padding: 0;

			.detail{
				gap: 0.875rem;

				.bulletPoint{
					font-size: 0.5rem;
				}

				p{
					font-family: 'Roboto Regular';
					font-size: 0.875rem;
					line-height: 1.25rem;
				}			
			}
		}

		.buyButton{
			padding: 0;
		}
	}
`;


const YessDetailsModal = ({ 
	YessPrice,
	setShowDetailsModal,
	showDetailsModal, 
}: { 
	YessPrice : string | undefined,
	setShowDetailsModal : Function,
	showDetailsModal: boolean,
}) => {

	const currentTheme = useTheme();
  	const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const buyNowHandler = () => navigate(Pages.PAYMENT + "?selectedPlans=yess");

	const details = [
		{
			title: "Expert Online Guidance: ",
			text: "Our experts provide online support to help guide your family through executing your Will or claiming assets in the absence of a Will.",
		},
		{
			title: "Customized Executor Checklist*: ",
			text: "A custom step-by-step checklist based on your Will, including a comprehensive list of assets, even those added since your last Will update.",
		},
		{
			title: "Comprehensive Data Accessibility*: ",
			text: "Access to all your data, including any video recordings of your Will signing, as well as the latest version of your Will.",
		},
	]

	const checkPaymentsRoute = () => location.pathname.split('/').pop() === 'payments';

  // @ts-ignore
  return (
    <YessDetailsWrapper>
		{
			lessThanSmall ? (
				<MuiDrawer
					open={true}
					hideDrawer={() => setShowDetailsModal(false)}
					anchor={'bottom'}
					height={'auto'}
					borderRadius={'1.5rem 1.5rem 0 0'}
				>
					<DetailsModal>
						<div className="info">
							<div className="heading">
								<div className="yessCloseButton">
									<img
									src={closeButton}
									alt="Close Button"
									onClick={() => setShowDetailsModal(false)}
									/>
								</div>
								<h2>Yellow SMART Remote Support</h2>
								<div className="pricingInfo">
									<h3>Annual Subscription</h3>
									<p>Rs 499</p>
									{/* <p>{YessPrice}</p> */}
								</div>
							</div>
							<img src={YessDetailsModalImg} alt="YessDetailsModalImg" className="detailsImage" />
							<div className="details">
								{
									details.map((detail, index) => {
										return (
											<div className="detail">
												<span className="bulletPoint">&#9679;</span>
												<p><span>{detail.title}</span>{detail.text}</p>
											</div>
										)
									})
								}
							</div>
							<div className="buyButton">
								{!checkPaymentsRoute() &&
									<CustomButton
										style={{
											width: "100%",
											// marginTop: 16,
											height: 'fit-content',
											borderRadius: '0.5rem',
											padding: '0.75rem 1.5rem',
										}}
										onClick={buyNowHandler}
										//If will in progress text is "Continue Will" else it is "Start Now"
										text={'Buy now'}
									/>
								}
								{
									!lessThanSmall &&
									<p>* These services are exclusively available to clients who have created a valid Yellow Will.</p>
								}
							</div>
						</div>
					</DetailsModal>
				</MuiDrawer>
				
			) : (
				<DetailsModal>
					<img src={YessDetailsModalImg} alt="YessDetailsModalImg" />
					<div className="info">
						<div className="heading">
							<div className="yessCloseButton">
								<img
								src={closeButton}
								alt="Close Button"
								onClick={() => setShowDetailsModal(false)}
								/>
							</div>
							<h2>Yellow SMART Remote Support</h2>
							<div className="pricingInfo">
								<h3>Annual Subscription</h3>
								<p>Rs 499</p>
								{/* <p>{YessPrice}</p> */}
							</div>
						</div>
						<div className="details">
							{
								details.map((detail, index) => {
									return (
										<div className="detail">
											<span className="bulletPoint">&#9679;</span>
											<p><span>{detail.title}</span>{detail.text}</p>
										</div>
									)
								})
							}
						</div>
						<div className="buyButton">
							{!checkPaymentsRoute() &&
								<CustomButton
									style={{
										width: "100%",
										// marginTop: 16,
										height: 'fit-content',
										borderRadius: '0.5rem',
										padding: '0.75rem 1.5rem',
									}}
									onClick={buyNowHandler}
									//If will in progress text is "Continue Will" else it is "Start Now"
									text={'Buy now'}
								/>
							}
							<p>* These services are exclusively available to clients who have created a valid Yellow Will.</p>
						</div>
					</div>
				</DetailsModal>
			)	
		}
    </YessDetailsWrapper>
  );
};

export default YessDetailsModal;
