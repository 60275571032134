import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import styled from 'styled-components';
import { breakpoints } from 'styles/Breakpoints';
// import theme from 'styles/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import OneSolutionCards from './OneSolutionCards';
import heroSectionImage from 'assets/images/plan_home_hero_section_image.png';
import blueRightArrow from 'assets/svg/img_arrowright_blue_700.svg';
// import loadingGIFYellow from 'assets/images/loadingGIF.gif';
import loadingGIFYellow from 'assets/Yellow_Loader_WHITEBG.gif';

import CustomButton from 'common/Reusables/Button';
// import { useNavigate } from 'react-router-dom';
import { selectContent, selectUserProfile } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import ArticleListingCard from 'Pages/Resources/Blog/ArticleListingCard';
import ResourcesVideoCard from 'Pages/Resources/Blog/ResourcesVideoCard';
import arrowRight from 'assets/svg/arrow-right-button.svg';
import arrowLeft from 'assets/svg/arrow-left-button.svg';
import ContinueWillCard from 'Pages/Dashboard/ContinueWillCard';
import EnhanceWillCard from 'Pages/Dashboard/EnhanceWillCard';
import theme from 'styles/theme';
import { ShimmerTitle } from 'shimmer-effects-react';

// import DashboardAddOns from './DashboardAddOns';

const DashboardStaticViewWrapper = styled.div`

  .shimmerLoader{
    width: 100%;
    height: 50vh;
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;  
  }

  .shimmerTitleLoader{
    width: fit-content;
    height: 2rem;
  }

  .heroSectionHeading {
    color: #101828;
    font-size: 20px !important;
    font-weight: 700;
  }

  .heroSection {
    display: flex;
    width: 100%;
    background: #ffd662;
    border-radius: 12px;
    overflow: hidden;

    margin-bottom: 30px;
    justify-content: space-between;

    .heroContent {
      width: 100%;
      padding: 30px 5px 30px 30px;

      @media screen and (max-width: ${breakpoints.md}) {
        padding: 20px;
      }

      .heroSectionText {
        padding-top: 16px;
        font-size: 16px;
      }

      .heroSectionCTA {
        margin-top: 30px;
        display: flex;
        font-size: 16px;

        .viewAllPlansButton {
          padding: 0px 10px;
          width: 45%;
          margin-right: 20px;
        }

        .connectContainer {
          display: flex;
          align-items: center;
          text-align: center;

          p {
            padding: 0px 10px 0px 0px;
            text-decoration: underline;
            font-size: 16px;
            color: #1270e8;
            cursor: pointer;
            font-weight: 500;
          }
          img {
            cursor: pointer;
            width: 45px;
            height: 30px;
            min-width: unset;
          }
        }
      }
    }

    img {
      object-fit: fill;
      width: 100%;
      height: 100%;
      // max-height: 26rem;
      min-width: 22rem;
      max-width: 24rem;
    }
  }

  .createWillSection{
    margin-bottom: 30px;
  }

  .planHeading {
    margin-bottom: 2.5rem;

    display: flex;
    justify-content: space-between;

    p{
      font-family: 'Merriweather Regular' !important;
      font-size: 2rem !important;
    }
  }

  .nextStepSection__unpaid{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // margin-bottom: 3.5rem;

    > p{
      color: ${theme.pricingHeadingColor};
      font-family: 'Merriweather Regular';
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .nextStepSections{
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    .heroSection {
      margin-top: 20px;
      flex-direction: column;
      img {
        display: none;
      }

      .heroContent {
        padding: 1.5rem 1rem;
        .heroSectionCTA {
          flex-direction: column;
          // justify-content:center;
          align-items: start;
          gap:1rem;

          img{
            display: block !important;
          }

          .viewAllPlansButton {
            width: 60%;
          }
        }
      }
    }

    .planHeading {
      margin-bottom: 2rem;
      flex-direction: column;
      align-items: start;
      gap: 1rem;
      
      p{
        font-size: 1.5rem !important;
      }
    }

    .nextStepSection__unpaid{
      margin-bottom: 2.5rem;

      p{
        font-size: 1.125rem;
      }

      .nextStepSections{
        gap: 2.5rem;
      }
    }
  }
`;

const ResourceSectionWrapper = styled.div`
  .resourceSection {
    .resourceHeadingSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .resourcesViewAll {
        align-items: center;
        text-align: center;
        text-decoration: underline;
        font-size: 14px;
        color: #1270e8;
        cursor: pointer;
        font-weight: 600;
        // width: min-content;
        white-space: nowrap;
      }

      // @media screen and (max-width: ${breakpoints.md}) {
      //   display: inline;
      // }
    }
  }

  .carousel{
    position: relative;
    height: 380px;
    width: 100%;
    margin-bottom: 1rem;

    .carouselContainer{
      padding: 0.625rem 0.625rem 0 0.625rem;
      padding-bottom: 2rem;
      position: relative;
      overflow: scroll;
      height: 100%;
      transition: transform 0.5
    }
    .carouselContainer::-webkit-scrollbar {
      width: 0rem;
      height: 0.625rem;
    }
    .carouselContainer::-webkit-scrollbar-track {
      margin-left: 2.5rem;
      margin-right: 2.5rem;
      background: lightgray;
      border-radius: 10px;
    }
    .carouselContainer::-webkit-scrollbar-track-piece {
      border-radius: 10px;
      padding: 1rem;
      background: lightgray;
    }
    .carouselContainer::-webkit-scrollbar-thumb {
      background: #1270E8;
      border-radius: 10px;
    }

    .carouselTrack{
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .carouselItem{
      position: absolute;
      top: 0;
      bottom: 0;
      width: 30%;
      height: 100%;
    }

    .carouselButton{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      cursor: pointer;
    }

    .carouselButton--left{
      left: -5%;
    }

    .carouselButton--right{
      right: -5%;
    }
  }

  @media screen and (max-width: ${breakpoints.xs}){
    .carousel{
      margin-bottom: 2.5rem;

      .carouselContainer{
        height: 100%;
      }
      .carouselItem{
        width: 65%;
      }
      .carouselButton{
        top: auto;
        bottom: -20%;

        img{
          width: 2rem;
        }
      },

      .carouselButton--left{
        left: -2%;
      }
  
      .carouselButton--right{
        right: -2%;
      }
    }
  }

    // @media screen and (max-width: ${breakpoints.xs}) {
    //   flexDirection:column;
    //   justify-content:center;
    // }
  }
`;

// const DashboardAddOnsWrapper = styled.div`
//   margin-top: 48px;
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   gap: 1rem;
// `;

const articleToShow = [
  'everyone-needs-to-write-a-will-here-s-why',
  'when-and-with-whom-you-should-share-your-will',
  'nominee-vs-beneficiary-know-the-difference',
  'how-to-write-a-robust-will',
];

const DashboardStaticView = ({
  setShowModal = () => {},
  neverRegistered = true,
  yessPrice
}:{
  setShowModal?: Function,
  neverRegistered?: Boolean,
  yessPrice: string | undefined
}) => {
  // const navigate = useNavigate();
  const content = useAppSelector(selectContent);
  const userProfile = useAppSelector(selectUserProfile);

  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [testatorName, setTestatorName] = React.useState(localStorage.getItem('testatorName') !== null ? localStorage.getItem('testatorName') : (userProfile ? userProfile?.fullName : null));

  // Array of filtered articles, filtered based on slugs present in article to show.
  const filteredArticlesArray = content?.articles.filter((article) =>
    articleToShow.includes(article.slug),
  );

  const carouselInfiniteScroll = () => {
    if (currentIndex === (React.Children.toArray(filteredArticlesArray).length - 1)) {
      return setCurrentIndex(0);
    }
    return setCurrentIndex(currentIndex + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      carouselInfiniteScroll();
    }, 3000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if(userProfile)
      setTestatorName(userProfile?.fullName);

  },[userProfile]);

  console.log(filteredArticlesArray);

  return (
    <DashboardStaticViewWrapper>
      {
        userProfile === null ? (
          <div className="loader">
            <img src={loadingGIFYellow} alt="loading..."/>
          </div>
        ) : (
          userProfile?.wills.length === 0 ? (
            <>
              <Grid className='heroSection'>
                <Grid className='heroContent'>
                  {!lessThanSmall ? (
                    <>
                    <p className='heroSectionHeading font-merriweather'>
                      Don't leave your family unprotected.
                    </p>
                    <p className='heroSectionHeading font-merriweather'> Make your Will with Yellow.</p>
                  </>
                  ) : (
                    <p className='heroSectionHeading font-merriweather'>
                      Don't leave your family unprotected. Make your Will with Yellow.
                    </p>
                    )
                  }
                  <p className='heroSectionText font-roboto'>
                    Creating a Will for your family is every Indian's responsibility. Start now with Yellow,
                    India's most comprehensive legacy and estate planning solution.
                  </p>
                  <Grid className='heroSectionCTA'>
                    <CustomButton
                      text={'View all plans'}
                      className='viewAllPlansButton'
                      onClick={() => {
                        console.log('view all plan');
                          window.open('https://getyellow.in/pricing-wills')
                      }}
                    />
                    <div
                      className='connectContainer'
                      onClick={() => {
                        window.open('https://getyellow.in/legal-will')
                      }}
                    >
                      <p>Know more</p>
                      <img src={blueRightArrow} />
                    </div>
                  </Grid>
                </Grid>
                <Grid>
                  <img src={heroSectionImage} />
                </Grid>
              </Grid>
              <Grid className="createWillSection">
                <p className='heroSectionHeading font-merriweather'>
                  A one-stop solution for all your legacy planning needs
                </p>
                <OneSolutionCards
                  setShowModal={setShowModal}
                />
              </Grid>
            </>
          ) : (
            <>
              {
                testatorName === null ? (
                  <div className="shimmerTitleLoader">
                    <ShimmerTitle mode="light" line={1} gap={1} width={100} height={32} />
                  </div>
                ) : (
                  <Grid lg={12} md={12} sm={12} className='planHeading'>
                    <p>Welcome back, {testatorName}</p>
                  </Grid>
                )
              }
              <Grid lg={12} md={12} sm={12} className='nextStepSection__unpaid'>
                <p>Complete your Journey</p>
                <div className="nextStepSections">
                  <ContinueWillCard
                      neverRegistered={neverRegistered}
                  />
                  <EnhanceWillCard
                    showYess={true}
                    yessPrice={yessPrice}
                  />
                </div>
              </Grid>
            </>
          )
        )
      }

      <ResourceSectionWrapper>
        <Grid className='resourceSection'>
          <div className='resourceHeadingSection '>
            <p className='font-merriweather heroSectionHeading'>
              Helpful Resources
            </p>
              <a
                onClick={() => window.open('https://getyellow.in/resources', '_blank')}
                className='resourcesViewAll'
              >
                View all
              </a>
          </div>

          {/* <Grid item columnGap={2} rowGap={3} container flexWrap='wrap' className="resourcesSectionMobilePosition">
            <ResourcesVideoCard />
            {React.Children.toArray(
              filteredArticlesArray?.map((article) => <ArticleListingCard {...article} />),
            )}
          </Grid> */}
          

          <div className='carousel' >
            <div id="carouselLeft" className="carouselButton carouselButton--left"
              onClick = {() => {
                document.querySelector('.carouselContainer')?.scrollBy({top: 0, ...(lessThanSmall ? {left: -200} :{left: -350}), behavior: 'smooth'})
              }}
            >
              <img src={arrowLeft} alt="Left Arrow" />
            </div>
            <div className="carouselContainer" id="carouselContainer">
              <ul className="carouselTrack">
                <li className="carouselItem">
                  <ResourcesVideoCard />
                </li>
                {React.Children.toArray(
                filteredArticlesArray?.map((article, index) =>
                  <li 
                  className="carouselItem"
                  style={{
                    ...(lessThanSmall ? {left: `${82 + ((index) * 80)}%`} : {left: `${37 + ((index) * 36)}%`}),
                  }}
                  >
                    <ArticleListingCard {...article} />
                  </li>
                ),
              )}
              </ul>
            </div>
            <div id="carouselRight" className="carouselButton carouselButton--right"
            onClick = {() => {
              document.querySelector('.carouselContainer')?.scrollBy({top: 0, ...(lessThanSmall ? {left: 200} :{left: 350}), behavior: 'smooth'})
            }}
            >
              <img src={arrowRight} alt="Right Arrow" />
            </div>
          </div>

        </Grid>
      </ResourceSectionWrapper>
      {/* <DashboardAddOnsWrapper>
          <p className="font-merriweather heroSectionHeading">
            Add-ons
          </p>

          <DashboardAddOns 
            showLegalAddOn={true}
            showPrintAddOn={false}
          />
      </DashboardAddOnsWrapper> */}
    </DashboardStaticViewWrapper>
  );
};

export default DashboardStaticView;
