import { Grid } from '@mui/material';
import closeIcon from 'assets/svg/close.svg';
import { AssetType } from 'utils/types';
import { HtmlTooltip, getTooltipTitleByAssetType } from './HtmlTooltip';
import { assetsIcons } from 'utils';
import { useState } from 'react';
import styled from 'styled-components';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import bulbIcon from 'assets/svg/bulb.svg';
import { breakpoints } from '../../../../styles/Breakpoints';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledWrapper = styled.div`
  .assets-tooltip-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .help_icon {
    cursor: pointer;
    height: 35px;
    width: 35px;
    margin-left: 12px;
  }

  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
  }
  .head {
    @media screen and (max-width: ${breakpoints.md}) {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      display: flex;
    }
  }
`;
const OverlayHead = ({
  heading,
  onClick,
  showToolTip,
  assetType,
  showHelp = false,
  setShowHelp = null,
  isShowImage,
}: {
  heading: string;
  onClick: Function;
  showToolTip?: Boolean;
  assetType?: AssetType;
  showHelp?: boolean;
  setShowHelp?: Function | null;
  isShowImage?: boolean;
}) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [displayTooltipBadge, setDisplayTooltipBadge] = useState(false);

  const onHelpClick = () => {
    if (setShowHelp) {
      setShowHelp();
    }
  };

  return (
    <StyledWrapper>
      <Grid
        item
        lg={12}
        display={'flex'}
        justifyContent='space-between'
        alignItems={'center'}
        className='head'
      >
        <div className='assets-tooltip-header'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {isShowImage ? (
                <img
                  src={bulbIcon}
                  alt='bulb'
                  style={{ marginRight: '8px', width: '26px', height: '26px' }}
                />
              ) : null}
              <h4 className='heading6'>{heading}</h4>
            </div>
            {showHelp ? (
              lessThanSmall ? (
                <HelpOutlineIcon onClick={onHelpClick} />
              ) : (
                <img src={helpIcon} alt='Help Icon' className='help_icon' onClick={onHelpClick} />
              )
            ) : null}
          </div>
          <div
            onMouseEnter={() => setDisplayTooltipBadge(true)}
            onMouseLeave={() => setDisplayTooltipBadge(false)}
          >
            {showToolTip && (
              <HtmlTooltip
                open={displayTooltipBadge}
                title={getTooltipTitleByAssetType(assetType as AssetType)}
                arrow
                placement='right-end'
                style={{ paddingLeft: '10px' }}
              >
                <img src={assetsIcons.tooltip} alt='' className='cursor-pointer tooltipIcon' />
              </HtmlTooltip>
            )}
          </div>
        </div>

        <img
          src={closeIcon}
          alt=''
          className='cursor-pointer close_icon'
          onClick={() => onClick()}
          style={{
            height: '18px',
            objectFit: 'contain',
          }}
        />
      </Grid>
    </StyledWrapper>
  );
};

export default OverlayHead;
