import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import congratulations from 'assets/svg/congratulations.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const style = (lessThanSmall?: boolean) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(90%, 650px)',
  boxSizing: 'border-box',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: lessThanSmall ? '20px' : '40px 52px 54px 32px',
  borderRadius: '12px',
});
const style2 = {
  background: 'rgba(0,0,0,0.4)',
};

export default function CongratulationsJustCompletedWindow({
  isOpenModal,
  handleModalClose,
}: {
  isOpenModal: boolean;
  handleModalClose: Function;
}) {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  return (
    <Modal
      sx={style2}
      open={isOpenModal}
      onClose={() => handleModalClose()}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style(lessThanSmall)}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <CloseIcon
            style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
            onClick={() => handleModalClose()}
          />
        </div>
        <div
          style={{
            display: 'flex',
            ...(lessThanSmall
              ? { flexDirection: 'column', justifyContent: 'center' }
              : { justifyContent: 'space-between' }),
            alignItems: 'center',
          }}
        >
          <img src={congratulations} style={{ height: '150px', width: '150px' }} />
          <div
            style={{
              display: 'flex',
              ...(lessThanSmall ? { flexDirection: 'column', justifyContent: 'center' } : {}),
              alignItems: 'center',
            }}
          >
            <Grid display={'flex'} ml={5} flexDirection={'column'} alignItems={'flex-start'}>
              <p className='heading6'>Congratulations!</p>
              <p style={{ margin: '16px 0px', color: '#101828a3' }}>
                You’ve completed the process of safeguarding your legacy by recording the signing of
                your Will.
              </p>
              <p style={{ margin: '16px 0px', color: '#101828a3' }}>
                Sit back and relax, knowing that your Will is now airtight.
              </p>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
