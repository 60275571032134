import * as React from 'react';
// import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import useMediaQuery from '@mui/material/useMediaQuery';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';

export default function CopySnackBar({open, closeSnackBar}: {open: boolean; closeSnackBar: Function;}) {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    closeSnackBar();
  };
  const isMobile = useMediaQuery('(max-width:600px)');

  //   const action = (
  //     <React.Fragment>
  //       <Button color='secondary' size='small' onClick={handleClose}>
  //         UNDO
  //       </Button>
  //       <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
  //         <CloseIcon fontSize='small' />
  //       </IconButton>
  //     </React.Fragment>
  //   );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message='Link copied'
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          width: isMobile ? '95%' : 'auto', // Set width to 95% on mobile
          '&.MuiSnackbarContent-root': {
            textAlign: 'center',
            whiteSpace: 'nowrap', // Prevent text wrapping
          },
          '&.MuiSnackbarContent-message': {
            textAlign: 'center', // Center text horizontally
            display: 'flex',
            alignItems: 'center', // Center text vertically
            justifyContent: 'center', // Center text horizontally
            width: isMobile ? '90vw' : '40vw'
          },
        }}
        // action={action}
      />
    </div>
  );
}
