import { Checkbox, Grid, useMediaQuery } from '@mui/material';
import contact from 'assets/svg/menuItem-contact.svg';
import book from 'assets/svg/book.svg';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import MuiDrawer from 'common/Reusables/Drawer';
import { getEmptyAddress } from 'utils';
import { useTheme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AssetSubTypesWrapper, HelpWrapper, QueryHelpCard } from '../../NewMyWill';
import OverlayHead from '../../CreateWill/Steps/OverlayHead';
import { PrintAndCourierAddressForm } from './PrintAndCourierOld';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
import { PrintAndCourierDetails } from 'utils/types';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import { useVendor } from 'common/useVendor';
import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';

export const CourierRequestForm = ({ resetToMyWill }: { resetToMyWill: Function }) => {
  const userActiveWill = useAppSelector(selectActiveWill);
  // @ts-ignore
  const [showStepper, setShowStepper] = useState(false);
  const themew = useTheme();
  const { isAcko } = useVendor();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const [personalAddress, setPersonalAddress] = useState(true);
  // @ts-ignore
  const [loading, setLoading] = useState<boolean>(false);
  const [printAndCourierScreen, setPrintAndCourierScreen] = useState<number>(1);
  const [showInfoScreen, setShowInfoScreen] = useState<boolean>(false);
  // @ts-ignore
  const [submitWholeForm, setSubmitWholeForm] = useState<boolean>(false);

  useEffect(() => {
    if (
      userActiveWill &&
      userActiveWill.courierRequestInfo &&
      userActiveWill.courierRequestInfo !== null
    )
      setShowStepper(true);

  }, [userActiveWill]);

  const onHelpClickRightSide = () => {
    setShowInfoScreen(true);
    Mixpanel.track('Question mark button clicked', {
      card_name: 'Print & Courier Will',
    });
  };


  return <Grid
    item
    sm={12}
    xs={9}
    md={7}
    style={{
      ...(!lessThanSmall || printAndCourierScreen === 1 ? {} : { display: 'none' }),
      ...(lessThanSmall ? { padding: "40px" } : { padding: "40px 25%" })
    }}
  >
    <div className={'hide_in_mobile'}>
      <BackToSectionSummary
        noMargin={true}
        text='Back to My Will'
        action={resetToMyWill}
      />
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
        alignItems: 'center',
        ...(lessThanSmall ? { width: '100%' } : {}),
      }}
    >
      <h3
        className={'font-roboto-bold'}
        style={{
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: lessThanSmall ? '24px' : '32px',
          lineHeight: '40px',
          color: '#101828',
        }}
      >
        Print & Courier Will
      </h3>
      <div className={'hide_in_mobile'}>
        {lessThanSmall ? (
          <HelpOutlineIcon onClick={onHelpClickRightSide} />
        ) : (
          <img
            style={{ height: '60px', cursor: 'pointer' }}
            src={helpIcon}
            alt='Help Icon'
            onClick={onHelpClickRightSide}
          />
        )}
      </div>
    </div>
    <MuiDrawer
      open={showInfoScreen}
      hideDrawer={() => setShowInfoScreen(false)}
      width={'min(100%, 500px)'}
    >
      <AssetSubTypesWrapper>
        <OverlayHead
          heading='Print & Courier Will'
          onClick={() => setShowInfoScreen(false)}
          setShowHelp={() => setShowInfoScreen(true)}
        />
        <p className='notify-help-text'>
          According to the current law in India, a Will is not valid unless it has been
          physically signed in the presence of 2 witnesses.
        </p>
        <p className='notify-help-text'>
          If you would like, we are happy to print and ship your Will to you free of
          charge*. You can also get a soft copy emailed to you and print it out
          yourself.
        </p>
        <p className='notify-help-text' style={{ color: '#666B75' }}>
          * Free for first-time Will requests only. Updates may be printed and shipped
          for a fee.
        </p>
      </AssetSubTypesWrapper>
      <HelpWrapper container>
        {!isAcko && (
          <Grid
            item
            lg={12}
            className='queries_section'
            display={'flex'}
            flexDirection='column'
            justifyContent='space-around'
            alignItems={'center'}
          >
            <p className='subText font-semi-bold'>Have a specific query in mind?</p>
            <QueryHelpCard
              icon={book}
              text='Explore FAQs'
              onClick={() => window.open('https://getyellow.in/faq', '_blank')}
            />
            <QueryHelpCard
              icon={contact}
              text='Contact Us'
              onClick={Intercom.hideOverLayAndShowIntercom({
                setShowOverlay: setShowInfoScreen,
              })}
            />
          </Grid>
        )}
      </HelpWrapper>
    </MuiDrawer>
    <p className='intro font-roboto'>
      A copy of your latest Will and testament will be printed and couriered to you.
    </p>
    <h4 className='label subText font-roboto' style={{ marginTop: "40px" }}>Confirm your details below </h4>
    {personalAddress && userActiveWill ? (
      <PrintAndCourierAddressForm
        showTracker={() => setShowStepper(true)}
        setLoading={setLoading}
        personalAddress={personalAddress}
        defaultValues={
          {
            email: userActiveWill.testatorDetails?.email,
            name: userActiveWill.testatorDetails?.fullName,
            phoneNumber: userActiveWill.testatorDetails?.phoneNumber,
            mailingAddress:
              userActiveWill.testatorDetails?.address ?? getEmptyAddress(),
          } as PrintAndCourierDetails
        }
        printAndCourierScreen={printAndCourierScreen}
        setPrintAndCourierScreen={setPrintAndCourierScreen}
        submitWholeForm={submitWholeForm}
      />
    ) : (
      <PrintAndCourierAddressForm
        showTracker={() => setShowStepper(true)}
        setLoading={setLoading}
        defaultValues={
          {
            email: userActiveWill!.testatorDetails?.email,
            name: userActiveWill!.testatorDetails?.fullName,
            phoneNumber: userActiveWill!.testatorDetails?.phoneNumber,
            mailingAddress: getEmptyAddress(),
          } as PrintAndCourierDetails
        }
        personalAddress={personalAddress}
        printAndCourierScreen={printAndCourierScreen}
        setPrintAndCourierScreen={setPrintAndCourierScreen}
        submitWholeForm={submitWholeForm}
      />
    )}
    <div className={'font-roboto'} style={{ alignItems: 'center', display: 'flex', marginBottom: '64px' }}>
      <Checkbox
        checked={personalAddress}
        sx={{
          '&.Mui-checked': {
            color: '#1D2939',
          },
        }}
        onChange={() => setPersonalAddress(!personalAddress)}
      />
      Same as address in Personal Details
    </div>
  </Grid>
}
