import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import CustomButton from 'common/Reusables/Button';
import { InputWrapper } from 'common/forms/Wrappers';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
// import { runMutation } from 'utils/graphQL';
// import { GENERATE_PREVIEW_OF_WILL } from 'common/queries';
import { useState } from 'react';
import { useGenerateWill } from 'common/useGenerateWill';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(75%, 600px)',
  maxWidth: 'min(75%, 600px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '10px',
};

const style2 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(50%, 600px)',
  maxWidth: 'min(50%, 600px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '10px',
};

// const buttonStyle = {

// }

export default function EmailSendModal({
  handleOpenCloseModal,
  isShowModal,
}: {
  handleOpenCloseModal: Function;
  isShowModal: boolean;
}) {
  const userActiveWill = useAppSelector(selectActiveWill);
  const [generateWillButtonPressed, setGenerateWillButtonPressed] = useState<boolean>(false);
  const { generateWill } = useGenerateWill();

  return (
    <div>
      <Modal
        open={isShowModal}
        onClose={() => handleOpenCloseModal()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        {!generateWillButtonPressed ? (
          <Box sx={style}>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <p className='heading6'>Confirm</p>
              <CloseIcon
                style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
                onClick={() => handleOpenCloseModal()}
              />
            </Grid>
            {/* <p style={{ margin: '12px 0px' }}>
            Complete these following steps to Generate your Will:
          </p> */}
            <InputWrapper style={{ margin: '24px 0px' }}>
              <input type='email' value={userActiveWill?.testatorDetails?.email} />
            </InputWrapper>
            <CustomButton
              text={'Send via E-mail'}
              // disabled={generatingWill}
              className='continueWillBtn'
              onClick={async () => {
                if (userActiveWill?.documentId) {
                  generateWill(userActiveWill?.documentId);
                  setGenerateWillButtonPressed(true);
                }
              }}
              style={{ width: '100%' }}
            />
          </Box>
        ) : (
          <Box sx={style2}>
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <p className='heading6'>E-mail My Will</p>
              <CloseIcon
                style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
                onClick={() => {
                  handleOpenCloseModal()
                  setGenerateWillButtonPressed(false);
                }}
              />
            </Grid>
            <p style={{ margin: '24px 0px' }}>
              {/* <span style={{ color: '#555' }}>Your latest Will draft has been emailed to</span>{' '} */}
              {/* <span style={{ fontWeight: 'bolder' }}>{userActiveWill?.testatorDetails?.email}</span> */}
              <p>We have received your request. Your Will will be emailed to you shortly.</p>
              <p>In the meantime, check out the other actions you can take to help make your Will more robust.</p>
            </p>

            <CustomButton
              text={'Got it, thanks!'}
              // disabled={generatingWill}
              className='continueWillBtn'
              onClick={() => {
                handleOpenCloseModal();
                setGenerateWillButtonPressed(false);
              }}
              style={{ width: '100%' }}
            />
          </Box>
        )}
      </Modal>
    </div>
  );
}
