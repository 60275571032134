import React, { useEffect, useState } from 'react';
import { SelectProps } from 'utils/types';
import { checkForErrors } from '../formUtils';
import chevronDown from 'assets/svg/chevronDown.svg';
import chevronDownInactive from 'assets/svg/chevronDownInactive.svg';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  .errorMessage {
    margin: 10px 0px;
    color: #ba1b1b;
  }
`;

const StyledSelect = styled.select`
  height: 48px;
  width: 100%;
  padding: 5px 10px;
  border-radius: 12px;
  border: ${(props: { valid: boolean }) => (props.valid ? '0' : '0')};
  opacity: 0.8;
  font-size: 16px;

  :focus {
    outline: 1;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */

  :disabled {
    cursor: not-allowed;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  // top: ${(props: { hiddenLabel: boolean }) => (props.hiddenLabel ? '25px' : '46px')};
  top: ${(props: { hiddenLabel: boolean }) => (props.hiddenLabel ? '50%' : '46px')};
  right: 20px;
`;

const CountrySelect = ({
  selectOptions,
  register,
  errors,
  errorMessage,
  fieldToRegister,
  placeHolder,
  hidePlaceHolder,
  required = false,
  pattern,
  onChangeEvent,
  disabled = false,
  value = undefined,
  labelStyle,
  getValues,
  defaultValue
}: SelectProps) => {
  const registerConfig = {
    required,
    ...(pattern ? { pattern } : {}), // Uses pattern only if any valid regex is passed as a prop.
  };

  const [valid, setValid] = useState(false);

  const isValid = () => {
    if (getValues && getValues(fieldToRegister)) return true;
    return false;
  };

  useEffect(() => {
    if (isValid()) setValid(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {!hidePlaceHolder && (
        <p style={labelStyle} className='font-semi-bold'>
          {placeHolder}
        </p>
      )}
      <StyledSelect
        className='inputBackground'
        valid={valid}
        value={value}
        disabled={disabled}
        {...register(fieldToRegister, registerConfig)}
        onChange={({ target: { value } }) =>
          onChangeEvent ? onChangeEvent(value) : setValid(true)
        }
        defaultValue={defaultValue}
      >
        <option value='' selected>
          {required ? `${placeHolder}*` : placeHolder}
        </option>
        {React.Children.toArray(
          Object.keys(selectOptions).map((key) => {
            const optionValue = selectOptions[key as keyof typeof selectOptions];
            return <option value={optionValue}>{optionValue}</option>;
          }),
        )}
        {/* {selectOptions.map((each: any) => (
          <option value={each.value}>{each.label}</option>
        ))} */}
      </StyledSelect>
      <StyledImage
        hiddenLabel={hidePlaceHolder || false}
        src={valid ? chevronDown : chevronDownInactive}
        alt=''
        className='dropdown_icon'
      />
      <br />
      {errors && Object.keys(errors).length > 0 && checkForErrors({ fieldToRegister, errors }) && (
        <p className='errorMessage'>{errorMessage}</p>
      )}
    </Wrapper>
  );
};

export default CountrySelect;
