import { useEffect, useState } from 'react';
import styled from 'styled-components';
import pigeon from 'assets/images/pigeon.png';
import CustomButton from 'common/Reusables/Button';
import BackToSectionSummary from '../../BackToSectionSummary';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import MuiDrawer from 'common/Reusables/Drawer';
import { Grid } from '@mui/material';
import { Mixpanel } from 'utils/mixpanel';
import OverlayHead from '../../CreateWill/Steps/OverlayHead';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import { AssetSubTypesWrapper, HelpWrapper, QueryHelpCard } from '../../NewMyWill';
import { Intercom } from '../../../../utils/intercom';
import Header from '../../../../common/Header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { breakpoints } from '../../../../styles/Breakpoints';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useVendor } from 'common/useVendor';
import { selectActiveWill, selectCurrentUserPlans } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import { MainPlanModules } from 'store/payments/paymentsSaga';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pages } from 'routes';
import { CourierRequestForm } from './CourierRequestForm';
import PrintAndCourierRequestTracker from './RequestStatusTracker';
import { useDispatch } from 'react-redux';
import { fetchCurrentUserPlans } from 'store/user/slice';
import Navbar from 'Pages/Home/Navbar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
  padding-left: 33px;
  padding-right: 33px;
  @media screen and (max-width: ${breakpoints.md}) {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
  }
  .title {
    font-weight: 700;
    font-size: 52px;
    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 24px;
    }
  }

  .pigeon {
    width: 294px;
    height: 258px;
    margin-top: 36px;
    margin-bottom: 48px;
    @media screen and (max-width: ${breakpoints.md}) {
      width: 136px;
      height: 119px;
      margin-top: 48px;
      margin-bottom: 48px;
    }
  }

  .info-text {
    margin-top: 50px;
    margin-bottom: 56px;
    font-weight: 700;
    font-size: 28px;
    @media screen and (max-width: ${breakpoints.md}) {
      margin: 0px;
      text-align: center;
      margin-bottom: 32px;
      font-size: 20px;
    }
  }
`;

export default function PrintAndCourierFlow({ resetToMyWill }: { resetToMyWill: Function }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [nextPage, setNextPage] = useState<boolean>(location.state?.fromPayment || false);
  const [showInfoScreen, setShowInfoScreen] = useState<boolean>(false);
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const { isAcko } = useVendor();
  const userPlans = useAppSelector(selectCurrentUserPlans);
  const [needsPayment, setNeedsPayment] = useState<boolean>(true);
  const userActiveWill = useAppSelector(selectActiveWill);
  const [showStepper, setShowStepper] = useState(false);

  useEffect(() => {
    dispatch(fetchCurrentUserPlans());
  }, [])

  useEffect(() => {
    if (!userPlans.data?.fetchPlans) return;
    if (userPlans.data?.fetchPlans.length === 0) return;
    
    const activePlan = userPlans.data.fetchPlans.filter(pl => pl.isActive)[0];
    if(Date.now() > Number(activePlan.expireAt)) return;

    // @ts-ignore
    const shippingAndCourier = activePlan.modules[MainPlanModules.SHIPPING_AND_COURIER];

    if (shippingAndCourier && shippingAndCourier.enabled && shippingAndCourier.useCount > 0) {
      setNeedsPayment(false);
    } else {
      setNeedsPayment(true);
    }

  }, [userPlans])

  useEffect(() => {
    if (
      userActiveWill &&
      userActiveWill.courierRequestInfo &&
      userActiveWill.courierRequestInfo !== null
    ){
      setShowStepper(true);
    }
  }, [userActiveWill]);

  // MainPlanModules.SHIPPING_AND_COURIER;

  const onHelpClickRightSide = () => {
    setShowInfoScreen(true);
    Mixpanel.track('Question mark button clicked', {
      card_name: 'Print & Courier Will',
    });
  };

  if (showStepper) return <PrintAndCourierRequestTracker />

  return (
    <>
      <div className={'hide_in_mobile'}>
        <Navbar backgroundColorClass='background_white' />
      </div>
      {/* <PrintAndCourier resetToMyWill={resetToMyWill} /> */}
      {nextPage ? (
        <CourierRequestForm resetToMyWill={resetToMyWill} />
      ) : (
        <>
          <div
            style={{
              position: 'relative',
              top: 0,
              display: 'flex',
              justifyContent: 'space-between',
              ...(lessThanSmall
                ? {
                  alignItems: 'center',
                  paddingLeft: '24px',
                  paddingRight: '30px',
                  paddingTop: '50px',
                }
                : {
                  alignItems: 'center',
                  paddingLeft: '80px',
                  paddingRight: '80px',
                  paddingTop: '33px',
                }),
            }}
          >
            <div className={'hide_in_mobile'}>
              <BackToSectionSummary
                noMargin={true}
                text='Back to My Will'
                action={resetToMyWill}
              />
            </div>
            <div className={'show_in_mobile w-100'}>
              <Header
                // backgroundColorClass={userActiveWill ? 'background_white' : 'background_lightBeige'}
                backgroundColorClass={'background_white'}
                // @ts-ignore
                centerItems={true}
                backButton={true}
                title={'My Will'}
                backButtonOnClick={resetToMyWill}
                hideAvatar={true}
                renderRightHandSide={
                  lessThanSmall ? (
                    <HelpOutlineIcon onClick={onHelpClickRightSide} />
                  ) : (
                    <img
                      style={{
                        height: '60px',
                        ...(lessThanSmall ? {} : { marginTop: '33px', marginRight: '80px' }),

                        cursor: 'pointer',
                      }}
                      src={helpIcon}
                      alt='Help Icon'
                      onClick={onHelpClickRightSide}
                    />
                  )
                }
              />
            </div>
            <div className={'hide_in_mobile'}>
              {lessThanSmall ? (
                <HelpOutlineIcon onClick={onHelpClickRightSide} />
              ) : (
                <img
                  style={{
                    height: '60px',
                    ...(lessThanSmall ? {} : { marginTop: '33px', marginRight: '80px' }),

                    cursor: 'pointer',
                  }}
                  src={helpIcon}
                  alt='Help Icon'
                  onClick={onHelpClickRightSide}
                />
              )}
            </div>
          </div>
          <MuiDrawer
            open={showInfoScreen}
            hideDrawer={() => setShowInfoScreen(false)}
            width={'min(100%, 500px)'}
          >
            <AssetSubTypesWrapper>
              <OverlayHead
                heading='Print & Courier Will'
                onClick={() => setShowInfoScreen(false)}
                setShowHelp={() => setShowInfoScreen(true)}
              />
              <p className='notify-help-text'>
                According to the current law in India, a Will is not valid unless it has been
                physically signed in the presence of 2 witnesses.
              </p>
              <p className='notify-help-text'>
                If you would like, we are happy to print and ship your Will to you free of charge*.
                You can also get a soft copy emailed to you and print it out yourself.
              </p>
              <p className='notify-help-text' style={{ color: '#666B75' }}>
                * Free for first-time Will requests only. Updates may be printed and shipped for a
                fee.
              </p>
            </AssetSubTypesWrapper>
            <HelpWrapper container>
              {!isAcko && (
                <Grid
                  item
                  lg={12}
                  className='queries_section'
                  display={'flex'}
                  flexDirection='column'
                  justifyContent='space-around'
                  alignItems={'center'}
                >
                  <p className='subText font-semi-bold'>Have a specific query in mind?</p>
                  <QueryHelpCard
                    icon={book}
                    text='Explore FAQs'
                    onClick={() => window.open('https://getyellow.in/faq', '_blank')}
                  />
                  <QueryHelpCard
                    icon={contact}
                    text='Contact Us'
                    onClick={Intercom.hideOverLayAndShowIntercom({
                      setShowOverlay: setShowInfoScreen,
                    })}
                  />
                </Grid>
              )}
            </HelpWrapper>
          </MuiDrawer>
          <Wrapper>
            <p className='title'>Print & Courier Will</p>
            <img src={pigeon} alt='' className='pigeon' />
            <p className='info-text'>Get your Will shipped to you</p>
            {/* <div className={'show_in_mobile'}> */}
            {/*   <div */}
            {/*     className={'flex flex-row p-16px items-center justify-center gap-16px'} */}
            {/*     style={{ */}
            {/*       background: '#FFF8DD', */}
            {/*       borderRadius: 12, */}
            {/*       border: '0.50px #FFC632 solid', */}
            {/*     }} */}
            {/*   > */}
            {/*     <div> */}
            {/*       <img className={'h-24px w-24px'} src={print_courier_first_time_info_icon} /> */}
            {/*     </div> */}
            {/*     <div> */}
            {/*       <span */}
            {/*         className={'font-roboto'} */}
            {/*         style={{ color: '#675E40', fontSize: '14px', verticalAlign: 'middle' }} */}
            {/*       > */}
            {/*         This service is complimentary for first-time requests */}
            {/*       </span> */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </div> */}
            <CustomButton
              text='Get Started'
              style={
                lessThanSmall
                  ? { position: 'absolute', bottom: 0, width: '100%', borderRadius: '0px' }
                  : { width: '368px', borderRadius: '12px' }
              }
              onClick={() => {
                if (needsPayment) {
                  navigate(Pages.PAYMENT + "?selectedPlans=shippingAndCourier", { state: { fromCourier: true } });
                } else {
                  setNextPage(true)
                }
              }}
            />
          </Wrapper>
        </>
      )}
    </>
  );
}
