import { BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';
import willIcon from 'assets/svg/faq-basicWill-V2.svg';
import talkToExpertIcon from 'assets/svg/talkToExpertYellow.svg';
import arrowIcon from 'assets/svg/arrow-right-clear-bg.svg';
import evenIcon from 'assets/svg/even_blue_icon.svg';
import bottom_navigation_home from 'assets/svg/bottom_navigation_home.svg';
import bottom_navigation_resources from 'assets/svg/bottom_navigation_resources.svg';
import bottom_navigation_my_will from 'assets/svg/bottom_navigation_my_will.svg';
// import shareIcon from 'assets/svg/shareIcon.svg';
// import couponCodeIcon from 'assets/svg/couponCodeIconRefer.svg';
// import succesfulReferalIcon from 'assets/svg/succesfulReferal.svg';
import needHelpIcon from 'assets/svg/needHelpIcon.svg';
import referIcon from 'assets/svg/referAndEarnIcon.svg';
import blueRightArrow from 'assets/svg/img_arrowright_blue_700.svg';
import Header from 'common/Header';
import { breakpoints } from 'styles/Breakpoints';
import theme from 'styles/theme';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
// import React, { useState, useEffect, useRef } from 'react';
import DashboardStaticView from './DashboardStaticView';
import CustomButton from 'common/Reusables/Button';
import { useAppSelector } from 'store/hooks';
// import { selectedPlans } from "store/payments";
import { UserStatus } from 'store/user/slice';
import CongratulationsFlowInformForm from './CongratulationsFlowInformForm';
import {
  selectUserStatus,
  selectCurrentUserPlans,
  selectYellowPlusDefaultPlansConfig,
  selectSendWillPreviousRecipients,
  selectUserProfile,
  // selectUserProfile,
} from '../../store/user/selectors';
import Loading from 'common/Loading';
import { Pages, YELLOW_PLUS_LEGAL, YELLOW_RESOURCES } from 'routes';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import get from 'lodash.get';
import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';
// import { openReferralFactory } from '../../utils';
import YessSupport from '../YessPlus/QuickCart/YessSupport';
import useReferralFactoryScript from 'Pages/Dashboard/useReferralFactoryScript';

export const DashboardWrapper = styled.div`
  background-color: #fcfcfc;

  .container {
    color: #101828;
    display: flex;
    background-color: #fcfcfc;
    // height: 100vh;

    .navbar {
      height: fit-content;
      padding: 0px;
      margin: 0px;
      width: 100%;
    }

    .mobilePlanHeading {
      display: none;
    }

    .content {
      height: 100%;
      padding: 40px;
      // margin-bottom: 35px;
      // background-color: #fcfcfc;

      p {
        font-size: 24px;
      }

      h3 {
        width: 80%;
      }

      .yellowWill {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 18px;
        padding: 10px 20px 10px 20px;
        height: 5em;
        background-color: #ffe171;
        border-radius: 12px;

        h3 {
          padding-left: 20px;
          font-weight: 700;
          font-size: 20px;
        }

        img {
          width: 50px;
          height: 50px;
        }

        :hover {
          cursor: pointer;
        }

        .smallCardText {
          width: 100%;
          flex-direction: row;
          display: flex;
          align-items: center;
        }

        .rightArrow {
          display: flex;
          justify-content: center;
          padding-right: 10px;
          img {
            width: 22px;
          }
        }
      }

      .talkToExpert {
        background-color: #ffffff;
        border: 2px solid #e3e4e7;
      }
      .yessAddOn{
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        div.width {
          width: 45%;
        }

        .addOnHeader{
          color: ${theme.pricingHeadingColor};
          font-family: Roboto;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .text{
          width: auto !important;
        }

        p{
          width: 100%;
        }
      }
    }

    .planSection {
      height: 100%;
      background-color: #f3f3f3;
      padding: 40px;

      .rightSectionHeadingPlan {
        margin-bottom: 20px;
      }

      h3 {
        font-family: 'Merriweather' !important;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 30px;

        .font-roboto {
          font-family: 'Roboto Thin' !important;
        }
      }

      .needHelpCard {
        margin-top: 20px;
      }

      .needHelpAndReferCard {
        border: 2px solid #e3e4e7;
        border-radius: 12px;
        cursor: default;
        display: flex;
        justify-content: space-between;

        img {
          height: 64px;
        }

        .decoratorImg {
          margin: -20px;
        }

        .needHelpText {
          padding: 20px;

          .contactContainer{
            display: flex;
            align-items: center;
            text-align: center;

            p {
              padding: 0px 10px 0px 0px;
              font-weight: 600;
              text-align: left;
            }
          }

          .connectContainer {
            display: flex;
            align-items: center;
            text-align: center;

            p {
              padding: 0px 10px 0px 0px;
              text-decoration: underline;
              color: #1270e8;
              cursor: pointer;
              font-weight: 600;
              text-align: left;
            }
            img {
              cursor: pointer;
              width: 45px;
              height: 30px;
            }
          }

          .referAndEarnButton {
            text-decoration: None !important;
          }

          .borderDashed {
            display: inline;
            border-left: 2px dashed #757575;
            margin-top: 3.5rem;
            margin-bottom: 3.5rem;
            width: 2px;
            margin-right: -1.4rem;
          }

          h3 {
            font-family: 'Roboto Bold' !important;
            font-size: 18px;
            margin-bottom: 0px;
            padding-bottom: 8px;
          }

          p {
            font-size: 14px;
            padding-bottom: 10px;
          }

          .pointHolder {
            margin-top: 14px;
            .points {
              margin-bottom: 26px;

              :last-child {
                margin-bottom: 0px;
              }

              span {
                font-size: 16px;
                line-height: 1.5;

                h3 {
                  font-size: 14px;
                  padding-bottom: 0px;
                }

                p {
                  color: rgba(16, 24, 40, 0.64);
                  b {
                    color: #101828;
                  }
                }
              }

              .avatar {
                radius: 0px;
                margin-right: 24px;
                height: 40px;
                width: 40px;
              }
            }
          }
        }
      }
    }

    .addOnsContainer {
      padding-top: 30px;

      .addOnsCardLink {
        color: #101828;
        cursor: pointer;
        .addOnsCard {
          margin-top: 10px;
          width: 100% !important;
          border-radius: 12px;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          width: max-content;
          padding: 24px;
          background-color: #ddeeff;

          .evenAddOnTextLeft {
            padding-right: 20px;
            border-right: 1.2px solid rgba(29, 41, 57, 0.24);
          }

          .subContainerCard {
            width: 100% !important;
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            width: max-content;

            h3 {
              font-size: 20px !important;
            }
          }

          .evenAddOnText {
            padding-left: 24px;
            h3 {
              width: 100%;
              font-size: 20px;
              padding-bottom: 10px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    .leftContainer {
      width: 100%;
      // padding-right: 20px;
      // display: flex;
      // flex-direction: column;
      // gap: 1.125rem;
    }

    .footerContainer {
      position: relative;
      // display: fixed;
      margin-bottom: 10px;
      bottom: 0;
      // left: 0;
      padding: 0px 60px 0px 80px;
      width: 100%;
      .footerLinks {
        margin: 15px 0px 15px 0px;
        display: flex;
        text-align: center;
        width: 100%;

        .privacy-text {
          color: #101828;
          margin-right: 30px;
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    display: flex;
    flex-direction: column-reverse;
    .container {
      display: flex;
      padding: 0px;
      background-color: #fcfcfc;
      height: calc(100vh - 70px);
      overflow-y: scroll;

      .navbar {
        // padding: 30px 30px 0px 30px;
        padding-top: 24px;
      }

      .mobilePlanHeading {
        display: flex;
        p {
          font-family: 'Merriweather' !important;
          font-size: 24px;
        }
      }

      .addOnsContainer {
        p {
          font-size: 18px;
        }

        .addOnsCardLink {
          .addOnsCard {
            padding: 16px;
            width: 100% !important;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .iconsAddon {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
            }

            .evenAddOnTextLeft {
              padding-bottom: 20px !important;
              border-right: none;
              border-bottom: 1.2px solid rgba(29, 41, 57, 0.24);
            }

            .subContainerCard {
              width: 100% !important;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
            }

            .evenAddOnText {
              padding: 18px 0px 0px 0px;
              h3 {
                width: 100%;
                font-size: 18px;
                padding-bottom: 10px;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }

      .content {
        width: 100%;
        // height: 50%;
        min-height: 0vh;
        padding: 0px 24px 0px 24px;
        margin-bottom: 50px;

        .congratsMessage {
          p {
            font-size: 18px;
            padding-top: 15px;
          }
        }

        h3 {
          font-size: 16px;
          width: 70%;
        }

        .yellowWill {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px;
          padding: 10px 20px 10px 20px;
          height: 4em;

          border-radius: 12px;

          h3 {
            font-size: 16px;
          }

          img {
            width: 40px;
            height: 40px;
          }

          .rightArrow {
            display: none;
          }
        }
      }

      .planSection {
        padding: 30px 24px 40px 24px;
        margin-bottom: 40px;
      }

      .leftContainer {
        padding-right: 0px;
      }

      .footerContainer {
        // position: absolute;
        margin-bottom: 0px;
        // bottom: 0;
        // left: 0;
        padding: 0px 24px 0px 24px;
        width: 100%;
        .footerLinks {
          margin: 15px 0px 15px 0px;
          display: flex;
          text-align: center;
          width: 100%;

          .privacy-text {
            color: #101828;
            margin-right: 30px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    .container{
      height: auto;
    }
  }
`;

// const referAndEarnSteps = [
//   {
//     icon: shareIcon,
//     title: 'Step 1',
//     text: 'Share your personal link with your friends and family',
//   },
//   {
//     icon: couponCodeIcon,
//     title: 'Step 2',
//     text: 'Your friend/family member signs up and uses their coupon code to buy a Yellow plan at 15% off',
//   },
//   {
//     icon: succesfulReferalIcon,
//     title: 'Step 3',
//     text: (
//       <p>
//         You earn <b>₹250</b> for each successful referral
//       </p>
//     ),
//   },
// ];

const Dashboard = () => {
  const navigate = useNavigate();
  const currentUserPlansResponse = useAppSelector(selectCurrentUserPlans);
  const yellowPlusConfig = useAppSelector(selectYellowPlusDefaultPlansConfig);
  const userProfile = useAppSelector(selectUserProfile);
  const [showModel, setShowModel] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const previousWillRecipients = useAppSelector(selectSendWillPreviousRecipients);
  const currentPlan = currentUserPlansResponse?.data?.fetchPlans?.[0];
  const planInfo =
    currentPlan && yellowPlusConfig?.plans?.find((plan) => plan?.id === currentPlan?.planId);
  console.log("Plan Info: ", planInfo);
  console.log("Current Plan: ", currentPlan);
  console.log("Yellow Plus Config: ", yellowPlusConfig);

  const bottomNavigation = useRef(null);
  const currentUserAddons = get(currentUserPlansResponse, 'data.fetchPlans.0.addons') || [];
  const currentUserAddonsPrice = yellowPlusConfig?.addOns ? yellowPlusConfig?.addOns[0] : undefined;

  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const neverRegistered = planInfo === undefined;
  const userStatus = useAppSelector(selectUserStatus);
  // const plans = useAppSelector(selectedPlans);
  //
  //

  const cartPlanDetails: any = {
    'starter': {
      'title': 'Starter Will',
      'subText': 'Create your Will with yellow'
    },
    'pro': {
      'title': 'Pro Will',
      'subText': 'Create your Will with yellow'
    },
    'yess': {
      'title': 'Yellow SMART Remote Support',
      'subText': 'Online guidance for the Executor of your Will'
    },
    'smart': {
      'title': 'Yellow Smart',
      'subText': 'Asset transfer support for your loved ones'
    }
  }

  const TalkToExpertDialogText = {
    Title: 'Talk to our experts',
    alignText: 'left',
    HeadingContainerPadding: '24px 30px 20px 30px',
    subTextContainerPadding: '0px 30px 20px 30px',
    confirmButton: 'Schedule Now',
    warningText:
      'Proceed to chat with our helpdesk team so they can find a convenient time for your call with a Yellow legal expert.',
  };

  const willNotCreated = (userStatus || []).includes(UserStatus.WILL_DOC_NOT_CREATED);

  const sheduleCallWithExpert = () => {
    console.log('Talk to expert schedule');
    Intercom.show();
    setShowModel(false);
  };

  //Initialise Referral Factory Hook -> Supports openReferralFactory() -> Refer & Earn
  useReferralFactoryScript();

  useEffect(() => {
    if ((userStatus || []).includes(UserStatus.LOGGED_OUT)) {
      setIsLoading(false);
    }
    if ((userStatus || []).includes(UserStatus.LOGGED_IN)) {
      if ((userStatus || []).includes(UserStatus.CONFIG_LOADED)) {
        if ((userStatus || []).includes(UserStatus.PROFILE_LOADED)) {
          setIsLoading(false);
        }
      }
    }
    // setIsLoading(false);
  }, [userStatus, planInfo]);

  if (isLoading) return <Loading />;

  console.log(
    'currentUserPlansResponse?.data?.fetchPlans?.[0]?.additionalAddOns is:\n' +
    JSON.stringify(currentUserPlansResponse?.data?.fetchPlans?.[0]?.additionalAddOns, null, 2),
  );
  console.log(
    'currentUserPlansResponse?.data?.fetchPlans?.[0]?.additionalAddOns?.length is:\n' +
    JSON.stringify(
      currentUserPlansResponse?.data?.fetchPlans?.[0]?.additionalAddOns?.length,
      null,
      2,
    ),
  );

  return (
    <>
      {/* // @ts-ignore */}
      <DashboardWrapper>
        {lessThanSmall && (
          <BottomNavigation
            ref={bottomNavigation}
            showLabels
            value={'home'}
            onChange={(event, newValue) => {
              switch (newValue) {
                case 'my-will':
                  return navigate(Pages.MY_WILL);
                case 'resources':
                  // @ts-ignore
                  return (window.location = YELLOW_RESOURCES);
              }
            }}
            style={{
              position: 'sticky',
              bottom: 0,
              zIndex: 9999,
              backgroundColor: '#1D2939',
              height: '70px',
            }}
          >
            <BottomNavigationAction
              value={'home'}
              label='Home'
              icon={
                <img className={'h24px w-24px object-fit-contain'} src={bottom_navigation_home} />
              }
              style={{ color: '#FFC632', borderTop: '5px solid #FFC632', gap: '3px' }}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: '14px',
                },
              }}
            />
            <BottomNavigationAction
              value={'resources'}
              label='Resources'
              icon={
                <img
                  className={'h24px w-24px object-fit-contain'}
                  src={bottom_navigation_resources}
                />
              }
              style={{ color: '#FCFCFC', borderTop: '5px solid transparent', gap: '3px' }}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: '14px',
                },
              }}
            />
            <BottomNavigationAction
              value={'my-will'}
              label='My Will'
              icon={
                <img
                  className={'h24px w-24px object-fit-contain'}
                  src={bottom_navigation_my_will}
                />
              }
              style={{
                color: '#FCFCFC',
                borderTop: '5px solid transparent',
                gap: '3px',
              }}
              sx={{
                '& .MuiBottomNavigationAction-label': {
                  fontSize: '14px',
                },
              }}
            />
          </BottomNavigation>
        )}
        <Grid container sm={12} className='container '>
          <Grid lg={12} sm={12} className='navbar'>
            <Header title='' backButton={false} />
          </Grid>

          <Grid lg={7.5} md={7.5} sm={12} className='content'>
            {neverRegistered ? (
              <DashboardStaticView 
              neverRegistered={neverRegistered}
              setShowModal={() => {}}
              yessPrice={''}
              />
            ) : (
              <>
                {lessThanSmall ? (
                  <Grid lg={4.5} md={4.5} sm={12} className='mobilePlanHeading'>
                    {/* <p>Yellow {planInfo?.title} Plan</p> */}
                    <p>{planInfo?.id !== undefined ? cartPlanDetails[planInfo?.id].title : '...'}</p>
                  </Grid>
                ) : null}
                <Grid lg={12} md={12} sm={12} className='congratsMessage'>
                  <p>Here’s what you need to do next</p>
                </Grid>

                {!previousWillRecipients ? (
                  get(currentPlan, 'modules.SMART.enabled') ? (
                    <CongratulationsFlowInformForm />
                  ) : null
                ) : null}
                <Grid container lg={12} md={12} sx={{ width: '100%' }}>
                  <Grid lg={12} md={12} sm={12} className='leftContainer' sx={{ width: '100%' }}>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      className='yellowWill'
                      onClick={() => {
                        navigate(Pages.WILL_EDITOR);
                      }}
                    >
                      <div className='smallCardText'>
                        <img src={willIcon} />

                        {/* change text to update your will when user is already having a will */}
                        {willNotCreated ? (
                          <h3>Create your Yellow Will</h3>
                        ) : (
                          <h3>Update your Yellow Will</h3>
                        )}
                      </div>
                      <div className='rightArrow'>
                        <img src={arrowIcon} />
                      </div>
                    </Grid>
                    {/* <Grid lg={12} md={12} sm={12} className='yellowPlusFamily'>
                        <div className='comingSoon'>
                          <p>Coming Soon</p>
                        </div>
                        <h3>Talk to a legal expert</h3>
                      </Grid> */}
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      className='yellowWill talkToExpert'
                      onClick={() => {
                        // Intercom.show();
                        setShowModel(true);
                      }}
                    >
                      <div className='smallCardText'>
                        <img src={talkToExpertIcon} />
                        <h3>Talk to a legal expert</h3>
                      </div>
                      <div className='rightArrow'>
                        <img src={arrowIcon} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                {previousWillRecipients ? (
                  get(currentPlan, 'modules.SMART.enabled') ? (
                    <CongratulationsFlowInformForm />
                  ) : null
                ) : null}

                <div
                  className="yessAddOn"
                >
                  <h2
                    className='addOnHeader'
                  >Purchase exclusive add-ons</h2>
                  <div className="width">
                    <YessSupport
                      isCart={false}
                    />
                  </div>
                </div>
                {false &&
                  <Grid className='addOnsContainer'>
                    <p>Access your add-ons</p>
                    <div
                      className='addOnsCardLink'
                    // href='https://3fvovtcfa0b.typeform.com/to/TCw5Ldrx'
                    >
                      <Grid className='addOnsCard'>
                        <Grid
                          className={`iconsAddon ${currentUserAddons?.length >= 1 || !userProfile?.isNRI ? '' : 'none'
                            }`}
                        >
                          <img src={evenIcon} />
                          {lessThanSmall ? <img src={arrowIcon} /> : null}
                        </Grid>
                        <Grid display={'flex'} className='subContainerCard'>
                          {currentUserAddons?.length >= 1 || !userProfile?.isNRI ? (
                            <Grid
                              className={`evenAddOnText evenAddOnTextLeft`}
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: '1',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>
                                <h3>Even Health Insurance</h3>
                                {currentUserAddons ? (
                                  currentUserAddons?.length >= 1 ? (
                                    <p>
                                      Fill in your information to begin your OPD heath coverage with
                                      our partner, Even.
                                    </p>
                                  ) : (
                                    <>
                                      <p>
                                        Purchase <b>Even Healthcare Insurance</b> for yourself for{' '}
                                        <b>
                                          {currentUserAddonsPrice?.price}
                                          /year*.
                                        </b>
                                      </p>
                                      <p>For Indian residents aged 1 to 70 years only</p>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <p>
                                      Purchase <b>Even Healthcare Insurance</b> for yourself for{' '}
                                      <b> {currentUserAddonsPrice?.price}/year*.</b>
                                    </p>
                                    <p>For Indian residents aged 1 to 70 years only</p>
                                  </>
                                )}
                              </div>
                              <div>
                                <CustomButton
                                  style={{
                                    width: '100%',
                                    borderRadius: '12px',
                                    marginTop: '16px',
                                    fontWeight: '500',
                                  }}
                                  onClick={() =>
                                    currentUserAddons
                                      ? currentUserAddons.length >= 1
                                        ? window.open(
                                          'https://3fvovtcfa0b.typeform.com/to/TCw5Ldrx',
                                        )
                                        : navigate(Pages.MY_CART, { state: { addingFor: 'self' } })
                                      : navigate(Pages.MY_CART, { state: { addingFor: 'self' } })
                                  }
                                  text={
                                    currentUserAddons
                                      ? currentUserAddons.length >= 1
                                        ? 'Activate now'
                                        : 'Purchase Even now'
                                      : 'Purchase Even now'
                                  }
                                />
                              </div>
                            </Grid>
                          ) : null}
                          <Grid
                            className='evenAddOnText'
                            style={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'column',
                              flex: '1',
                              justifyContent: 'space-between',
                            }}
                          >
                            {(
                              currentUserPlansResponse?.data?.fetchPlans?.[0]?.additionalAddOns ||
                              []
                            )?.length < 3 ? (
                              <>
                                <div>
                                  <h3>Add-on members</h3>
                                  <p>
                                    Purchase <b>Even Healthcare Insurance</b> for up to{' '}
                                    <b>3 add-on members</b> for{' '}
                                    <b>{currentUserAddonsPrice?.priceForOthers}/year*</b> each
                                    member.
                                  </p>
                                </div>
                                <div>
                                  <CustomButton
                                    style={{
                                      border: '2px solid black',
                                      width: '100%',
                                      borderRadius: '12px',
                                      marginTop: '16px',
                                      fontWeight: '700',
                                    }}
                                    hoverBackgroundColor='#DDEEFF'
                                    hoverColor='black'
                                    backgroundColor='#DDEEFF'
                                    fontColor='black'
                                    onClick={() =>
                                      navigate(Pages.MY_CART, { state: { addingFor: 'others' } })
                                    }
                                    text={'Purchase for add-on members'}
                                  />
                                </div>
                              </>
                            ) : (
                              <div>
                                <h3>Add-on members</h3>
                                <p>
                                  Inform the members to fill out the form sent to them on their
                                  registered e-mail id to begin their OPD heath coverage with our
                                  partner, Even.
                                </p>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                }
              </>
            )}
          </Grid>
          <Grid lg={4.5} md={4.5} sm={12} xs={12} className='planSection'>
            {!lessThanSmall && !neverRegistered ? (
              <h3 className='rightSectionHeadingPlan'>
                Your Active Plan: {planInfo?.id !== undefined ? cartPlanDetails[planInfo?.id].title : '...'}
              </h3>
            ) : null}
            {/* <Grid className='needHelpAndReferCard'>
              <Grid className='needHelpText'>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 className='font-roboto-bold'>Refer and earn</h3>
                  <img className='decoratorImg' src={referIcon} />
                </Grid>
                <Grid display={'flex'} style={{ padding: '0px 0px 0px 20px' }}>
                  <div className='borderDashed'></div>
                  <Grid item lg={12} md={12} sm={12} xs={12} className='pointHolder'>
                    {React.Children.toArray(
                      referAndEarnSteps.map((perk) => <ReferAndEarnSteps {...perk} />),
                    )}
                  </Grid>
                </Grid>
                <CustomButton
                  style={{ width: '100%' }}
                  text={'Refer now'}
                  className='referAndEarnButton'
                  onClick={() => {
                    openReferralFactory();
                  }}
                />
              </Grid>
            </Grid> */}

            <Grid className='needHelpAndReferCard needHelpCard'>
              <Grid className='needHelpText'>
                <h3 className='font-roboto-bold'>Refer your loved ones</h3>
                <p>Invite friends and family and they'll save 15% when they make their Will with Yellow.</p>
                <div
                  className='contactContainer'
                  onClick={() => {
                    // Intercom.show();
                    console.log('Get Code');
                  }}
                >
                  <p>Contact our support team now to get your exclusive code</p>
                </div>
              </Grid>
              <img src={referIcon} />
            </Grid>

            <Grid className='needHelpAndReferCard needHelpCard'>
              <Grid className='needHelpText'>
                <h3 className='font-roboto-bold'>Need help?</h3>
                <p>Get on-demand helpdesk support to clear any doubts you have.</p>
                <div
                  className='connectContainer'
                  onClick={() => {
                    Intercom.show();
                    console.log('connect now');
                  }}
                >
                  <p>Connect Now</p>
                  <img src={blueRightArrow} />
                </div>
              </Grid>
              <img src={needHelpIcon} />
            </Grid>
          </Grid>

          <Grid lg={7.5} md={7.5} sm={12} className='footerContainer'>
            <hr style={{ border: '1.4px solid #EDEBE7' }} />
            <div className='footerLinks'>
              {/* <h3 onClick={'/'}>Coverage</h3> */}
              {/* <Link className='privacy-text' to={'https://getyellow.in/privacy-policy'} rel='noopener noreferrer'> */}
              <a
                className='privacy-text'
                href='https://getyellow.in/privacy-policy'
                target={'_blank'}
                rel='noopener noreferrer'
              >
                <h3>Privacy Policy</h3>
              </a>
              <a
                className='privacy-text'
                href={YELLOW_PLUS_LEGAL}
                onClick={() =>
                  Mixpanel.track('Yellow+ Terms of Service click', { from: 'Congratulations page' })
                }
                target={'_blank'}
                rel='noopener noreferrer'
              >
                <h3>Terms of Service</h3>
              </a>
              {/* </Link> */}
            </div>
          </Grid>
          <Grid lg={4.5} md={4.5} sm={12} style={{ backgroundColor: '#f3f3f3' }}></Grid>
        </Grid>
        {showModel ? (
          <ConfirmationDialog
            setShowModel={setShowModel}
            confirmationText={TalkToExpertDialogText}
            showConfirmationData={false}
            confirmationTitle={TalkToExpertDialogText.Title}
            // For the mutation (and close the dialog as well)
            confirmDataButton={sheduleCallWithExpert}
          />
        ) : null}
      </DashboardWrapper>
    </>
  );
};

// const ReferAndEarnSteps = ({ icon, title, text }: { icon: string; title: string; text: any }) => (
//   <Grid item lg={12} sm={12} display='flex' className='points'>
//     <img src={icon} className='avatar' />
//     <span>
//       <h3>{title}</h3>
//       <p>{text}</p>
//     </span>
//   </Grid>
// );

export default Dashboard;
