import { Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import emailMyWill from 'assets/svg/email.svg';
import Navbar from 'Pages/Home/Navbar';

import talkToExpert from 'assets/svg/talkToExpert.svg';
import funeralArrangements from 'assets/svg/funeral.svg';
import printCourier from 'assets/svg/printCourier.svg';
import updateLocation from 'assets/svg/update_location.svg';
import willSigning from '../../assets/svg/will_signing_icon.svg';
import { scrollToTopInstantly } from '../../utils';

import { useAppSelector } from 'store/hooks';
import { selectActiveWill, selectUserProfile, selectUserStatus } from 'store/user/selectors';
import { useNavigate, useLocation } from 'react-router-dom';
import OthersAccordian from 'common/NotifyPeople/OthersAccordian';
import MuiDrawer from 'common/Reusables/Drawer';
import OverlayHead from './CreateWill/Steps/OverlayHead';
import ShowPeople from 'common/NotifyPeople/ShowPeople';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import versions from 'assets/svg/versions.svg';
import shareViaEmail from 'assets/svg/share_via_email.svg';
import BackToSectionSummary from './BackToSectionSummary';
import NotifyBlock from './NotifyBlock';
import { UserStatus } from 'store/user/slice';
import Loading from 'common/Loading';
import DetailsModal from './DetailsModal';
import EmailSendModal from './EmailSendModal';
import { Pages } from 'routes';
import { WillPreviewContainer } from './CreateWill/WillPreviewContainer';
import { getWillPDF } from './CreateWill/PreviewWill';
import UpdateLocation from './UpdateLocation';
import PrintAndCourierFlow from './PostWillGeneration/PrintAndCourier';
import WillSigningVideoFlow from './PostWillGeneration/WillSigningVideoFlow';
import { FuneralArrangements } from './FuneralArrangements';
import KYCShowModal from './KYCShowModal';
import { Intercom } from '../../utils/intercom';
import Header from '../../common/Header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { breakpoints } from '../../styles/Breakpoints';
import magnifyingGlass from '../../assets/images/magnifying.png';
import dateformat from 'dateformat';
import { WillModal } from '../../common/Reusables/WillModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useEventPublisher } from 'use-event-emitter-hook';
// import loadingGIFYellow from 'assets/images/loadingGIF.gif';
import loadingGIFYellow from 'assets/Yellow_Loader_YELLOW.gif';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const SectionSummaryWrapper = styled.div`
  padding: 32px 40px 0;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0px 24px;
    box-sizing: border-box;
  }
  .skipActionArea {
    width: 180px;
    height: 45px;
    border: 2px solid ${(props) => props.theme.primaryColor};
    border-radius: 5px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action_button_holder {
    display: flex;
    margin-bottom: 30px;
    .continueWillBtn {
      width: 180px;
      margin-left: 20px;
    }
  }

  .sectionSummary_container {
    padding: 40px 0px;

    .summary_cards_holder {
      margin-top: 40px;
    }
  }
  .summary {
    min-height: 500px;
  }
  .illustrationImage {
    width: 100%;
  }
  .myWill-subtext-font {
    font-size: 24px;
  }
`;

const ActionPageWrapper = styled.div`

  padding: 0px 40px 0px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding: 0px 20px;
    box-sizing: border-box;
  }
`;

export const TimerWrapper = styled(Grid)`
  border: 1px solid rgba(29, 41, 57, 0.1);
  background: #f3f4f5;
  padding: 16px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 120px;
  // margin: 24px 0px 16px 0px;
  .timer-text {
    margin-left: 20px;
  }
  .line-height {
    line-height: 1.8;
  }
`;

export const NotifyWrapper = styled(Grid)`
  border: 1px solid rgba(29, 41, 57, 0.1);
  height: 120px;
  @media screen and (max-width: ${breakpoints.md}) {
    height: min-content;
  }
  background: #ece9df;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  align-items: center;
  // margin: 16px 0px;
  .line-height {
    line-height: 1.3;
  }
  .go-to {
    display: flex;
    align-items: center;
    color: #1270e8;
    cursor: pointer;
  }
`;
const ComingSoonWrapper = styled.fieldset`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  padding: 30px 10px;
  background-color: #f8f8f8;
  border: 1px solid rgba(29, 41, 57, 0.1);
  border-radius: 12px;
  margin-bottom: 48px;
  .legend-styles {
    background-color: #ffc632;
    padding: 8px 24px;
    width: auto;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AssetSubTypesWrapper = styled(Grid)`
  padding: 30px;
  height: 100%;
  width: min(100%, 500px);
  max-width: min(100%, 500px);
  box-sizing: border-box;
  overflow: auto;

  .intro {
    margin: 30px 0px 20px;
  }

  .close_icon {
    height: 18px;
    object-fit: contain;
  }
  .notify-help-text {
    margin: 18px 0px;
  }
  .help_icon {
    cursor: pointer;
    height: 35px;
    width: 35px;
    margin-right: 18px;
  }
`;

export const ActionsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  // align-items:center;
  align-items:start;
`;

const ActionsWrapperWidthContainer = styled.div`
  width:100%;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 0px;
  border-top: 1px solid rgb(239 239 239);
`;

export const ActionsWrapperElementsRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
`;

const AccessLinksWrapper = styled(Grid)`
  // display: flex;
  // margin-top: 20px;
  // align-items: flex-start;
  // justify-content: space-between;
  // flex-wrap: wrap;
`;
export const EachAccessLinkWrapper = styled(Grid)`
  width: 100px;
  margin: 12px 10px;
  cursor: pointer;
  flex: 1;

  p{
    font-size: 13px;
    text-align: center;
    margin-top: 8px;
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin:12px 0px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    width: 85px;
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
  width: 42px;
  height: 42px;

  background: #f3f4f5;
  border-radius: 50%;
`;

export const HelpWrapper = styled(Grid)`
  padding: 30px 30px 20px;
  height: 100vh;
  position: relative;

  .content {
    height: 80%;

    p {
      margin-top: 20px;
    }
  }
  .bold-text {
    font-weight: 800;
  }
  .queries_section {
    height: min-content;
    width: 100%;
    padding: 20px;

    background: #f3f4f5;
    border-radius: 10px;

    .queryHelpCard {
      cursor: pointer;
      height: 50px;
      max-height: 50px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 10px;

      img {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
`;

const UnOrderedListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  margin-left: 16px;
  margin-top: 42px;
  li {
    margin: 24px 0;
  }
`;

export const NotifyPointsWrapper = styled(Grid)`
  display: flex;
  margin: 24px 0px;
`;

const MyWill = ({ }: {}) => {
  //   const gaEventTracker = useAnalyticsEventTracker({ category: EVENT_CATEGORIES.MY_WILL });
  const navigate = useNavigate();
  // const userActiveWill = useAppSelector(selectActiveWill);
  const [isNotify, setIsNotify] = useState(false);
  const [isNotifyHelp, setIsNotifyHelp] = useState(false);
  const userStatus = useAppSelector(selectUserStatus);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isShowWhatNext, setIsShowWhatNext] = useState<boolean>(false);
  const [isShowDetailsModal, setIsShowDetailsModal] = useState<boolean>(false);
  const [isShowEmailSendModal, setIsShowEmailSendModal] = useState<boolean>(false);
  const [isWillModalOpen, setIsWillModalOpen] = useState<boolean>(false);
  const [url, setUrl] = useState('');
  const [, setLoading] = useState<boolean>(false);
  const [isUpdateLocation, setIsUpdateLocation] = useState(location.pathname === Pages.MY_WILL_UPDATE_LOCATION || false);
  const [showWillSigningPage, setShowWillSigningPage] = useState<boolean>(location.pathname === Pages.MY_WILL_SIGNING_VIDEO || false);
  const [showPrintPage, setShowPrintPage] = useState<boolean>(location.pathname === Pages.MY_WILL_SHIPPING_AND_COURIER || false);
  const [showFuneralArrangements, setShowFuneralArrangements] = useState(false);
  const [isPendingKYC, setIsPendingKYC] = useState(true);
  const [showKYCModal, setShowKYCModal] = useState(false);
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const rightPanel = useRef(null);
  const userActiveWill = useAppSelector(selectActiveWill);
  const userProfile = useAppSelector(selectUserProfile);

  const search = new URLSearchParams(useLocation().search);

  const setKycHeroScreen = (show: boolean) => {
    if (show) {
    }
  };

  useEffect(() => {
    if (userProfile?.kycDetails) {
      if (userProfile.kycDetails.kycStatus === 'DONE') {
        setIsPendingKYC(false);
      }
    }
  }, [userProfile]);

  const generateWillPreview = async () => {
    setLoading(true);
    try {
      if (!userActiveWill?.documentId) throw new Error();
      const pdfUrl = await getWillPDF({
        willId: userActiveWill?.documentId,
        forPreview: true,
        webPreview: true,
      });
      if (!pdfUrl) throw new Error();
      console.log({ pdfUrl });
      setUrl(pdfUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // const toast: ToastMessage = {
      //   type: ToastType.ERROR,
      //   message: 'Error getting preview of your will. Please try again.',
      // };
      // dispatch(showToast(toast));
    }
  };

  const generateWill = () => {
    if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) {
      setIsShowDetailsModal(true);
    } else {
      setIsShowEmailSendModal(true);
    }
  };

  // const getWillStatus = () => {
  //   let status = true;
  //   if (userActiveWill?.will_progress) {
  //     if (!userActiveWill.will_progress.includes('BASIC_DETAILS_FILLED' as WILL_CREATION_STATES)) {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  //   console.log('Will progress', userActiveWill, status);
  //   return status;
  // };

  useEffect(() => {
    if (userStatus.includes(UserStatus.LOGGED_OUT)) {
      setIsLoading(false);
      navigate(Pages.LOGIN, { replace: true });
    }

    if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
      if (userStatus.includes(UserStatus.PROFILE_INCOMPLETE))
        navigate(Pages.ONBOARDING, { replace: true });
      else if (
        userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE) ||
        userActiveWill === null ||
        userActiveWill?.paymentInfo === null
      )
        navigate(Pages.WILL_EDITOR, { replace: true });
      else if (userStatus.includes(UserStatus.PAYMENT_INCOMPLETE))
        navigate(Pages.PAYMENT, { replace: true });

      setIsLoading(false);
    }
  }, [userStatus, userActiveWill]);

  useEffect(() => {
    generateWillPreview();
  }, [userActiveWill]);

  const resetToMyWill = () => {
    navigate(Pages.MY_WILL, { replace: true });
  };

  const publish = useEventPublisher();

  if (isLoading) return <Loading />;
  if (isUpdateLocation)
    return (
      <div>
        <div className={'hide_in_mobile'}>
          <Navbar backgroundColorClass='background_white' />
        </div>
        <div className={'show_in_mobile p-16px'}>
          <Header
            title={'Will Location'}
            backButton={true}
            backButtonOnClick={resetToMyWill}
            // @ts-ignore
            centerItems={true}
            renderRightHandSide={
              <HelpOutlineIcon onClick={() => publish('will_location_help_on_click')} />
            }
            hideBackNavigation={search.get("hideBackNavigation") === 'true'}
          />
        </div>
        <ActionPageWrapper>
          <UpdateLocation setIsUpdateLocation={setIsUpdateLocation} />
        </ActionPageWrapper>
      </div>
    );

  return (
    <>
      {showPrintPage ? (
        <PrintAndCourierFlow resetToMyWill={resetToMyWill} />
      ) : showWillSigningPage ? (
        <WillSigningVideoFlow
          showNotifyOthersOverlay={setIsNotify}
          showFuneralArrangements={setShowFuneralArrangements}
        />
      ) : (
        <div className={lessThanSmall ? 'flex flex-col' : ''}>
          {!lessThanSmall ? (
            <div>
              <Navbar backgroundColorClass='background_white' />
            </div>
          ) : (
            <div className={'w-100 border-box flex flex-col items-center px-20px py-20px'}>
              <Header
                // backgroundColorClass={userActiveWill ? 'background_white' : 'background_lightBeige'}
                backgroundColorClass={'background_white'}
                // @ts-ignore
                centerItems={true}
                backButton={true}
                title={'My Will'}
                backButtonOnClick={() => {
                  const isVendor = !!userProfile?.externalVendor;
                  if (isVendor)
                    navigate(Pages.WILL_EDITOR)
                  else
                    navigate(Pages.DASHBOARD)
                }}
              />
            </div>
          )}
          <SectionSummaryWrapper>
            {!lessThanSmall && (
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                justifyContent={'space-between'}
                className={'actions'}
              >
                <h3 className='heading2'>My Will</h3>
                <div className='action_button_holder'></div>
              </Grid>
            )}
            <Grid
              lg={12}
              md={12}
              sm={12}
              display={'flex'}
              direction={lessThanSmall ? 'column' : 'row'}
              style={{ marginTop: lessThanSmall ? '0px' : '24px' }}
            >
              <Grid
                lg={6}
                md={6}
                sm={12}
                style={!lessThanSmall ? { width: '80%' } : {}}
                {...(lessThanSmall ? { onClick: () => setIsWillModalOpen(true) } : {})}
              >
                <Grid
                  style={{
                    borderRadius: '12px',
                    height: lessThanSmall
                      ? 'auto'
                      : // @ts-ignore
                      rightPanel?.current?.clientHeight
                        ? // @ts-ignore

                        rightPanel?.current?.clientHeight - 50
                        : '100vh',
                    backgroundColor: '#FFE171',
                    marginRight: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: lessThanSmall ? 'column-reverse' : 'column',
                    padding: lessThanSmall ? '0px' : '24px 0px',
                    overflowY: lessThanSmall ? 'hidden' : 'scroll',
                    overflowX: 'hidden',
                    ...(lessThanSmall ? { justifyContent: 'flex-start', width: '100%' } : {}),
                  }}
                  sm={12}
                >
                  <h3
                    className='heading6'
                    style={{
                      ...(lessThanSmall
                        ? {
                          textAlign: 'left',
                          fontSize: '20px',
                          padding: '16px',
                        }
                        : { fontSize: '20px', padding: '24px' }),
                    }}
                  >
                    {userActiveWill?.testatorDetails?.fullName!!}'s Latest Will and Testament
                    <br />
                    <h3
                      className='font-roboto'
                      style={{
                        ...(lessThanSmall
                          ? {
                            textAlign: 'left',
                            fontSize: '15px',
                            marginTop: '10px',
                          }
                          : { display: 'none' }),
                      }}
                    >{`Updated on ${dateformat(
                      new Date(userActiveWill?.updatedOn || 0),
                      'dS mmmm, yyyy',
                    )}`}</h3>
                  </h3>

                  {
                    url ? (
                      <div
                        style={{
                          width: '100%',
                          justifyContent: 'center',
                          display: 'flex',
                          ...(lessThanSmall
                            ? {
                              position: 'relative',
                              height: '223px',
                              overflow: 'hidden',
                              padding: '10px',
                            }
                            : {}),
                        }}
                      >
                        <div
                          style={{
                            width: lessThanSmall ? '65%' : '85%',
                          }}
                        >
                          <WillPreviewContainer
                            url={url}
                            {...(lessThanSmall ? { pageToRender: 1 } : {})}
                          />
                        </div>
                        {lessThanSmall && (
                          <div
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 0,
                              backgroundColor: 'rgba(0,0,0,0.5)',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={magnifyingGlass}
                              alt='open-will-preview'
                              style={{
                                position: 'absolute',
                                top: '50%',
                                zIndex: '20',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '90%',
                          width: '100%',
                        }}
                      >
                        <img 
                        style={{
                          width: '7.5rem',
                        }}
                        src={loadingGIFYellow} alt="loading..." />
                      </div>
                    )
                  }
                </Grid>
              </Grid>

              <Grid lg={6} md={6} sm={12} ref={rightPanel}>
                {!lessThanSmall && (
                  <BackToSectionSummary
                    text='Back to Will Editor'
                    action={() => navigate(Pages.WILL_EDITOR)}
                    noMargin={true}
                  />
                )}
                <div className={'hide_in_mobile'}>
                  <Grid
                    display={'flex'}
                    justifyContent='space-between'
                    style={{ marginTop: '32px' }}
                  >
                    <p className='font-semi-bold'>Your Will draft has been emailed to you.</p>
                    {/* <p style={{ cursor: 'pointer' }} onClick={() => setIsShowWhatNext(true)}>
                <u>What next?</u>
              </p> */}
                  </Grid>
                </div>
                <div style={{ margin: '24px 0px' }}></div>
                {/* {!(
              userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) && !userActiveWill?.isWillUpdated
            ) ||
              (
                userStatus.includes(UserStatus.FIRST_WILL_COMPLETED) &&
                userActiveWill?.isWillUpdated) ? (
              <TimerBlock />
            ) : null} */}
                <div style={{ margin: '24px 0px' }}></div>
                <NotifyBlock />
                <div style={{ margin: '24px 0px' }}></div>

                <ActionsWrapper>
                  <ActionsWrapperWidthContainer>
                    <p className='font-semi-bold'>Actions</p>
                    <ActionsWrapperElementsRow
                      alignItems={'center'}
                      justifyContent={'start'}
                    >
                      <p className="font-semi-bold"
                        style={{ color: '#646464', fontSize: '13px', margin: '8px 0px', whiteSpace: 'nowrap' }}>
                        Quick access links
                      </p>
                      <HorizontalLine />
                    </ActionsWrapperElementsRow>

                    <AccessLinksWrapper>
                      <ActionsWrapper>
                        <ActionsWrapperElementsRow>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                          >
                            <img src={talkToExpert} alt='Talk to Expert' />
                            <p
                            // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                            >
                              Talk to our experts
                            </p>
                          </EachAccessLinkWrapper>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                            onClick={generateWill}
                            className='cursor-pointer'
                          >
                            <img src={emailMyWill} alt='email' />
                            <p
                            // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                            >
                              E-mail My Will
                            </p>
                          </EachAccessLinkWrapper>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                            onClick={() => { setShowFuneralArrangements(true); }}
                          >
                            <img src={funeralArrangements} alt='Funeral Arrangements' />
                            <p
                            // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                            >
                              Funeral arrangements
                            </p>
                          </EachAccessLinkWrapper>
                        </ActionsWrapperElementsRow>
                        <ActionsWrapperElementsRow>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                            onClick={() => {
                              scrollToTopInstantly();
                              setShowPrintPage(true);
                            }}
                          >
                            <img src={printCourier} alt='Print & Courier' />
                            <p
                            // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                            >
                              Print & courier
                            </p>
                          </EachAccessLinkWrapper>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                            onClick={() => {
                              scrollToTopInstantly();
                              setShowWillSigningPage(true);
                            }}
                          >
                            <img src={willSigning} alt='Will Signing' />
                            <p
                            // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                            >
                              Will signing
                            </p>
                          </EachAccessLinkWrapper>
                          <EachAccessLinkWrapper
                            lg={2}
                            display='flex'
                            flexDirection={'column'}
                            alignItems='center'
                            justifyContent={'center'}
                            onClick={() => {
                              scrollToTopInstantly();
                              setIsUpdateLocation(true);
                            }}
                          >
                            <img src={updateLocation} alt='Uplaod Location' />
                            <p
                              // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                              onClick={() => setIsUpdateLocation(true)}
                            >
                              Update location
                            </p>
                          </EachAccessLinkWrapper>
                        </ActionsWrapperElementsRow>
                      </ActionsWrapper>
                    </AccessLinksWrapper>
                    <OthersAccordian
                      setShowWillSigningPage={setShowWillSigningPage}
                      setShowPrintPage={setShowPrintPage}
                      setIsUpdateLocation={setIsUpdateLocation}
                    />
                  </ActionsWrapperWidthContainer>
                </ActionsWrapper>
                {/* ****************** Coming Soom Block ******************** */}
                <div>
                  {/* <fieldset style={{border: "none"}}> */}

                  <ComingSoonWrapper>
                    <legend className='font-semi-bold legend-styles'>Coming Soon</legend>

                    <EachAccessLinkWrapper
                      lg={2}
                      display='flex'
                      flexDirection={'column'}
                      alignItems='center'
                      justifyContent={'center'}
                    >
                      <img src={shareViaEmail} alt='Share via Email' />

                      <p style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}>
                        Share via Email
                      </p>
                    </EachAccessLinkWrapper>

                    <EachAccessLinkWrapper
                      lg={2}
                      display='flex'
                      flexDirection={'column'}
                      alignItems='center'
                      justifyContent={'center'}
                    >
                      <img src={versions} alt='Versions' />
                      <p style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}>
                        Versions
                      </p>
                    </EachAccessLinkWrapper>
                  </ComingSoonWrapper>
                  {/* </fieldset> */}
                </div>
              </Grid>
            </Grid>
          </SectionSummaryWrapper>
        </div>
      )}
      {/* Show People Overlay */}
      <MuiDrawer open={isNotify} hideDrawer={() => setIsNotify(false)} width={'min(100%, 500px)'}>
        <AssetSubTypesWrapper>
          <div className={'w-100 flex flex-col'}>
            <OverlayHead
              heading='Notify My People'
              showHelp={true}
              onClick={() => setIsNotify(false)}
              setShowHelp={() => setIsNotifyHelp(true)}
            />
            <p className='notify-help-text'>
              Notifications will only alert the user about their role in your Will. The details of
              your Will will not be shared.
            </p>
            <ShowPeople />
          </div>
        </AssetSubTypesWrapper>
      </MuiDrawer>
      <MuiDrawer
        open={isNotifyHelp}
        hideDrawer={() => setIsNotifyHelp(false)}
        width={'min(100%, 500px)'}
      >
        <AssetSubTypesWrapper>
          <OverlayHead heading='Notify My People' onClick={() => setIsNotifyHelp(false)} />
          <p className='notify-help-text'>
            You can choose to notify important people in your Will here.
          </p>
          <p style={{ color: 'rgba(16, 24, 40, 0.64)' }}>Please note:</p>
          {/* Nofity People Points */}
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>
              An alert will be sent via Whatsapp that will notify the person only of their role in
              your Will.
            </p>
          </NotifyPointsWrapper>
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>The details of your Will will not be shared.</p>
          </NotifyPointsWrapper>
          <NotifyPointsWrapper>
            {lessThanSmall ? (
              <HelpOutlineIcon />
            ) : (
              <img src={helpIcon} alt='Help Icon' className='help_icon' />
            )}
            <p className='help-text'>
              Notifications will be sent to your Primary Beneficiaries only. Secondary
              Beneficiaries, Backups, Monetary Bequests and people below 18 years of age will not be
              notified.
            </p>
          </NotifyPointsWrapper>
        </AssetSubTypesWrapper>
        {userProfile?.externalVendor !== 'ACKO' && <HelpWrapper container>
          <Grid
            item
            lg={12}
            className='queries_section'
            display={'flex'}
            flexDirection='column'
            justifyContent='space-around'
            alignItems={'center'}
          >
            <p className='subText font-semi-bold'>Have a specific query in mind?</p>
            <QueryHelpCard
              icon={book}
              text='Explore FAQs'
              onClick={() => window.open('https://getyellow.in/faq', '_blank')}
            />
            <QueryHelpCard
              icon={contact}
              text='Contact Us'
              onClick={Intercom.hideOverLayAndShowIntercom({ setShowOverlay: setIsNotifyHelp })}
            />
          </Grid>
        </HelpWrapper>}
      </MuiDrawer>
      <MuiDrawer
        open={isShowWhatNext}
        width={'min(100%, 500px)'}
        hideDrawer={() => setIsShowWhatNext(false)}
      >
        <HelpWrapper container>
          <Grid className='content' flexGrow={8}>
            <OverlayHead
              heading={'What Next?'}
              onClick={() => setIsShowWhatNext(false)}
              isShowImage={true}
            />
            <UnOrderedListWrapper>
              <li>
                For your convenience, you can continue to make changes to your Will for 48 hours
                within the app.
              </li>
              <li>
                When you are done, make sure you hit the "Generate" button for your changes to be
                reflected in your final Will.
              </li>
              <li>
                Once the 48-hour grace period is over, your final Will will be emailed to you.
              </li>
              <li>
                If you have chosen to have it shipped, a hard copy will be couriered to you as well.
              </li>
            </UnOrderedListWrapper>
          </Grid>
        </HelpWrapper>
      </MuiDrawer>
      <DetailsModal
        isShowModal={isShowDetailsModal}
        handleOpenCloseModal={() => setIsShowDetailsModal(false)}
        goToAssets={() => navigate(Pages.WILL_EDITOR, { state: 'assets' })}
        goToDistribution={() => navigate(Pages.WILL_EDITOR, { state: 'distribution' })}
        goToExecutor={() => navigate(Pages.WILL_EDITOR, { state: 'executor' })}
      />
      <EmailSendModal
        handleOpenCloseModal={() => setIsShowEmailSendModal(false)}
        isShowModal={isShowEmailSendModal}
      />
      <FuneralArrangements
        open={showFuneralArrangements}
        hideDrawer={() => {
          setShowFuneralArrangements(false);
        }}
        setShowKYCModal={setShowKYCModal}
        isPendingKYC={isPendingKYC}
      />
      <KYCShowModal
        isShowModal={showKYCModal}
        handleOpenCloseModal={() => setShowKYCModal(false)}
        handleKYC={() => {
          setKycHeroScreen(true);
          setShowKYCModal(false);
        }}
      />
      <WillModal
        isModalOpen={isWillModalOpen}
        setIsModalOpen={setIsWillModalOpen}
        url={url}
        header={`${userActiveWill?.testatorDetails?.fullName!!}'s Latest Will and Testament`}
      />
    </>
  );
};

export const QueryHelpCard = ({
  icon,
  text,
  onClick,
}: {
  icon: string;
  text: string;
  onClick: Function;
}) => (
  <Grid
    item
    display='flex'
    justifyContent={'center'}
    alignItems='center'
    className='queryHelpCard'
    onClick={() => onClick()}
    style={{ width: '100%', padding: '10px 0px', margin: '6px 0px' }}
  >
    <img src={icon} alt='' />
    <span className='font-semi-bold'>{text}</span>
  </Grid>
);

export default MyWill;
