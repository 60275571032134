import { Grid } from '@mui/material';
import styled from 'styled-components';
import messageIcon from 'assets/svg/messagev2.svg';
import { useEffect, useState } from 'react';

import emailBanner from 'assets/email-banner.gif';

import { breakpoints } from 'styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputField from 'common/Reusables/Inputs';

import { SubmitHandler, useForm } from 'react-hook-form';

import {
  // AssetType,
  // Contact,
  HTMLInputTypes,
  // Field,
  //  LiabilityDetails
} from 'utils/types';
import { regex } from 'utils';
import CustomButton from 'common/Reusables/Button';
import { useAppSelector, useTypedDispatch } from '../../store/hooks';
import { getWillMailRecipients, sendWillTo, startSendWillRequest } from '../../store/user/slice';
import { useSelector } from 'react-redux';
import {
  selectSendWillPreviousRecipients,
  selectSendWillToResponse,
} from '../../store/user/selectors';
import get from 'lodash.get';
import { WillMailRecipient, WillMailRecipientInput } from '../../utils/willmailrecipienttypes';

import ConfirmationDialog from 'common/ConfirmationDialog/ConfirmationDialog';

const CongratulationsFormWrapper = styled.div`
  margin-top: 24px;
  border: 2px solid #e3e4e7;
  border-radius: 12px;
  padding: 32px 30px 32px 20px;

  .emailPolicyButtonContainer {
    margin-top: 20px;
    direction: rtl;
    .emailPolicyButton {
      padding: 0px 55px 0px 55px;
      border-radius: 8px;
    }
  }

  .errorMessage {
    font-size: 14px !important;
  }

  .formContainer {
    display: flex;
    width: 100%;
    align-items: flex-start;

    form {
      section {
        margin-bottom: 10px;
      }
    }

    img {
      width: 50px;
      height: 50px;
    }

    .formText {
      padding-left: 20px;
      h3 {
        padding: 0px 0px 10px 0px;
        font-size: 20px;
        width: 100% !important;
      }

      .subFormText {
        font-size: 16px !important;
        color: rgba(16, 24, 40, 0.64);
        padding-bottom: 22px;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-top: 12px;
    padding: 32px 20px 22px 20px;

    .emailPolicyButtonContainer {
      margin-bottom: 0px !important;
      .emailPolicyButton {
        width: 100%;
      }
    }

    .formContainer {
      flex-direction: column;
      img {
        width: 40px;
        height: 40px;
      }

      .formText {
        padding-left: 0px;

        h3 {
          padding: 16px 0px 10px 0px;
        }
      }
    }
  }
`;

const CongratulationsFlowInformForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ emailId: string; fullName: string }>({});
  const [showModel, setShowModel] = useState(false);
  const dispatch = useTypedDispatch();
  const sendWillResponse = useSelector(selectSendWillToResponse);
  const previousWillRecipients = useAppSelector(selectSendWillPreviousRecipients);

  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const sendWillToRecipient = (recipient: WillMailRecipientInput) => {
    dispatch(sendWillTo(recipient));
    dispatch(startSendWillRequest());
  };

  const ConfirmationDialogText = {
    confirmButton: 'Got it, thanks!',
    warningText:
      'We have received your request. Your Yellow Smart membership will be emailed shortly.',
    subText:
      'In the meantime, check out the other actions you can take to help secure your family.',
    imageMarginObject: {
      desktop: '0px 0px 10px 0px',
      mobile: '0px 0px 10px 0px',
    },
  };

  const onSubmit: SubmitHandler<{
    emailId: string;

    fullName: string;
  }> = (data) => {
    console.log(data);
    sendWillToRecipient(data as unknown as WillMailRecipientInput);
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setShowModel(true);
  };

  const handleClickClose = () => {
    setShowModel(false);
  };

  useEffect(() => {
    dispatch(getWillMailRecipients());
  }, [sendWillResponse]);

  return (
    <CongratulationsFormWrapper>
      <Grid className='formContainer'>
        <img src={messageIcon} />
        <Grid className='formText'>
          <h3>Inform your loved ones about Yellow Smart</h3>
          <p className='subFormText'>
            To help your loved ones avail of Yellow Smart asset transfer services when needed, email
            your Yellow Smart Policy document to your Will Executor or a family member.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <section>
              <InputField
                type={HTMLInputTypes.TEXT}
                register={register as any}
                fieldToRegister='fullName'
                placeHolder='FullName'
                errorMessage='Invalid Full Name'
                pattern={regex.fullName}
                errors={errors}
                required={true}
              />
            </section>
            <section>
              <InputField
                type={HTMLInputTypes.TEXT}
                register={register as any}
                fieldToRegister='emailId'
                errorMessage='Invalid mail'
                placeHolder='E-mail ID'
                pattern={regex.email}
                errors={errors}
                required={true}
              />
            </section>
            <section className='emailPolicyButtonContainer'>
              <CustomButton className='emailPolicyButton' text={'Email Policy'} />
            </section>
            {(get(previousWillRecipients, 'data.getWillMailRecipients') || []).length > 0 ? (
              <div className={'flex flex-col w-100 h-auto gap-8px border-box'}>
                <hr style={{ width: '100%', border: '1px solid #ddd' }} />
                <h3 style={{ fontSize: '16px', marginTop: '25px' }}>
                  Yellow Smart Policy has been sent to
                </h3>
                {(get(previousWillRecipients, 'data.getWillMailRecipients') || []).map(
                  (recipient: WillMailRecipient) => (
                    <div
                      className={
                        lessThanSmall
                          ? 'flex flex-col w-100 h-auto'
                          : 'flex flex-row w-100 h-auto items-center justify-between'
                      }
                    >
                      <div className={'flex flex-col w-auto h-auto text-alphagray'}>
                        <span>{recipient.fullName}</span>
                        <span>{recipient.emailId}</span>
                      </div>
                      <div
                        style={{
                          paddingTop: lessThanSmall ? '10px' : '0px',
                          marginBottom: lessThanSmall ? '10px' : '0px',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          console.log('recipient is:\n' + JSON.stringify(recipient, null, 2));
                          sendWillToRecipient({
                            fullName: recipient.fullName,
                            emailId: recipient.emailId,
                          });
                        }}
                      >
                        <span className={'unselectable underline cursor-pointer text-blue_700'}>
                          Resend
                        </span>
                      </div>
                    </div>
                  ),
                )}
              </div>
            ) : null}
          </form>
        </Grid>
      </Grid>

      {showModel ? (
        <ConfirmationDialog
          imageWidth={'100%'}
          imageMargin={ConfirmationDialogText.imageMarginObject}
          imageBorderRadius='12px'
          dialogImage={emailBanner}
          setShowModel={setShowModel}
          confirmationText={ConfirmationDialogText}
          showConfirmationData={false}
          // For the mutation (and close the dialog as well)
          confirmDataButton={handleClickClose}
        />
      ) : null}
    </CongratulationsFormWrapper>
  );
};

export default CongratulationsFlowInformForm;
