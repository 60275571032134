import { Grid } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import styled from 'styled-components';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomButton from 'common/Reusables/Button';

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },

    background: {
      paper: '#ffffff',
    },
  },
  typography: {
    fontSize: 16,
  },
  shape: {
    borderRadius: 12,
  },
});

const PopUpWrapper = styled.div`
  .popUpContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;

    .popUpCard {
      width: 600px;
      height: 400px;
    }
    p {
      div {
        .popUpBackground {
          padding: 20px;
          background: rgba(209, 227, 250, 0.54);
        }
      }
    }
  }
`;

const ConfirmationDialog = ({
  dialogImage,
  imageWidth,
  imageHeight,
  imageMargin,
  imageBorderRadius,
  confirmationText,
  confirmationTitle,
  confirmDataButton,
  profileData,
  setShowModel,
  showConfirmationData,
}: {
  dialogImage?: any;
  imageWidth?: any;
  imageHeight?: any;
  imageMargin?: any;
  imageBorderRadius?: string;
  confirmationText?: any;
  confirmationTitle?: string;
  confirmDataButton?: any;
  profileData?: any;
  showConfirmationData: boolean;
  setShowModel: Function;
}) => {
  //   const [setShowDialog, setShowDialog] = useState(false);
  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const handleClose = () => {
    setShowModel(false);
  };

  return (
    <PopUpWrapper>
      <ThemeProvider theme={outerTheme}>
        <Dialog
          open={true}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          {confirmationTitle ? (
            <DialogTitle
              id='alert-dialog-title'
              sx={{
                padding: confirmationText.HeadingContainerPadding,
                fontWeight: '700',
                fontSize: '20px',
              }}
            >
              {confirmationTitle}
            </DialogTitle>
          ) : null}
          <DialogContent
            className='popUpContainer'
            style={{
              paddingTop: dialogImage ? '25px' : '0px',
              padding: confirmationText.subTextContainerPadding,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {dialogImage ? (
              <img
                style={{
                  borderRadius: imageBorderRadius ? imageBorderRadius : '0px',
                  margin: imageMargin
                    ? lessThanSmall
                      ? imageMargin.mobile
                      : imageMargin.desktop
                    : lessThanSmall
                    ? '0px 0px 10px 10px'
                    : '10px 10px 20px 10px',
                  ...(imageWidth ? { width: imageWidth } : {}),
                  ...(imageHeight ? { height: imageHeight } : {}),
                }}
                src={dialogImage}
                {...{ ...(!imageWidth ? { width: '20px' } : {}) }}
              />
            ) : null}
            <DialogContentText
              id='alert-dialog-description'
              sx={{
                [outerTheme.breakpoints.down('sm')]: {
                  fontSize: '16px',
                },
              }}
            >
              <p
                className={'font-roboto'}
                style={{
                  paddingBottom: confirmationText.subText ? '10px' : '5px',

                  color: 'black',
                  textAlign: confirmationText.alignText ? confirmationText.alignText : 'center',
                }}
              >
                {confirmationText.warningText}
              </p>
              <p
                className={'font-roboto'}
                style={{
                  paddingBottom: '0px',
                  color: 'black',
                  textAlign: confirmationText.alignText ? confirmationText.alignText : 'center',
                }}
              >
                {confirmationText.subText}
              </p>
              {showConfirmationData ? (
                <Grid
                  className='popUpBackground'
                  style={{ padding: '15px', background: 'rgba(209, 227, 250, 0.54)' }}
                >
                  <p style={{ paddingBottom: '5px' }}>
                    Name: <span style={{ color: 'black' }}>{profileData.fullName}</span>
                  </p>
                  <p style={{ paddingBottom: '5px' }}>
                    DOB: <span style={{ color: 'black' }}>{profileData.dateOfBirthForDialog}</span>
                  </p>
                  <p>
                    E-mail ID: <span style={{ color: 'black' }}>{profileData.email}</span>
                  </p>
                </Grid>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              padding: !lessThanSmall ? '0px 30px 30px 30px' : '0px 20px 25px 20px',
              display: 'flex',
              flexDirection: lessThanSmall ? 'column-reverse' : 'row',
              justifyContent: 'center',
            }}
          >
            {/* <Button>Disagree</Button> */}
            {/* <Button onClick={handleClose}>Edit Details</Button> */}
            {confirmationText.editButton ? (
              <Grid style={{ width: '100%' }}>
                <CustomButton
                  style={{ border: '2px solid black', width: '100%' }}
                  hoverBackgroundColor='white'
                  hoverColor='black'
                  backgroundColor='white'
                  fontColor='black'
                  onClick={handleClose}
                  text={confirmationText.editButton}
                  className={'font-roboto'}
                />
              </Grid>
            ) : null}
            {confirmationText.confirmButton ? (
              <CustomButton
                style={{
                  width: '100%',
                  marginLeft: confirmationText.editButton ? (lessThanSmall ? '0px' : '8px') : '0px',
                  marginBottom: confirmationText.editButton
                    ? lessThanSmall
                      ? '10px'
                      : '0px'
                    : '0px',
                  ...(confirmationText.confirmButtonBackgroundColor
                    ? { backgroundColor: confirmationText.confirmButtonBackgroundColor }
                    : {}),
                }}
                onClick={confirmDataButton}
                text={confirmationText.confirmButton}
                className={'font-roboto'}
              />
            ) : null}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </PopUpWrapper>
  );
};

export default ConfirmationDialog;
