import { Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import will_signing_tips_bullet from '../../../../../assets/svg/will_signing_tips_bullet.svg';
import CustomButton from '../../../../../common/Reusables/Button';
import { useState } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(95%, 480px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '40px 52px 54px 32px',
  borderRadius: '12px',
  boxSizing: 'border-box',
};
const style2 = {
  background: 'rgba(16, 24, 40, 0.6)',
};

const confirmation_list = [
  'It is well-lit and all parties are clearly visible',
  'Make sure all parties are within the frame without anyone being cut off',
  'Make sure you have recorded the video in a single take. Multiple videos that are stitched together will not be legally admissible.',
];

export default function UploadingConfitmationWindow({
  isOpenModal,
  handleModalClose,
}: {
  isOpenModal: boolean;
  handleModalClose: Function;
}) {
  const [isUnderstoodInstructions, setIsUnderstoodInstructions] = useState<boolean>(false);

  return (
    <Modal
      sx={style2}
      open={isOpenModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: 'auto',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '12px',
              marginBottom: '12px',
              display: 'inline-flex',
              flexDirection: 'column',
            }}
          >
            <div
              className={'font-merriweather'}
              style={{
                color: '#101828',
                fontSize: 16,
                wordWrap: 'break-word',
              }}
            >
              Uploading video...
            </div>
            <div
              className={'font-merriweather'}
              style={{
                color: '#101828',
                fontSize: 22,
                wordWrap: 'break-word',
              }}
            >
              Please confirm that the uploaded video is recorded according to these instructions
            </div>
          </div>
          <div className={'flex flex-col'}>
            {confirmation_list.map((tip) => (
              <div
                style={{
                  width: 'fit-content',
                  height: 'min-content',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  display: 'flex',
                  gap: '12px',
                  marginBottom: '12px',
                }}
              >
                <img className={'h-8px w-8px m-5px'} src={will_signing_tips_bullet} />
                <h4
                  className={'font-roboto'}
                  style={{
                    color: '#101828',
                    fontSize: '16px',
                    wordWrap: 'break-word',
                  }}
                >
                  {tip}
                </h4>
              </div>
            ))}
          </div>
          <div
            style={{
              alignItems: 'center',
              justifyContent: 'flex-start',
              display: 'flex',
              color: '#17449B',
              width: '100%',
            }}
            className={'pb-15px'}
          >
            <Checkbox
              checked={isUnderstoodInstructions}
              sx={{
                color: '#17449B',
                '&.Mui-checked': {
                  color: '#17449B',
                },
              }}
              onChange={() => setIsUnderstoodInstructions(!isUnderstoodInstructions)}
            />
            <h4 className='heading7 font-roboto-light'>
              Yes, the uploaded video fulfills the above criteria
            </h4>
          </div>
          <CustomButton
            style={{ width: '100%' }}
            text={'Continue'}
            disabled={!isUnderstoodInstructions}
            onClick={() => {
              handleModalClose();
            }}
          />
        </div>
      </Box>
    </Modal>
  );
}
