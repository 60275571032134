import { Backdrop, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { WillPreviewContainer } from '../../Pages/MyWill/CreateWill/WillPreviewContainer';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const WillModal = ({
  isModalOpen,
  setIsModalOpen,
  url,
  header,
}: {
  isModalOpen: boolean;
  setIsModalOpen: Function;
  url: string;
  header: string;
}) => {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      BackdropComponent={styled(Backdrop, {
        name: 'MuiModal',
        slot: 'Backdrop',
        overridesResolver: (props, styles) => {
          return styles.backdrop;
        },
      })({ zIndex: -1 })}
      BackdropProps={{ sx: { background: 'rgba(16, 24, 40, 0.85)' } }}
    >
      <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center' }}>
        <div
          onClick={() => setIsModalOpen(false)}
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'auto',
            width: lessThanSmall ? '90%' : '60%',
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ margin: '32px 0px' }}
          >
            <h2
              className='heading2'
              style={{ color: '#fff', ...(lessThanSmall ? { fontSize: '16px' } : {}) }}
            >
              {header}
            </h2>
            <ClearIcon
              style={{ color: '#fff', cursor: 'pointer' }}
              onClick={() => setIsModalOpen(false)}
            />
          </Grid>
          <WillPreviewContainer url={url} />
        </div>
      </div>
    </Modal>
  );
};
