import BackToSectionSummary from '../../../BackToSectionSummary';
import will_signing_video_tips_icon from '../../../../../assets/svg/will_signing_video_tips_icon.svg';
import will_signing_tips_bullet from '../../../../../assets/svg/will_signing_tips_bullet.svg';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import WillSigningUploadRightPanel from './WillSigningUploadRightPanel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const tips_for_preparing_space = [
  'Find a well-lit space with minimal background noise.',
  'Use a tripod or a stable surface like a bookshelf to keep your phone steady.',
  'Record in landscape mode for optimal viewing.',
  'Test the video beforehand to ensure clear audio.',
  'Make sure everyone is visible within the frame throughout the recording, without any heads being cut off or moving out of frame.',
];

export const WillSigningUploadingPage = ({
  setIsUploadingVideo,
  setJustCompletedUploading,
  renderHelpIcon,
}: {
  setIsUploadingVideo: Function;
  setJustCompletedUploading: Function;
  renderHelpIcon: Function;
}) => {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const navigate = useNavigate();
  return (
    <>
      <Grid
        lg={12}
        md={12}
        sm={12}
        className='content background-fcfcfc'
        style={{
          backgroundColor: '#fcfcfc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          ...(lessThanSmall ? { paddingBottom: '50px' } : {}),
        }}
      >
        <div
          className={'hide_in_mobile'}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: '16px',
            alignItems: 'center',
          }}
        >
          <BackToSectionSummary noMargin={true} text='Back to My Will' action={() => navigate(0)} />

          {renderHelpIcon()}
        </div>
        <div
          className={`flex ${lessThanSmall ? 'flex-col w-100 border-box' : 'max-w-80'} gap-16px `}
        >
          <div
            style={{
              width: lessThanSmall ? '100%' : '30%',
              boxSizing: 'border-box',
              height: 'auto',
              padding: lessThanSmall ? 14 : 28,
              background: '#F8F3EB',
              borderRadius: 12,
              overflow: 'hidden',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'inline-flex',
              gap: '12px',
            }}
          >
            <div
              style={{
                width: 'auto',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '12px',
                marginBottom: '12px',
                display: 'inline-flex',
              }}
            >
              <img className={'h-48px w-48px'} src={will_signing_video_tips_icon} />
              <div
                className={'font-merriweather'}
                style={{
                  color: '#101828',
                  fontSize: 22,
                  wordWrap: 'break-word',
                }}
              >
                Tips for preparing your space
              </div>
            </div>
            <>
              {tips_for_preparing_space.map((tip) => (
                <div
                  style={{
                    width: 'fit-content',
                    height: 'min-content',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    display: 'flex',
                    gap: 12,
                  }}
                >
                  <img className={'h-8px w-8px m-5px'} src={will_signing_tips_bullet} />
                  <h4
                    className={'font-roboto-light'}
                    style={{
                      color: '#101828',
                      fontSize: '16px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {tip}
                  </h4>
                </div>
              ))}
            </>
          </div>
          <div
            style={{
              width: lessThanSmall ? '100%' : 'calc(70% - 100px)',
              boxSizing: 'border-box',
              height: 'auto',
              padding: lessThanSmall ? 14 : 28,
              background: '#F8F3EB',
              borderRadius: 12,
              overflow: 'hidden',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              display: 'inline-flex',
              gap: '12px',
            }}
          >
            <WillSigningUploadRightPanel
              setIsUploadingVideo={setIsUploadingVideo}
              setJustCompletedUploading={setJustCompletedUploading}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};
