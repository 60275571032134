import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CustomButton from 'common/Reusables/Button';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
import styled from 'styled-components';
import theme from 'styles/theme';
import warningIcon from 'assets/images/warning-orange.png';
import { InputWrapper } from 'common/Reusables/Inputs';
import CongratulationsWindow from 'common/CongratulationsWindow';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRedirectToPaymentOrMyWill } from 'common/useRedirectToPaymentOrMyWill';
import { useVendor } from 'common/useVendor';
// import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  padding: '32px',
  borderRadius: '12px',
  boxShadow: '0px -4px 8px rgba(97, 94, 87, 0.08)',
  borderBottom: '6px solid #F09000',
  width: 'min(80%, 560px)',
  height: 'max(auto, 408px)',
};

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
  .howItWorks {
    width: 48%;
    height: 50px;
    border: 2px solid ${theme.primaryColor};
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      color: ${theme.primaryColor};
      display: flex;
      justify-content: center;
      align-items: center;

      .playBtn {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
`;

export default function KYCShowModal({
  handleOpenCloseModal,
  isShowModal,
  handleKYC,
}: {
  handleOpenCloseModal: Function;
  isShowModal: boolean;
  handleKYC: Function;
}) {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const userActiveWill = useAppSelector(selectActiveWill);
  const { redirectToPaymentOrMyWill } = useRedirectToPaymentOrMyWill();
  const [proceedWithoutKyc, setProceedWithoutKyc] = useState<boolean>(false);
  const [userFullName, setUserFullName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [congratulationsWindow, setCongratulationsWindow] = useState<boolean>(false);
  const { isAcko } = useVendor();

  const handleErrorState = (val: string) => {
    if (val) {
      setNameError(val !== userActiveWill?.testatorDetails?.fullName);
    } else {
      setNameError(false);
    }
  };

  useEffect(() => {
    if(isAcko){
      setProceedWithoutKyc(true);
    }
  },[isAcko])

  if (congratulationsWindow) {
    return (
      <CongratulationsWindow
        isOpenModal={congratulationsWindow}
        handleModalClose={() => setCongratulationsWindow(false)}
      />
    );
  }

  return (
    <div>
      <Modal
        open={isShowModal}
        onClose={() => {
          handleOpenCloseModal();
          setProceedWithoutKyc(false);
          setUserFullName('');
          setNameError(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} style={{ height: proceedWithoutKyc ? 'max(auto, 520px)' : '' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '15px',
              borderRadius: '8px',
            }}
          >
            <img src={warningIcon} />
          </div>

          <p style={{ fontSize: '16px' }}>
            Looks like you haven't completed your KYC. We recommend you do this so that the details
            in your Will match your government issued ID. If you choose to skip KYC, please verify
            your personal details below to be used in your Will document.
          </p>

          <Grid
            style={{
              backgroundColor: 'rgba(209, 227, 250, 0.54)',
              padding: '12px',
              margin: '12px 0px',
              borderRadius: '8px',
            }}
          >
            <p style={{ color: '#aaa', margin: '8px' }}>
              Name:{' '}
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {userActiveWill?.testatorDetails?.fullName
                  ? userActiveWill?.testatorDetails?.fullName
                  : 'NA'}
              </span>
            </p>
            <p style={{ color: '#aaa', margin: '8px' }}>
              Gender:{' '}
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {userActiveWill?.testatorDetails?.gender
                  ? userActiveWill?.testatorDetails?.gender
                  : 'NA'}
              </span>
            </p>
            <p style={{ color: '#aaa', margin: '8px' }}>
              Birth Date:{' '}
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {userActiveWill?.testatorDetails?.dateOfBirth
                  ? new Date(userActiveWill?.testatorDetails?.dateOfBirth).getDate() +
                    '/' +
                    (new Date(userActiveWill?.testatorDetails?.dateOfBirth).getMonth() + 1) +
                    '/' +
                    new Date(userActiveWill?.testatorDetails?.dateOfBirth).getFullYear()
                  : 'NA'}
              </span>
            </p>

            <p style={{ color: '#aaa', margin: '8px' }}>
              Parent's Name:{' '}
              <span style={{ fontWeight: 'bold', color: '#000' }}>
                {userActiveWill?.testatorDetails?.fatherName
                  ? userActiveWill?.testatorDetails?.fatherName
                  : 'NA'}
              </span>
            </p>
          </Grid>

          {proceedWithoutKyc && (
            <p>Re-confirm your Full name as you would like it to appear in your Will</p>
          )}

          {proceedWithoutKyc ?
            <>
              <InputWrapper style={{ margin: '12px 0px' }}>
                <input
                  style={{ height: '48px', border: nameError ? '2px solid #BA1B1B' : '', outline: 'none', borderRadius: '8px' }}
                  placeholder='Type your full name here'
                  value={userFullName}
                  onChange={(e) => {
                    setUserFullName(e.target.value);
                    handleErrorState(e.target.value);
                  }}
                />
              </InputWrapper>

              {nameError &&
                <p style={{ color: '#BA1B1B', fontSize: '16px' }}>
                  Entered name does not match the name used in Personal Details
                </p>}

              <CustomButton
                text='Continue'
                style={{
                  marginTop: lessThanSmall ? '10px' : '35px',
                  width: 'min(100%, 558px)',
                  borderRadius: '8px',
                }}
                // TODO: Check this section whether redirection
                // should be to my will or to pricing
                onClick={() => {
                  if (!nameError) {
                    redirectToPaymentOrMyWill();
                  }
                }}
                disabled={!userFullName || nameError}
              />
            </>
            : <ButtonWrapper>
              <div className='howItWorks cursor-pointer' style={{ width: '290px', marginRight: '10px' }}>
                <a className='content' onClick={() =>
                  // navigate(Pages.KYC)
                  handleKYC()
                }
                >
                  <span>Go to KYC page</span>
                </a>
              </div>

              <CustomButton
                text='Proceed without KYC'
                onClick={() => setProceedWithoutKyc(true)}
                style={{ width: '290px', borderRadius: '10px' }}
              />
            </ButtonWrapper>}
        </Box>
      </Modal>
    </div>
  );
}
