import { styled } from '@mui/system';
import Drawer from '@mui/material/Drawer';
import { AddOn, Body } from '../../utils/choosePlanTypes';

import bluecheck from 'assets/svg/img_bluecheck.svg';
import even_image from 'assets/svg/even_mobile_drawer_graphic.svg';
import yellow_smart_feature_graphic from 'assets/svg/smart_mobile_drawer_graphic.svg';
import will_infopopup_mobile from 'assets/svg/will_infopopup_mobile.svg';
import cross from 'assets/svg/img_plandetaildismisscross.svg';
import pointSvg from 'assets/svg/img_planfeaturelistsupoint.svg';

import { Text } from '../Text';
import { Img } from '../Img';
import img_arrowright from '../../assets/svg/img_arrowright_blue_700.svg';
import evenPolicyDoc from '../../assets/pdf/addon/even/eve_policy_doc.pdf';
import { SITE_FAQ } from '../../routes';

const StyledDrawer = styled(Drawer)({
  borderRadius: '15px',
  padding: '16px',
  '& .MuiPaper-root': {
    height: '100%',
    background: 'transparent',
    overflow: 'scroll',
  },
  '& .MuiBackdrop-root': {
    background: 'rgba(16, 24, 40, 0.85)',
  },
});

interface AddOnPopupProps {
  add_on?: Partial<AddOn>;
  open?: boolean;
  onClose?: () => unknown;
}

interface SpecialLinkProps {
  specialLink?: string;
}

const getImageForAddOn: (add_on_name: string | undefined) => string | undefined = (
  add_on_name: string | undefined,
) => {
  switch (add_on_name) {
    case 'even':
      return even_image;
    case 'smart':
      return yellow_smart_feature_graphic;
    case 'will':
      return will_infopopup_mobile;
  }
};

const getNumberingBulleting = (bulletingStyle?: string, index?: number) => {
  switch (bulletingStyle) {
    case 'numbered':
      return (
        <div
          className={
            'rounded-radius50p border-color-black h-min-content p-8px aspect-ratio-1-1 overflow-hidden flex items-center justify-center'
          }
        >
          <Text className={'font-roboto'} variant={'body1'}>
            {Number(index) + 1}
          </Text>
        </div>
      );
    default:
      return <img src={bluecheck} className={'h-12px w-12px pt-2px'} />;
  }
};

export const SpecialLink = ({ specialLink }: SpecialLinkProps) => {
  switch (specialLink) {
    case 'will_info_popup_yellow_plans_know_more':
      return (
        <Text className={'unselectable'} variant={'h7'}>
          <a className={'underline text-blue_700 cursor-pointer'} href={SITE_FAQ} target='_blank'>
            Click here
          </a>{' '}
          to learn more about our Yellow plans
        </Text>
      );
  }
  return null;
};

const getDocumentDownloadLink = (addOnData?: Partial<AddOn>) => {
  switch (addOnData?.id) {
    case 'even':
      return (
        <div
          className={
            'w-auto max-w-100 flex flex-row border-box items-start justify-start gap-8px ml-8px pb-10px'
          }
        >
          <div className={'h-12px w-12px pt-2px'} />
          <a href={evenPolicyDoc} target='_blank' download>
            <div className='flex flex-row gap-8px h-32px items-center justify-start cursor-pointer'>
              <a
                className='font-medium text-14px text-blue_700 text-left underline cursor-pointer'
                rel='noreferrer'
              >
                Download Policy Document
              </a>
              <Img src={img_arrowright} className='h-24px w-24px cursor-pointer' alt='arrowright' />
            </div>
          </a>
        </div>
      );
  }
  return null;
};

const AddOnPopup = ({ add_on, open, onClose }: AddOnPopupProps) => (
  <StyledDrawer anchor={'bottom'} open={open} onClose={onClose}>
    {
      //@ts-ignore

      <div className={'w-100 h-100 flex overflow-scroll hide_scrollbars'}>
        <div
          className={'table mt-20 rounded-radius24 rounded-radius-bottom0 background_white p-24px'}
        >
          <div className={'table-row w-100'}>
            <div className={'w-100 flex flex-col gap-16px pb-60px'}>
              <div className={'w-100 flex flex-row border-box items-center justify-between'}>
                <h3>{add_on?.title}</h3>
                <img className={'h-12px w-12px'} src={cross} onClick={onClose} />
              </div>

              <img className={'w-100 h-auto border-box'} src={getImageForAddOn(add_on?.id)} />
              {add_on?.popUpFeatureText?.introduction ? (
                <Text className={'w-100'} variant={'h7'}>
                  {add_on?.popUpFeatureText?.introduction}
                </Text>
              ) : null}
              <Text
                className={`w-100 ${
                  add_on?.popUpFeatureText?.headerRobotoFont
                    ? 'font-roboto-bold'
                    : 'font-merriweather'
                }`}
                variant={'h6'}
              >
                {add_on?.popUpFeatureText?.header}
              </Text>
              {add_on?.popUpFeatureText?.headerParagraph ? (
                <Text className={'w-100'} variant={'h7'}>
                  {add_on?.popUpFeatureText?.headerParagraph}
                </Text>
              ) : null}
              {[add_on].map((add_on_data, index) => (
                <div className={`w-100 flex flex-col border-box gap-8px hide_scrollbars`}>
                  {add_on_data?.popUpFeatureText?.body.map(
                    (point: Partial<Body>, index: number) => (
                      <>
                        <div
                          className={
                            'w-100 flex flex-row border-box items-start justify-start gap-8px ml-8px'
                          }
                        >
                          {getNumberingBulleting(
                            add_on_data?.popUpFeatureText?.bodyListStyle,
                            index,
                          )}
                          <div className={'flex flex-col flex-grow gap-8px'}>
                            {point?.pointHeader ? (
                              <Text className={'font-roboto-bold flex-grow '} variant={'body1'}>
                                {point?.pointHeader}
                              </Text>
                            ) : null}
                            <Text className={'font-roboto flex-grow '} variant={'body1'}>
                              {point?.point}
                            </Text>
                          </div>
                        </div>
                        {point?.subPoints?.map((subpoint: string) => (
                          <div
                            className={
                              'w-100 flex flex-row border-box items-start justify-start gap-16px ml-24px'
                            }
                          >
                            <img src={pointSvg} className={'h-3px w-3px pt-5px'} />
                            <Text className={'font-roboto'} variant={'body1'}>
                              {subpoint}
                            </Text>
                          </div>
                        ))}
                      </>
                    ),
                  )}
                  {getDocumentDownloadLink(add_on_data)}
                </div>
              ))}
              {add_on?.popUpFeatureText?.additionalParagraphs?.map((additionalParagraph) => {
                if (additionalParagraph.specialLink) {
                  return <SpecialLink specialLink={additionalParagraph.specialLink} />;
                }
                return (
                  <div className={'flex flex-col gap-8px py-8px'}>
                    {additionalParagraph.header ? (
                      <Text
                        className={`w-100 ${
                          add_on?.popUpFeatureText?.headerRobotoFont
                            ? 'font-roboto-bold'
                            : 'font-merriweather'
                        }`}
                        variant={'h6'}
                      >
                        {additionalParagraph.header}
                      </Text>
                    ) : null}
                    {additionalParagraph.body ? (
                      <Text className={'w-100'} variant={'h7'}>
                        {additionalParagraph.body}
                      </Text>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    }
  </StyledDrawer>
);

export default AddOnPopup;
