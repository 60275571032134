import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import { useAppSelector } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import styled from 'styled-components';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(85%, 600px)',
  maxWidth: 'min(85%, 600px)',
  boxSizing: 'border-box',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '10px',
};

const DetailsWrapper = styled(Grid)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 8px 0px;
  .go-to {
    display: flex;
    align-items: center;
    color: #1270e8;
    cursor: pointer;
    font-size: 14px;
  }
`;

// const buttonStyle = {

// }

export default function DetailsModal({
  handleOpenCloseModal,
  isShowModal,
  goToAssets,
  goToDistribution,
  goToExecutor,
}: {
  handleOpenCloseModal: Function;
  isShowModal: boolean;
  goToAssets: Function;
  goToDistribution: Function;
  goToExecutor: Function;
}) {
  const userActiveWill = useAppSelector(selectActiveWill);
  return (
    <div>
      <Modal
        open={isShowModal}
        onClose={() => handleOpenCloseModal()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <p className='heading6'>Pending Details</p>
            <CloseIcon
              style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
              onClick={() => handleOpenCloseModal()}
            />
          </Grid>
          <p style={{ margin: '12px 0px' }}>
            Complete these following steps to Generate your Will:
          </p>
          {userActiveWill?.assets && userActiveWill.assets.length === 0 ? (
            <DetailsWrapper>
              <div
                style={{
                  backgroundColor: '#FEE071',
                  marginRight: '10px',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  minHeight: '24px',
                  maxWidth: '24px',
                  maxHeight: '24px',
                  display: 'inline-block',
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
              />
              <div
                style={{
                  fontSize: '14px',
                  width: '100%',
                  lineHeight: '22px',
                  gap: '12px',
                }}
              >
                <span
                  className={'font-roboto'}
                  style={{
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                >
                  You haven’t added any assets
                  <br />
                  <a className={'text-blue_700 underline font-roboto'} onClick={() => goToAssets()}>
                    My Estate
                  </a>{' '}
                </span>
                <TrendingFlatIcon
                  style={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-block',
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                  className={'text-blue_700'}
                  onClick={() => goToAssets()}
                />
              </div>
            </DetailsWrapper>
          ) : null}
          {userActiveWill?.assetDistribution &&
          userActiveWill.assets &&
          (userActiveWill.assetDistribution.length === 0 ||
            userActiveWill.assetDistribution.length !== userActiveWill.assets.length) ? (
            <DetailsWrapper>
              <div
                style={{
                  backgroundColor: '#FEE071',
                  marginRight: '10px',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  minHeight: '24px',
                  maxWidth: '24px',
                  maxHeight: '24px',
                  display: 'inline-block',
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
              />
              <div
                style={{
                  fontSize: '14px',
                  width: '100%',
                  lineHeight: '22px',
                  gap: '12px',
                }}
              >
                <span
                  className={'font-roboto'}
                  style={{
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                >
                  Not all of your listed assets are distributed
                  <br />
                  <a
                    className={'text-blue_700 underline font-roboto'}
                    onClick={() => goToDistribution()}
                  >
                    Asset Distribution
                  </a>{' '}
                </span>
                <TrendingFlatIcon
                  style={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-block',
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                  className={'text-blue_700'}
                  onClick={() => goToDistribution()}
                />
              </div>
            </DetailsWrapper>
          ) : null}
          {!userActiveWill?.executor ? (
            <DetailsWrapper>
              <div
                style={{
                  backgroundColor: '#FEE071',
                  marginRight: '10px',
                  borderRadius: '50%',
                  width: '24px',
                  height: '24px',
                  minWidth: '24px',
                  minHeight: '24px',
                  maxWidth: '24px',
                  maxHeight: '24px',
                  display: 'inline-block',
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
              />
              <div
                style={{
                  fontSize: '14px',
                  width: '100%',
                  lineHeight: '22px',
                  gap: '12px',
                }}
              >
                <span
                  className={'font-roboto'}
                  style={{
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                >
                  You haven’t appointed an Executor
                  <br />
                  <a
                    className={'text-blue_700 underline font-roboto'}
                    onClick={() => goToExecutor()}
                  >
                    My Executor
                  </a>{' '}
                </span>
                <TrendingFlatIcon
                  style={{
                    width: '24px',
                    height: '24px',
                    display: 'inline-block',
                    lineHeight: '18px',
                    verticalAlign: 'middle',
                  }}
                  className={'text-blue_700'}
                  onClick={() => goToExecutor()}
                />
              </div>
            </DetailsWrapper>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
