import { useState, useRef, useEffect } from 'react';
import { Grid, Slider } from '@mui/material';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { exitFullScreen, formatDuration, toggleFullScreen } from 'utils';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { breakpoints } from 'styles/Breakpoints';

const VideoWrapper = styled(Grid)`
  height: 450px;
  position: relative;
  width: 100%;
  padding: 15px 15px 30px;

  background: #fcfcfc;
  box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
  border-radius: 16px;

  .video {
    object-fit: cover;
    border-radius: 8px;
  }

  .iframe {
    border-radius: 8px;
  }
  .icon-size {
    transform: scale(1.2);
  }
  .react-player {
    border-radius: 10px;
    overflow: hidden;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    height: 450px;
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    padding: 0px;
  }
`;

type videoConfig = {
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  analyticsAction?: Function;
  url: string;
  playing?: boolean;
};

type ReactPlayerState = {
  totalDuration: string;
  isFullScreen: boolean;
  playing: boolean;
  played: number;
  seeking: boolean;
  currentlyPlayingVideo: string;
};

type ReactPlayerChangeState = {
  loaded: number;
  loadedSeconds: number;
  played: number;
  playedSeconds: number;
};

const VideoContainer = ({
  lg = 6,
  md = 6,
  sm = 12,
  xs = 12,
  analyticsAction,
  url,
  playing,
}: videoConfig) => {
  const [reactPlayerState, setReactPlayerState] = useState<ReactPlayerState>({
    totalDuration: '0:00',
    isFullScreen: false,
    playing: playing ?? false,
    played: 0,
    seeking: false,
    currentlyPlayingVideo: url,
  });

  useEffect(() => {
    setReactPlayerState({
      ...reactPlayerState,
      playing: playing ?? false,
    });
  }, [playing]);

  const onSeek = (event: Event, newValue: number | number[], activeThumb: number) =>
    setReactPlayerState({
      ...reactPlayerState,
      played: parseFloat(((newValue as any) / 100) as any) as number,
    });

  const onSeekMouseDown = (event: any) =>
    setReactPlayerState({ ...reactPlayerState, seeking: true });

  const onSeekMouseUp = (
    event: Event | React.SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ) => {
    setReactPlayerState({ ...reactPlayerState, seeking: false });
    playerRef?.current?.seekTo(((newValue as any) / 100) as any, 'fraction');
  };
  const playerRef = useRef<any>(null);
  const playerContainerRef = useRef<any>(null);
  const handleProgress = (changeState: ReactPlayerChangeState) => {
    if (!reactPlayerState.seeking) {
      setReactPlayerState({
        ...reactPlayerState,
        played: changeState.played,
      });
    }
  };
  useEffect(() => {
    var document: any = window.document;
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setReactPlayerState({
          ...reactPlayerState,
          isFullScreen: false,
          totalDuration: formatDuration(playerRef?.current?.getDuration()),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoWrapper item lg={lg} md={md} sm={sm} xs={xs} ref={playerContainerRef}>
      {/*// @ts-ignore*/}
      <ReactPlayer
        width='100%'
        height={'90%'}
        ref={playerRef}
        url={reactPlayerState.currentlyPlayingVideo}
        playing={reactPlayerState.playing}
        onProgress={handleProgress}
        className='react-player'
        onDuration={(totalSeconds: number) =>
          setReactPlayerState({
            ...reactPlayerState,
            totalDuration: formatDuration(totalSeconds),
          })
        }
        onEnded={() => setReactPlayerState({ ...reactPlayerState, playing: false })}
        onPlay={() => {
          if (analyticsAction) analyticsAction();
          setReactPlayerState({ ...reactPlayerState, playing: true });
        }}
        onPause={() => setReactPlayerState({ ...reactPlayerState, playing: false })}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        display={'flex'}
        alignItems='center'
        justifyContent='space-around'
        className='controls'
        sx={{ marginTop: '20px', backgroundColor: '#fcfcfc' }}
      >
        <Grid
          lg={1}
          md={1}
          sm={1}
          item
          display={'flex'}
          alignItems='center'
          onClick={() =>
            setReactPlayerState({ ...reactPlayerState, playing: !reactPlayerState.playing })
          }
        >
          {reactPlayerState.playing ? (
            <PauseCircleOutlineIcon className='cursor-pointer icon-size' />
          ) : (
            <PlayCircleOutlineIcon className='cursor-pointer icon-size' />
          )}
        </Grid>
        <Grid item lg={8} md={8} sm={8} display={'flex'} alignItems='center'>
          <Slider
            min={0}
            max={100}
            value={reactPlayerState.played * 100}
            aria-label='Default'
            valueLabelDisplay='off'
            onChange={onSeek}
            onMouseDown={onSeekMouseDown}
            onChangeCommitted={onSeekMouseUp}
            sx={{
              height: '16px',
              '& .MuiSlider-rail': {
                color: 'rgba(16, 24, 40, 0.08);',
              },
              '& .MuiSlider-track': {
                color: '#FFE171',
              },
              '& .MuiSlider-thumb': {
                color: '#FFC632',
              },
            }}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={1} display={'flex'}>
          <span>
            {formatDuration(playerRef?.current?.getCurrentTime()) || '0:00'}/
            {reactPlayerState.totalDuration || '0:00'}
          </span>
        </Grid>
        <Grid
          item
          lg={1}
          md={2}
          sm={1}
          display={'flex'}
          alignItems='center'
          justifyContent={'flex-end'}
          className='cursor-pointer'
        >
          <OpenInFullIcon
            onClick={() => {
              if (reactPlayerState.isFullScreen) {
                exitFullScreen();
                setReactPlayerState({
                  ...reactPlayerState,
                  isFullScreen: false,
                });
                return;
              }
              setReactPlayerState({
                ...reactPlayerState,
                isFullScreen: true,
              });
              toggleFullScreen(playerContainerRef);
            }}
          />
        </Grid>
      </Grid>
    </VideoWrapper>
  );
};

export default VideoContainer;
