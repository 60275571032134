import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import {
  selectActiveWill,
  selectConfig,
  selectCurrentUserPlans,
  selectUserStatus,
  selectYellowPlusDefaultPlansConfig,
} from 'store/user/selectors';
import { FormRegisterInputs, HTMLInputTypes, NewStates } from 'utils/types';
import styled from 'styled-components';
// import { HtmlTooltip, getTooltipTitleByAssetType } from './HtmlTooltip';
import { assetsIcons, getEmptyAddress, regex } from 'utils';
// import PrintAndCourierRequestTracker from './PrintAndCourierRequestTracker';
import { Checkbox, Grid } from '@mui/material';
import InputField from 'common/Reusables/Inputs';
import { runMutation } from 'utils/graphQL';
import { CREATE_COURIER_REQUEST } from 'common/queries';
import { dismissToast, showToast, ToastMessage, ToastType } from 'store/toast/slice';
import {
  UserStatus,
  addToUserStatus,
  fetchUserProfileAction,
} from 'store/user/slice';
import useAnalyticsEventTracker, { EVENT_CATEGORIES } from 'common/GoogleAnalytics';
import SelectField from 'common/Reusables/Select';
// import { WillCreationLayoutWrapper } from 'Pages/YessPlus/PaymentSummary/paymentSummary';
import logo from 'assets/svg/yellowLogo.svg';
import BackToSectionSummary from '../../BackToSectionSummary';
import { useNavigate } from 'react-router-dom';
// import { Pages } from 'routes';
import PayButton from 'common/forms/PayButton';
import { Pages } from 'routes';
import { Amount } from 'utils/config/interfaces';
import Loading from 'common/Loading';
import { HtmlTooltip } from '../../CreateWill/Steps/HtmlTooltip';
import discountIcon from 'assets/images/discountCouponIcon.png';
import { Mixpanel } from 'utils/mixpanel';
import helpIcon from 'assets/images/willStepsHelpIcon.png';
import MuiDrawer from 'common/Reusables/Drawer';
import OverlayHead from '../../CreateWill/Steps/OverlayHead';
import { AssetSubTypesWrapper, HelpWrapper, QueryHelpCard } from '../../NewMyWill';
import book from 'assets/svg/book.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import { PrintAndCourierDetails } from 'utils/types';
import { handlePincodeChangeForShipping } from 'common/Reusables/pincodeService';
import { Outer } from 'common/forms/Wrappers';
import { Intercom } from '../../../../utils/intercom';
import Header from '../../../../common/Header';
import { breakpoints } from '../../../../styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useVendor } from 'common/useVendor';
import Button from 'common/Reusables/Button';
import SaveDetailsFloatingButton from 'Pages/MyWill/CreateWill/SaveDetailsFloatingButton';

const MainWrapper = styled(Grid)`
  overflow-x: scroll;
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.md}) {
    padding: 24px;
    overflow: scroll;
  }
  .payment {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #101828;
    margin-bottom: 32px;
  }
  .payment-sec {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #101828;
      margin-bottom: 16px;
    }
    .total {
      padding-top: 16px;
      border-top: 1px solid #1d2939;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
  .sub-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #101828;
    margin-bottom: 16px;
  }
  .intro {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
    color: #101828;
  }
  .label {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #101828;
    margin-bottom: 16px;
  }
`;

const PrintCourierFormWrapper = styled.div`
  .proceed-payment-btn {
    .saveDetails {
      background: #1d2939;
      border-radius: 10px;
      height: 48px;
      margin-right: 80px;
      @media screen and (max-width: ${breakpoints.md}) {
        margin-right: 0;
      }
      bottom: 7px;
      button {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
      }
    }
  }
  margin-top: 20px;
  input,
  select {
    background: rgba(71, 84, 103, 0.05);
    border-radius: 12px;
    height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: rgba(71, 84, 103, 0.64);
  }
  .multipleInputsContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    gap: 12px;
    @media screen and (max-width: ${breakpoints.md}) {
      flex-direction: column;
    }
    .container-1,
    .container-2 {
      min-width: 49%;
    }
  }

  .mailing_address_container {
    margin-top: 25px;
    .mailingLabel {
      margin-bottom: 15px;
    }
  }
`;

const PromoCodeWrapper = styled.div`
  margin: 10px 0px;
  margin-bottom: 30px;
  .payments-text-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #101828;
    margin-bottom: 16px;
  }
  form {
    /* This bit sets up the horizontal layout */
    display: flex;
    flex-direction: row;

    /* This bit draws the box around it */
    background: rgba(71, 84, 103, 0.05);

    /* I've used padding so you can see the edges of the elements. */
    padding: 14px 28px;
    border-radius: 10px;
  }

  input {
    /* Tell the input to use all the available space */
    flex-grow: 2;
    /* And hide the input's outline, so the form looks like the outline */
    border: none;
    padding: 0px 15px;
    border-radius: 10px;
    background: none;
    font-size: 15px;
  }

  input:focus {
    /* removing the input focus blue box. Put this on the form if you like. */
    outline: none;
  }

  button {
    /* Just a little styling to make it pretty */
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #101828;
  }
`;

const PromoCodeSuccess = styled(Grid)`
  background: #e7f6ea;
  border: 2px solid #41aa58;
  margin: 10px 0px;
  padding: 15px;
  border-radius: 10px;
  color: #41aa58;
`;

// const PromoCodeFailed = styled(Grid)`
//   background: #FFEEEE;
//   border: 2px solid #BA1B1B;
//   margin: 10px 0px;
//   padding: 15px;
//   border-radius: 10px;
//   color: #BA1B1B;
// `;

const PrintAndCourier = ({ resetToMyWill }: { resetToMyWill: Function }) => {
  const [showStepper, setShowStepper] = useState(false);
  const [personalAddress, setPersonalAddress] = useState(true);
  const [amount, setAmount] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userStatus = useAppSelector(selectUserStatus);
  const userActiveWill = useAppSelector(selectActiveWill);
  const config = useAppSelector(selectConfig);
  const isFirstWillComplete = () => userStatus.includes(UserStatus.FIRST_WILL_COMPLETED);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [showInfoScreen, setShowInfoScreen] = useState<boolean>(false);
  // const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [promoCode, setPromocode] = useState<string>('');
  const [successPromocode, setSuccessPromocode] = useState<boolean>(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  // const [initiatePaymentResult, setInitiatePaymentResult] = useState();
  const [loading, setLoading] = useState(false);

  const [courierAmount, setCourierAmount] = useState<number>(250);
  const [taxAmount, setTaxAmount] = useState<number>(0);
  const [printAndCourierScreen, setPrintAndCourierScreen] = useState<number>(1);
  const [submitWholeForm, setSubmitWholeForm] = useState<boolean>(false);
  const currentUserPlansResponse = useAppSelector(selectCurrentUserPlans);
  const yellowPlusConfig = useAppSelector(selectYellowPlusDefaultPlansConfig);
  const currentPlan = currentUserPlansResponse?.data?.fetchPlans?.[0];
  const planInfo =
    currentPlan && yellowPlusConfig?.plans?.find((plan) => plan?.id === currentPlan?.planId);
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));
  const { isAcko } = useVendor();

  // const { initiatePayment } = (initiatePaymentResult!! as any).data;

  // const checkForPayment = async (amount: Amount, reason: PaymentReason) => {
  //   const finalPayment = successPromocode ? (amount.basePrice - discountAmount) + Math.floor((amount.basePrice - discountAmount) * 0.18) : amount.grossAmount;
  //   const result = await runMutation({
  //     mutation: gql`
  //       mutation initiatePayment($input: PaymentInput!) {
  //         initiatePayment(input: $input) {
  //           orderId
  //           receiptId
  //         }
  //       }
  //     `,
  //     variables: {
  //       key: 'input',
  //       value: {
  //         amount: finalPayment * 100,
  //         willType: WILL_TYPES.PREMIUM_WILL,
  //         willId: userActiveWill?.documentId,
  //         paymentReason: reason,
  //       } as PaymentInput,
  //     },
  //   });
  //   return result;
  // };

  const handleApplyPromoCode = async (e: any) => {
    e.preventDefault();
    // console.log('This is promocode', promoCode);
    // Mixpanel.track('Coupon Added', { code: promoCode });
    // try {
    //   const sendDataToServer: any = await runMutation({
    //     mutation: VALIDATE_COUPON,
    //     variables: {
    //       key: 'input',
    //       value: promoCode as string,
    //     },
    //   });
    //   if (
    //     !sendDataToServer ||
    //     !sendDataToServer.data ||
    //     !sendDataToServer.data.validateCouponByCode
    //   ) {
    //     return dispatch(
    //       showToast({
    //         type: ToastType.ERROR,
    //         message: 'Invalid Coupon Code!!',
    //       }),
    //     );
    //   }
    //   const { discount } = sendDataToServer.data.validateCouponByCode;
    //   const calcDiscountAmount = Math.round((amount.basePrice / 100) * parseFloat(discount));
    //   if (calcDiscountAmount) {
    //     setDiscountAmount(calcDiscountAmount);
    //   }

    //   const reason = isFirstWillComplete() ? PaymentReason.WILL_UPDATE : PaymentReason.WILL_GENERATION;
    //   const response = await checkForPayment(amount, reason);
    //   setInitiatePaymentResult(response);
    //   setSuccessPromocode(true);
    //   setInvalidPromoCode(false);
    // } catch (error) {
    //   setInvalidPromoCode(true);
    //   setSuccessPromocode(false);
    //   setDiscountAmount(0);
    // }
  };

  useEffect(() => {
    if (planInfo && planInfo.title) {
      if (planInfo.title.toLowerCase() === 'select' || planInfo.title.toLowerCase() === 'elite') {
        setCourierAmount(0);
      }
    }
  }, [planInfo]);

  useEffect(() => {
    if (courierAmount) {
      const tax = Math.round(courierAmount * 0.18);
      setTaxAmount(tax);
    }
  }, [courierAmount]);

  useEffect(() => {
    if (
      userActiveWill &&
      userActiveWill.courierRequestInfo &&
      userActiveWill.courierRequestInfo !== null
    )
      setShowStepper(true);
  }, [userActiveWill]);

  useEffect(() => {
    (async () => {
      if (userStatus.includes(UserStatus.LOGGED_OUT)) {
        setIsLoading(false);
        navigate(Pages.HOME, { replace: true });
      }

      if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
        // If will is not complete, redirect to will editor page
        if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) navigate(Pages.WILL_EDITOR);

        const isSecondWill = isFirstWillComplete();
        const amount: Amount = isSecondWill
          ? config!!.configs.mobilePlans['Update Premium Will']
          : config!!.configs.mobilePlans['Premium Will'];
        // const reason = isSecondWill ? PaymentReason.WILL_UPDATE : PaymentReason.WILL_GENERATION;

        try {
          // const response = await checkForPayment(amount, reason);
          // setInitiatePaymentResult(response);
          setAmount(amount);
          setIsLoading(false);
        } catch (err) {
          if ((err as Error).message.indexOf('NO_PAYMENT_REQUIRED') > -1) {
            dispatch(addToUserStatus(UserStatus.ACTION_SET_PAYMENT_COMPLETED));
            dispatch(fetchUserProfileAction());
            navigate(Pages.MY_WILL, { replace: true });
          }
        }
      }
    })();
  }, [
    userStatus,
    // discountAmount, successPromocode
  ]);
  if (isLoading) return <Loading />;

  console.log('amount', amount);

  const onHelpClickRightSide = () => {
    setShowInfoScreen(true);
    Mixpanel.track('Question mark button clicked', {
      card_name: 'Print & Courier Will',
    });
  };

  return (
    // <WillCreationLayoutWrapper>
    <>
      <div className={'hide_in_mobile'}>
        <Grid
          style={{
            padding: '20px 85px',
            borderBottom: '1px solid rgba(29, 41, 57, 0.16)',
          }}
        >
          <img style={{ width: '112px' }} src={logo} alt='logo' />
        </Grid>
      </div>
      <MainWrapper>
        {userActiveWill && Object.keys(userActiveWill).length > 0 && !showStepper && (
          <>
            <div className={'hide_in_mobile'}>
              <BackToSectionSummary text='Back to My Will' action={resetToMyWill} />
            </div>
            <div className={'show_in_mobile w-100 flex flex-row'}>
              <Header
                // backgroundColorClass={userActiveWill ? 'background_white' : 'background_lightBeige'}
                backgroundColorClass={'background_white'}
                // @ts-ignore
                centerItems={true}
                backButton={true}
                title={'My Will'}
                backButtonOnClick={resetToMyWill}
                hideAvatar={true}
                renderRightHandSide={
                  lessThanSmall ? (
                    <HelpOutlineIcon onClick={onHelpClickRightSide} />
                  ) : (
                    <img
                      style={{
                        height: '60px',
                        ...(lessThanSmall ? {} : { marginTop: '33px', marginRight: '80px' }),

                        cursor: 'pointer',
                      }}
                      src={helpIcon}
                      alt='Help Icon'
                      onClick={onHelpClickRightSide}
                    />
                  )
                }
              />
            </div>
            <Grid
              style={{
                padding: lessThanSmall ? '0px 10px' : '0px 80px',
                paddingBottom: '300px',
                marginTop: '11px',
                justifyContent: 'space-between',
              }}
              container
              columns={{ sm: 12, xs: 9, md: 12 }}
            >
              <Grid
                item
                sm={12}
                xs={9}
                md={7}
                style={{
                  ...(!lessThanSmall || printAndCourierScreen === 1 ? {} : { display: 'none' }),
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                    alignItems: 'center',
                    ...(lessThanSmall ? { width: '100%' } : {}),
                  }}
                >
                  <h3
                    className={'font-roboto-bold'}
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '400',
                      fontSize: lessThanSmall ? '24px' : '32px',
                      lineHeight: '40px',
                      color: '#101828',
                    }}
                  >
                    Print & Courier Will
                  </h3>
                  <div className={'hide_in_mobile'}>
                    {lessThanSmall ? (
                      <HelpOutlineIcon onClick={onHelpClickRightSide} />
                    ) : (
                      <img
                        style={{ height: '60px', cursor: 'pointer' }}
                        src={helpIcon}
                        alt='Help Icon'
                        onClick={onHelpClickRightSide}
                      />
                    )}
                  </div>
                </div>
                <MuiDrawer
                  open={showInfoScreen}
                  hideDrawer={() => setShowInfoScreen(false)}
                  width={'min(100%, 500px)'}
                >
                  <AssetSubTypesWrapper>
                    <OverlayHead
                      heading='Print & Courier Will'
                      onClick={() => setShowInfoScreen(false)}
                      setShowHelp={() => setShowInfoScreen(true)}
                    />
                    <p className='notify-help-text'>
                      According to the current law in India, a Will is not valid unless it has been
                      physically signed in the presence of 2 witnesses.
                    </p>
                    <p className='notify-help-text'>
                      If you would like, we are happy to print and ship your Will to you free of
                      charge*. You can also get a soft copy emailed to you and print it out
                      yourself.
                    </p>
                    <p className='notify-help-text' style={{ color: '#666B75' }}>
                      * Free for first-time Will requests only. Updates may be printed and shipped
                      for a fee.
                    </p>
                  </AssetSubTypesWrapper>
                  <HelpWrapper container>
                    {!isAcko && (
                      <Grid
                        item
                        lg={12}
                        className='queries_section'
                        display={'flex'}
                        flexDirection='column'
                        justifyContent='space-around'
                        alignItems={'center'}
                      >
                        <p className='subText font-semi-bold'>Have a specific query in mind?</p>
                        <QueryHelpCard
                          icon={book}
                          text='Explore FAQs'
                          onClick={() => window.open('https://getyellow.in/faq', '_blank')}
                        />
                        <QueryHelpCard
                          icon={contact}
                          text='Contact Us'
                          onClick={Intercom.hideOverLayAndShowIntercom({
                            setShowOverlay: setShowInfoScreen,
                          })}
                        />
                      </Grid>
                    )}
                  </HelpWrapper>
                </MuiDrawer>
                <p className='intro subText font-roboto'>
                  A copy of your latest Will and testament will be printed and couriered to you.
                </p>
                <h4 className='label font-roboto'>Confirm your details below </h4>
                {personalAddress ? (
                  <PrintAndCourierAddressForm
                    showTracker={() => setShowStepper(true)}
                    setLoading={setLoading}
                    personalAddress={personalAddress}
                    defaultValues={
                      {
                        email: userActiveWill.testatorDetails?.email,
                        name: userActiveWill.testatorDetails?.fullName,
                        phoneNumber: userActiveWill.testatorDetails?.phoneNumber,
                        mailingAddress:
                          userActiveWill.testatorDetails?.address ?? getEmptyAddress(),
                      } as PrintAndCourierDetails
                    }
                    printAndCourierScreen={printAndCourierScreen}
                    setPrintAndCourierScreen={setPrintAndCourierScreen}
                    submitWholeForm={submitWholeForm}
                  />
                ) : (
                  <PrintAndCourierAddressForm
                    showTracker={() => setShowStepper(true)}
                    setLoading={setLoading}
                    defaultValues={
                      {
                        email: userActiveWill.testatorDetails?.email,
                        name: userActiveWill.testatorDetails?.fullName,
                        phoneNumber: userActiveWill.testatorDetails?.phoneNumber,
                        mailingAddress: getEmptyAddress(),
                      } as PrintAndCourierDetails
                    }
                    personalAddress={personalAddress}
                    printAndCourierScreen={printAndCourierScreen}
                    setPrintAndCourierScreen={setPrintAndCourierScreen}
                    submitWholeForm={submitWholeForm}
                  />
                )}
                <div className={'font-roboto'} style={{ alignItems: 'center', display: 'flex' }}>
                  <Checkbox
                    checked={personalAddress}
                    sx={{
                      '&.Mui-checked': {
                        color: '#1D2939',
                      },
                    }}
                    onChange={() => setPersonalAddress(!personalAddress)}
                  />
                  Same as address in Personal Details
                </div>
              </Grid>

              {(!lessThanSmall || printAndCourierScreen === 2) && (
                <Grid item xs={12} md={3}>
                  <Grid>
                    <>
                      <p style={{ fontSize: '24px', marginBottom: '32px' }}>Payment Summary</p>
                      {
                        <>
                          <PromoCodeWrapper>
                            <div className='payments-text-heading font-roboto'>
                              Have a promotional code?
                            </div>
                            <form
                              // style={{ border: invalidPromoCode ? '2px solid #BA1B1B' : '' }}
                              onSubmit={handleApplyPromoCode}
                            >
                              <img
                                src={discountIcon}
                                style={{ width: '20px', height: '20px', margin: 'auto' }}
                                alt=''
                              />
                              <input
                                type='text'
                                name='promoCode'
                                value={promoCode}
                                placeholder='Coupon Code'
                                onChange={(e) => {
                                  setPromocode(e.target.value.toUpperCase());
                                  setSuccessPromocode(false);
                                  setDiscountAmount(0);
                                }}
                              />
                              <button
                                style={{
                                  padding: '0',
                                  fontWeight: '700',
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                  textAlign: 'right',
                                  color: '#1D2939',
                                  cursor: 'pointer',
                                }}
                                disabled={promoCode === ''}
                                type='submit'
                              >
                                APPLY
                              </button>
                            </form>
                          </PromoCodeWrapper>
                        </>
                      }
                    </>
                    {successPromocode ? (
                      <PromoCodeSuccess>
                        <Grid display={'flex'}>
                          <div>🎉</div>
                          <div style={{ marginLeft: '12px' }}>
                            <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                              Congratulations
                            </p>
                            <div style={{ fontSize: '13px' }}>
                              You just saved ₹{discountAmount} on your Will
                            </div>
                          </div>
                        </Grid>
                      </PromoCodeSuccess>
                    ) : null}
                    {/* {invalidPromoCode ? (
                    <PromoCodeFailed>
                      <Grid display={'flex'}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            src={alertIcon}
                            style={{ width: '35px', height: '35px' }}
                            alt=''
                          />
                          <div style={{ marginLeft: '12px' }}>
                            <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>Invalid Code</p>
                            <div style={{ fontSize: '13px' }}>
                              The code you have entered is expired or incorrect
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </PromoCodeFailed>
                  ) : null} */}
                  </Grid>

                  <div className='payment-sec'>
                    <h5 className='sub-heading'>Invoice</h5>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <p>Shipping & Handling charges</p>
                      <p style={{ color: courierAmount > 0 ? '' : '#41AA58' }}>
                        {courierAmount > 0 ? `₹${courierAmount}` : 'FREE'}
                      </p>
                    </div>

                    {courierAmount > 0 && (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Total Tax</p>
                        <p>{`₹ ${taxAmount}`}</p>
                      </div>
                    )}
                    <div className='total'>
                      <h4>Amount Payable</h4>
                      <p style={{ color: courierAmount > 0 ? '' : '#41AA58' }}>
                        {courierAmount > 0 ? `₹ ${taxAmount + courierAmount}` : 'FREE'}
                      </p>
                    </div>
                  </div>
                </Grid>
              )}

              <div className={`font-roboto proceed-payment-btn ${lessThanSmall ? 'w-100' : ''}`}>
                <SaveDetailsFloatingButton
                  lessThanSmallPageBottom={lessThanSmall}
                  width={296}
                  loading={loading}
                  btnText={
                    lessThanSmall && printAndCourierScreen === 1 ? 'Next' : 'Proceed to Payment'
                  }
                  {...(lessThanSmall && printAndCourierScreen === 1
                    ? { onClick: () => setPrintAndCourierScreen(2) }
                    : { onClick: () => setSubmitWholeForm(true) })}
                />
              </div>
            </Grid>
          </>
        )}
        {/* {showStepper && <PrintAndCourierRequestTracker />} */}
      </MainWrapper>
      {!lessThanSmall && !showStepper && (
        <PayButton
          hideText={lessThanSmall}
          text='Shipping & Handling charges'
          button={false}
          totalAmount={courierAmount > 0 ? taxAmount + courierAmount : 0}
        />
      )}
    </>
    // </WillCreationLayoutWrapper>
  );
};

export default PrintAndCourier;

export const PrintAndCourierAddressForm = ({
  defaultValues,
  showTracker,
  style,
  handlePayments,
  personalAddress,
  setLoading,
  submitWholeForm,
}: {
  defaultValues?: PrintAndCourierDetails;
  showTracker?: Function;
  style?: React.CSSProperties | undefined;
  handlePayments?: Function;
  personalAddress?: boolean;
  printAndCourierScreen: number;
  setPrintAndCourierScreen: Function;
  setLoading: Function;
  submitWholeForm: boolean;
}) => {
  const gaEventTracker = useAnalyticsEventTracker({ category: EVENT_CATEGORIES.GENERATE_WILL });
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    console.log('in PrintAndCourierAddressForm, submitWholeForm is: ' + submitWholeForm);
    if (submitWholeForm)
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, [submitWholeForm]);

  useEffect(() => {
    // Reset the form with new default values when defaultValues prop changes
    setValue('mailingAddress.addressLine1', defaultValues?.mailingAddress.addressLine1 ?? '');
    setValue('mailingAddress.addressLine2', defaultValues?.mailingAddress.addressLine2 ?? '');
    setValue('mailingAddress.country', defaultValues?.mailingAddress.country ?? '');
    setValue('mailingAddress.pinCode', defaultValues?.mailingAddress.pinCode ?? '');
    setValue('mailingAddress.city', defaultValues?.mailingAddress.city ?? '');
    setValue('mailingAddress.state', defaultValues?.mailingAddress.state ?? '');
  }, [defaultValues]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
  } = useForm<PrintAndCourierDetails>({
    defaultValues,
  });

  // Component Utils
  const [displayTooltipBadge, setDisplayTooltipBadge] = useState(false);
  const [pincode, setPincode] = useState('');
  const [pincodeErrorMessage, setPincodeErrorMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const userActiveWill = useAppSelector(selectActiveWill);
  const dispatch = useTypedDispatch();

  const onSubmit: SubmitHandler<PrintAndCourierDetails> = async (data) => {
    console.log('in PrintAndCourierAddressForm, in onSubmit');
    if (handlePayments) {
      handlePayments();
    } else {
      setLoading(true);
      try {
        const {
          data: { createCourierRequest: courierData },
        }: any = await runMutation({
          mutation: CREATE_COURIER_REQUEST,
          variables: {
            key: 'input',
            value: { ...data, willId: userActiveWill?.documentId },
          },
        });

        console.log({ courierData });
        setLoading(false);
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Sucessfully Created a courier request.',
        };
        dispatch(showToast(toast));
        (window as any).webengage.track("Will Print Requested",{
          "Print Requested": "yes"
        });
        gaEventTracker({
          action: 'Requested for Print & Courier',
          label: 'Generate Will Page',
        });

        // dispatch(
        //   updateActiveWill({
        //     will: {
        //       ...userActiveWill,
        //       courierRequestInfo: courierData,
        //     } as Will,
        //   }),
        // );
        dispatch(fetchUserProfileAction());

        showTracker && showTracker();
        setLoading(false);

        setTimeout(() => {
          dispatch(dismissToast());
        }, 2000);
      } catch (error) {
        setLoading(false);
        const toast: ToastMessage = {
          type: ToastType.ERROR,
          message: 'Error creating a courier request.. Please try again.',
        };
        dispatch(showToast(toast));
      }
    }
  };

  useEffect(() => {
    if (pincode !== '') {
      const pincodeData = {
        pincode,
        clearErrors,
        setShowLoader,
        setPincodeErrorMessage,
        setError,
        setValue,
      };
      handlePincodeChangeForShipping(pincodeData);
    }
  }, [pincode]);

  return (
    <Outer>
      {showLoader && <Loading />}
      <PrintCourierFormWrapper style={style}>
        <form ref={formRef} className={'font-roboto'} onSubmit={handleSubmit(onSubmit)}>
          <InputField
            type={HTMLInputTypes.TEXT}
            readOnly={defaultValues?.name ? true : false}
            register={register as UseFormRegister<FormRegisterInputs>}
            errors={errors}
            errorMessage='This field is required'
            fieldToRegister='name'
            placeHolder='Full Name'
            required={true}
          />

          <div className='multipleInputsContainer font-roboto'>
            <div className='container-1 font-roboto'>
              <InputField
                readOnly={defaultValues?.phoneNumber ? true : false}
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                fieldToRegister='phoneNumber'
                placeHolder='Phone Number'
                pattern={regex.phoneNumber}
                errors={errors}
                errorMessage='Invalid Phone Number'
              />
            </div>

            <div className='container-2 font-roboto'>
              <InputField
                readOnly={defaultValues?.email ? true : false}
                type={HTMLInputTypes.EMAIL}
                register={register as UseFormRegister<FormRegisterInputs>}
                errors={errors}
                errorMessage='Invalid email address'
                fieldToRegister='email'
                placeHolder='Email'
                required={true}
                pattern={regex.email}
              />
            </div>
          </div>

          <div className='mailing_address_container font-roboto'>
            <h4 className='label font-roboto' style={{ padding: "8px 0 16px" }}>Mailing Address</h4>
            <InputField
              required={true}
              errors={errors}
              readOnly={defaultValues?.mailingAddress?.addressLine1 ? true : false}
              errorMessage='This field is required'
              type={HTMLInputTypes.TEXT}
              register={register as UseFormRegister<FormRegisterInputs>}
              fieldToRegister='mailingAddress.addressLine1'
              placeHolder='Address line 1'
            />

            <div className={'font-roboto'} style={{ margin: '20px 0px' }}>
              <InputField
                readOnly={defaultValues?.mailingAddress?.addressLine2 ? true : false}
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                fieldToRegister='mailingAddress.addressLine2'
                placeHolder='Address line 2 (Optional)'
              />
            </div>

            <div
              className={'font-roboto'}
              style={{
                position: 'relative',
              }}
            >
              <InputField
                readOnly={true}
                defaultValue='India'
                type={HTMLInputTypes.TEXT}
                register={register as UseFormRegister<FormRegisterInputs>}
                fieldToRegister='mailingAddress.country'
                placeHolder=''
              />
              <div
                className={'font-roboto'}
                style={{
                  position: 'absolute',
                  top: '11px',
                  right: '11px',
                }}
                onMouseEnter={() => setDisplayTooltipBadge(true)}
                onMouseLeave={() => setDisplayTooltipBadge(false)}
              >
                <HtmlTooltip
                  sx={{
                    '.MuiTooltip-tooltip': {
                      background: '#FFE171',
                      fontSize: '16px',
                      lineHeight: '24px',
                      padding: '15px',
                      fontWeight: '400',
                      color: '#1D2939',
                      maxWidth: '500px',
                      borderColor: 'transparent',
                      position: 'relative',
                      width: '100%',
                      minWidth: '100%',
                      right: '15px',
                    },
                    '.MuiTooltip-arrow': {
                      color: '#fde171',
                      '&:before': {
                        border: 'transparent',
                      },
                    },
                  }}
                  open={displayTooltipBadge}
                  // title={getTooltipTitleByAssetType(assetType as AssetType)}
                  title='Currently, we are unable to fulfill international shipping requests'
                  arrow
                  placement='bottom-end'
                  className={'font-roboto'}
                >
                  <img
                    style={{
                      position: 'absolute',
                      right: '0px',
                      top: '0px',
                    }}
                    src={assetsIcons.tooltip}
                    alt=''
                    className='cursor-pointer tooltipIcon'
                  />
                </HtmlTooltip>
              </div>
            </div>
            <div className='font-roboto multipleInputsContainer'>
              <div className='font-roboto container-1'>
                <InputField
                  type={HTMLInputTypes.TEXT}
                  readOnly={defaultValues?.mailingAddress?.pinCode ? true : false}
                  register={register as UseFormRegister<FormRegisterInputs>}
                  fieldToRegister='mailingAddress.pinCode'
                  placeHolder='Pin Code'
                  required={true}
                  pattern={regex.pinCodeIndia}
                  errors={errors}
                  errorMessage={pincodeErrorMessage}
                  // errorMessage='Invalid Pincode'
                  onChangeValue={(value: string) => {
                    setPincode(value);
                  }}
                />
              </div>

              <div className='font-roboto container-2'>
                <InputField
                  readOnly={defaultValues?.mailingAddress?.city ? true : false}
                  type={HTMLInputTypes.TEXT}
                  register={register as UseFormRegister<FormRegisterInputs>}
                  fieldToRegister='mailingAddress.city'
                  placeHolder='City'
                  required={true}
                  errors={errors}
                  errorMessage='This field is required'
                />
              </div>
            </div>
            <SelectField
              disabled={personalAddress}
              selectOptions={NewStates}
              placeHolder={'State'}
              hidePlaceHolder={true}
              fieldToRegister='mailingAddress.state'
              register={register as UseFormRegister<FormRegisterInputs>}
            />
            {/* <InputField
              type={HTMLInputTypes.TEXT}
              register={register as UseFormRegister<FormRegisterInputs>}
              fieldToRegister='mailingAddress.state'
              placeHolder='State'
              required={true}
              errors={errors}
              errorMessage='This field is required'
            /> */}
            <br />
          </div>

          {/* <em>
            Note : Delivery charges may vary based on your mailing address and not included as part of
            your will purchase.
          </em> */}
          <Button
            //@ts-ignore
            type='submit'
            className='solid-black-btn w-100'
            text="Submit details"
          />
        </form>
      </PrintCourierFormWrapper>
    </Outer>
  );
};
