import { Text } from '../Text';
import { Img } from '../Img';
import img_user from 'assets/svg/img_user.svg';
import deleteAccount from 'assets/svg/deleteAccount.svg';
import img_icon from 'assets/svg/img_icon.svg';
// import img_trash from 'assets/svg/img_trash.svg';
// import img_willhistory from 'assets/svg/img_willhistory.svg';
// import img_home from 'assets/svg/img_home.svg';
import img_checkmark from 'assets/svg/img_checkmark.svg';
// import img_search from 'assets/svg/img_search.svg';
// import img_search_gray_900 from 'assets/svg/img_search_gray_900.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import img_arrowdown from '../../assets/svg/img_arrowdown.svg';
import img_close from '../../assets/svg/img_close.svg';
import Header from '../Header';
import { useAppSelector, useTypedDispatch } from '../../store/hooks';
import {
  selectCurrentUserPlans,
  selectYellowPlusDefaultPlansConfig,
} from '../../store/user/selectors';
import { useEffect, useState } from 'react';
import { fetchCurrentUserPlans } from '../../store/user/slice';
import { Pages } from 'routes';
import redDot from 'assets/svg/Dashboard/redDot.svg';
import yellowDot from 'assets/svg/Dashboard/yellowDot.svg';
import greenDot from 'assets/svg/Dashboard/greenDot.svg';
import { loadUsersPlans, selectAllPurchasedPlans } from 'store/payments';
// import { ShimmerTitle } from 'shimmer-effects-react';

interface Props {
  currentLink?: string;
  closeIconAction?: () => unknown;
}

const items = [
  {
    icon: img_user,
    label: 'Profile',
    navigateTo: Pages.EDIT_PROFILE,
    comingSoon: false,
  },
  {
    type: 'membership',
    comingSoon: false,
  },
  // {
  //   icon: img_trash,
  //   label: 'Payment History',
  //   navigateTo: Pages.PAYMENT_HISTORY,
  // },
  // {
  //   icon: img_willhistory,
  //   label: 'Will History',
  //   comingSoon: true,
  // },
  // {
  //   icon: img_home,
  //   label: 'My Addresses',
  //   comingSoon: true,
  // },
  // {
  //   icon: img_search,
  //   label: 'Invite',
  //   comingSoon: true,
  // },
  // {
  //   icon: img_search_gray_900,
  //   label: 'KYC Details',
  //   comingSoon: true,
  // },
  {
    icon: img_checkmark,
    label: 'My Roles',
    navigateTo: Pages.MY_ROLES,
  },
  {
    icon: deleteAccount,
    label: 'Delete Account',
    navigateTo: Pages.DELETE_ACCOUNT,
  },
];

export const checkPlanStatus = (dateString: string | undefined) => {
  if(!dateString) return "ONGOING";
  return Date.now() > Number(dateString) ? 'EXPIRED' : ((Number(dateString) - Date.now())/(1000*60*60*24) > 30) ? "ONGOING" : "RENEWAL" 
}

export default ({ closeIconAction, currentLink }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUserPlans = useAppSelector(selectCurrentUserPlans);
  const yellowPlansConfig = useAppSelector(selectYellowPlusDefaultPlansConfig);
  const purchasedPlans = useAppSelector(selectAllPurchasedPlans);

  const purchasedPlanIds = purchasedPlans?.map((item) => item.itemId);

  useEffect(() => {
    dispatch(fetchCurrentUserPlans());
    dispatch(loadUsersPlans());
    console.log("ns - purchasedPlans: ", purchasedPlans);
  }, []);
  const oldPlans = [
    {
      'id': 'elite'
    },
    {
      'id': 'select'
    }
  ];
  const currentPlan = currentUserPlans?.data?.fetchPlans?.[0];
  const endDateStr = currentPlan?.expireAt;
  // const endDate = endDateStr ? new Date(Number(endDateStr)) : new Date();
  // const today = new Date();
  // const expired = endDate < today;
  const currentPlanInConfig = yellowPlansConfig?.plans?.find(
    (plan) => plan?.id === currentPlan?.planId,
  ) || oldPlans.find((plan) => plan?.id === currentPlan?.planId);
  console.log("ns - currentPlan: ", currentPlan);
  console.log("ns - currentPlanInConfig: ", currentPlanInConfig);

  const [lawyerCall, setLawyerCall] = useState(0);
  const [shippingAndCourier, setShippingAndCourier] = useState(0);

  const cartPlanDetails: any = {
    'starter': {
      'title': 'Starter Will',
      'subText': 'Create your Will with yellow',
    },
    'pro': {
      'title': 'Pro Will',
      'subText': 'Create your Will with yellow',
    },
    'yess': {
      'title': 'Yellow SMART Remote Support',
      'subText': 'Online guidance for the Executor of your Will'
    },
    'smart': {
      'title': 'Yellow Smart',
      'subText': 'Asset transfer support for your loved ones'
    },
    'elite': {
      'title': 'Elite Will',
      'subText': 'Create your Will with yellow'
    },
    'select': {
      'title': 'Select Will',
      'subText': 'Create your Will with yellow'
    }
  }

  // const assetTransferServices = [ 'yess', 'smart'];

  // const addOns = [ 'shippingAndCourier', 'lawyerCall'];

  // const modules = [ 'SHIPPING_AND_COURIER', 'LAWYER_CALL'];

  useEffect(() => {
    if(currentPlan?.planId === 'starter'){
      if(currentPlan?.modules?.LAWYER_CALL){
        setLawyerCall(currentPlan?.modules?.LAWYER_CALL.useCount);
      }
      if(currentPlan?.modules?.SHIPPING_AND_COURIER){
        setShippingAndCourier(currentPlan?.modules?.SHIPPING_AND_COURIER.useCount);
      }
    }
    else if(currentPlan?.planId === 'pro'){
      if(currentPlan?.modules?.LAWYER_CALL && currentPlanInConfig && 'price' in currentPlanInConfig){
        //@ts-ignore
        if(currentPlan?.modules?.LAWYER_CALL.useCount - currentPlanInConfig?.price?.lawyerCall.number <= 0){
          setLawyerCall(0);
        }else{
          //@ts-ignore
          setLawyerCall(currentPlan?.modules?.LAWYER_CALL.useCount - currentPlanInConfig?.price?.lawyerCall.number);
        }
      }
      if(currentPlan?.modules?.SHIPPING_AND_COURIER && currentPlanInConfig && 'price' in currentPlanInConfig){
        //@ts-ignore
        if(currentPlan?.modules?.SHIPPING_AND_COURIER.useCount - currentPlanInConfig?.price?.shippingAndCourier.number <= 0){
          setShippingAndCourier(0);
        }else{
          //@ts-ignore
          setShippingAndCourier(currentPlan?.modules?.SHIPPING_AND_COURIER.useCount - currentPlanInConfig?.price?.shippingAndCourier.number);
        }
      }
    }
  },[currentPlan, currentPlanInConfig]);

  // useEffect(() => {
  //   if(currentPlan?.modules?.LAWYER_CALL && currentPlanInConfig && 'price' in currentPlanInConfig){
  //     //@ts-ignore
  //     setLawyerCall(currentPlan?.modules?.LAWYER_CALL.useCount - currentPlanInConfig?.price?.lawyerCall.number);
  //   }
  //   if(currentPlan?.modules?.SHIPPING_AND_COURIER && currentPlanInConfig && 'price' in currentPlanInConfig){
  //     //@ts-ignore
  //     setShippingAndCourier(currentPlan?.modules?.SHIPPING_AND_COURIER.useCount - currentPlanInConfig?.price?.shippingAndCourier.number);
  //   }
  // },[currentPlan, currentPlanInConfig]);

  // const shippingAndCourier = currentPlan?.modules?.SHIPPING_AND_COURIER.useCount - currentPlanInConfig?.price?.shippingAndCourier.number;
  // const lawyerCall = currentPlan?.modules?.LAWYER_CALL.useCount - currentPlanInConfig?.price?.lawyerCall.number;

  return (
    <div
      className={`background_white flex flex-grow flex-col gap-24px items-start justify-start ${
        lessThanSmall ? 'w-100 border-box hide_scrollbars pl-24px py-24px' : 'pl-56px pt-60px '
      } `}
    >
      {lessThanSmall ? (
        <Header
          title={'My Account'}
          titleBold={true}
          hideAvatar={true}
          backButton={true}
          backButtonOnClick={closeIconAction}
          backgroundColorClass={'background-transparent'}
        />
      ) : (
        <div
          className={`flex flex-row items center justify-between ${lessThanSmall ? 'pr-24px' : ''}`}
        >
          <Text className='text-gray_900 text-left w-auto font-merriweather' variant={'h6'}>
            My Account
          </Text>

          {lessThanSmall ? (
            <Img src={img_close} className='h-24px w-24px' alt='Icon' onClick={closeIconAction} />
          ) : null}
        </div>
      )}
      <div className='flex flex-col border-separate border-spacing-0-75rem border-left-none '>
        {items.map((item) => {
          // switching through this
          switch (item.type) {
            case 'membership':
              return (
                <div
                  className={`${
                    lessThanSmall ? '' : 'w-100'
                  } flex flex-row items-center mb-10px justify-between`}
                >
                  <div
                    style={{ display: 'flex'}}
                    className={`${
                      lessThanSmall ? '' : 'w-100'
                    } cursor-pointer unselectable flex flex-row ${
                      lessThanSmall ? 'min-h-50px' : 'min-h-70px'
                    } ${
                      lessThanSmall ? 'gap-8px' : 'gap-8px'
                    } flex flex-row items-start justify-start ${
                      !lessThanSmall &&
                      ((currentLink && currentLink === Pages.MY_ACCOUNT) ||
                        location.pathname === Pages.MY_ACCOUNT)
                        ? 'my-account-side-panel-select-tab-border'
                        : ''
                    }`}
                    onClick={() => navigate(Pages.MY_ACCOUNT)}
                  >
                    <Img src={img_icon} className='h-24px w-24px' alt='Icon' />
                    <div className='flex flex-col gap-16px items-start justify-start'>
                      <Text
                        className='font-normal not-italic text-black_900 text-left w-[auto]'
                        as='h7'
                        variant='h7'
                      >
                        My Purchase(s)
                      </Text>
                      {
                        // currentPlan === null ? (
                        //   <ShimmerTitle mode="light" line={1} gap={1} width={100} height={32} />
                        // ) : (
                          currentPlan ? (
                            <div className='flex flex-row gap-8px'>
                              {/* <div className={expired ? 'red-dot' : 'green-dot'} /> */}
                              <img src={checkPlanStatus(endDateStr) === "EXPIRED" ? redDot : checkPlanStatus(endDateStr) === "RENEWAL" ? yellowDot : checkPlanStatus(endDateStr) === "ONGOING" ? greenDot : greenDot} alt="" />
                              <Text className='font-semibold text-gray_900 text-left' variant='body1'>
                                {/* {currentPlanInConfig ? `${currentPlanInConfig.id} Plan` : ''} */}
                                {currentPlanInConfig?.id !== undefined
                                  ? cartPlanDetails[currentPlanInConfig.id].title
                                  : '...'}
                              </Text>
                            </div>
                          ) : (
                            null
                            // <div />
                          )
                        // )
                      }
                      {/* ns - Add the "ATS" and "Add Ons" dots here  */}
                      {(purchasedPlanIds !== undefined && purchasedPlanIds.includes('YESS') || purchasedPlanIds?.includes('SMART')) ? (
                        <div className='flex flex-row gap-8px'>
                          {/* <div className={expired ? 'red-dot' : 'green-dot'} /> */}
                          <img src={checkPlanStatus(endDateStr) === "EXPIRED" ? redDot : checkPlanStatus(endDateStr) === "RENEWAL" ? yellowDot : checkPlanStatus(endDateStr) === "ONGOING" ? greenDot : greenDot} alt="" />
                          <Text className='font-semibold text-gray_900 text-left' variant='body1'>
                            {/* {currentPlanInConfig ? `${currentPlanInConfig.id} Plan` : ''} */}
                            Asset Transfer Services
                          </Text>
                        </div>
                      ) : (
                        null
                        // <div />
                      )}
                      {(lawyerCall || shippingAndCourier) ? (
                        <div className='flex flex-row gap-8px'>
                          {/* <div className={expired ? 'red-dot' : 'green-dot'} /> */}
                          <img src={checkPlanStatus(endDateStr) === "EXPIRED" ? redDot : checkPlanStatus(endDateStr) === "RENEWAL" ? yellowDot : checkPlanStatus(endDateStr) === "ONGOING" ? greenDot : greenDot} alt="" />
                          <Text className='font-semibold text-gray_900 text-left' variant='body1'>
                            {/* {currentPlanInConfig ? `${currentPlanInConfig.id} Plan` : ''} */}
                            Add-ons
                          </Text>
                        </div>
                      ) : (
                        null
                        // <div />
                      )}
                    </div>
                  </div>
                  {lessThanSmall ? (
                    <img src={img_arrowdown} className='-rotate-90 h-24px w-24px px-8px' />
                  ) : null}
                </div>
              );
            default:
              return (
                <div
                  className='w-100 flex flex-row items-center mb-10px justify-between'
                  style={{ padding: '8px 0px -5px 8px' }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={`
                    ${lessThanSmall ? '' : 'w-100'}
                    ${item.comingSoon ? 'cursor-not-allowed' : 'cursor-pointer'} unselectable ${
                      lessThanSmall ? 'min-h-40px' : 'min-h-70px'
                    } ${lessThanSmall ? 'gap-8px' : 'gap-8px'}  ${
                      !lessThanSmall &&
                      ((currentLink && currentLink === item.navigateTo) ||
                        location.pathname === item.navigateTo)
                        ? 'my-account-side-panel-select-tab-border'
                        : ''
                    }`}
                    onClick={() => item.navigateTo && navigate(item.navigateTo)}
                  >
                    <div className='gap-8px flex flex-row items-center justify-start'>
                      <Img src={item.icon} className='h-24px w-24px' alt='home' />
                      <Text
                        className='font-normal not-italic text-black_900 text-left w-[auto]'
                        as='h7'
                        variant='h7'
                      >
                        {item.label}
                        {item.comingSoon && (
                          <>
                            {' '}
                            <Text
                              className='ml-[6px] mr-[34px] not-italic text-gray_900_70 text-left w-[auto]'
                              variant='body2'
                            >
                              (COMING SOON)
                            </Text>
                          </>
                        )}
                      </Text>
                    </div>
                  </div>
                  {lessThanSmall ? (
                    <img src={img_arrowdown} className='-rotate-90 h-24px w-24px px-8px' />
                  ) : null}
                </div>
              );
          }
          return null;
        })}
      </div>
    </div>
  );
};
