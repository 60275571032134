import { Avatar, Grid } from '@mui/material';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
// import { ExistingBeneficiary } from 'Pages/MyWill/CreateWill/Steps/AssetsManagement';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectActiveWill, selectUserContacts, selectUserProfile } from 'store/user/selectors';
import CopySnackBar from './CopySnackBar';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Contact, MyRolesInput, SecondaryBeneficiaryTypes } from 'utils/types';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import { INVITE_MY_ASSIGNEE } from 'common/queries';
import to from 'await-to-js';
import { runMutation } from 'utils/graphQL';

const ContactClassCardWrapper = styled(Grid)`
  border-radius: 12px;
  margin: 12px 0px;

  .social-icons {
    margin: 0px 6px;
    cursor: pointer;
  }
  .MuiGrid-item {
    height: 60px;

    display: flex;
    align-items: center;
  }

  .menuIcon {
    height: 25px;
    object-fit: contain;
  }

  .contactInfo {
    margin-left: 20px;
    .name {
      margin-bottom: 5px;
      font-size: 14px;
    }
    .relation {
      font-size: 12px;
      color: rgba(16, 24, 40, 0.44);
    }
  }

  .receives {
    font-size: 14px;
    color: rgba(16, 24, 40, 0.44);
  }

  .edit_actions {
    cursor: default;
    position: absolute;
    bottom: -60px;
    right: 0;
    z-index: 1;

    background-color: #fcfcfc;
    box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
    width: 300px;
    height: 85px;
    border-radius: 8px;

    .action {
      cursor: pointer;
      width: 80%;
      margin: 0px auto;

      display: flex;
      align-items: center;

      img {
        height: 20px;
        object-fit: contain;
        margin-right: 15px;
      }
    }
  }
`;

const ShowPeople = ({}: {}) => {
  const [showEditActions, setShowEditActions] = useState(false);

  const userActiveWill = useAppSelector(selectActiveWill);
  const userProfile = useAppSelector(selectUserProfile);
  const userContacts = useAppSelector(selectUserContacts);
  const dispatch = useTypedDispatch();
  const [isSnackbarOpen, setIsSnackBarOpen] = useState<boolean>(false);
  // const [guardianDetails, setGuardianDetails] = useState<Contact>();
  // const [executorDetails, setExecutorDetails] = useState<Contact>();
  const [allPeople, setAllPeople] = useState<Contact[]>([]);

  // const getPrimaryDistributionBeneficiaries = () => {
  //   let primaryBeneficiaries: Contact[] = [];
  //   if (
  //     userContacts &&
  //     userContacts.length > 0 &&
  //     userActiveWill &&
  //     userActiveWill.assetDistribution
  //   ) {
  //     let beneficiaryIds: string[] = [];
  //     userActiveWill.assetDistribution.map((each) => {
  //       each.distribution.map((eachDistribution) => {
  //         if (!beneficiaryIds.includes(eachDistribution.beneficiaryId)) {
  //           beneficiaryIds.push(eachDistribution.beneficiaryId);
  //         }
  //       });
  //     });
  //     beneficiaryIds.map((eachId) => {
  //       let contactDetails = userContacts.find((contact) => contact.documentId === eachId);
  //       if (contactDetails) primaryBeneficiaries.push(contactDetails);
  //     });
  //   }
  //   return primaryBeneficiaries;
  // };

  // const getResiduaryAssetBeneficiaries = () => {
  //   let residuaryBeneficiaries: Contact[] = [];
  //   if (
  //     userContacts &&
  //     userContacts.length > 0 &&
  //     userActiveWill &&
  //     userActiveWill.residuaryDistribution
  //   ) {
  //     userActiveWill.residuaryDistribution.distribution.map((eachDistribution) => {
  //       const filteredContact = userContacts.filter(
  //         (contact) => contact.documentId === eachDistribution.beneficiaryId,
  //       );
  //       if (filteredContact.length > 0) {
  //         if (filteredContact[0].relationship !== 'CHILD')
  //           residuaryBeneficiaries.push(filteredContact[0]);
  //       }
  //     });
  //   }
  //   return residuaryBeneficiaries;
  // };

  const inviteMyAssignee = async (myRolesInput: MyRolesInput): Promise<boolean> => {
    const {
      data: { inviteMyAssignee: isInviteSuccess },
    } = (
      await to(
        runMutation({
          mutation: INVITE_MY_ASSIGNEE,
          variables: {
            key: 'input',
            value: myRolesInput,
          },
        }),
      )
    )[1];
    return isInviteSuccess;
  };

  const getContactDetailsById = (id: string) => {
    return userContacts.find((contact) => contact.documentId === id);
  };

  const getContactDetailsByIds = (ids: string[]) => {
    let contactDetails: Array<Contact> = [];
    for (let i = 0; i < ids.length; i++) {
      if (getContactDetailsById(ids[i]))
        contactDetails.push(getContactDetailsById(ids[i]) as Contact);
    }
    return contactDetails;
  };

  const getAssetDetailsFromId = (id: string) => {
    return userActiveWill?.assets!!.find((each) => each.documentId === id);
  };

  const extractAllPeopleFromWill = () => {
    let allPeople: string[] = [];
    let primaryBeneficiaryIds: string[] = [];

    // * Primary Distribution
    if (userActiveWill && userActiveWill.assetDistribution) {
      userActiveWill.assetDistribution.map((each) => {
        each.distribution.map((eachDistribution) => {
          if (!primaryBeneficiaryIds.includes(eachDistribution.beneficiaryId))
            primaryBeneficiaryIds.push(eachDistribution.beneficiaryId);
          if (!allPeople.includes(eachDistribution.beneficiaryId)) {
            allPeople.push(eachDistribution.beneficiaryId);
          }
        });
      });
    }

    // * Secondary Beneficiary

    if (primaryBeneficiaryIds.length > 0) {
      const contactDetail: Contact[] = getContactDetailsByIds(primaryBeneficiaryIds);
      contactDetail.map((contact) => {
        if (
          contact.secondaryBeneficiary &&
          contact.secondaryBeneficiary.type === SecondaryBeneficiaryTypes.CUSTOM &&
          contact.secondaryBeneficiary.customSplit &&
          contact.secondaryBeneficiary.customSplit.distribution
        ) {
          let secondaryDistribution = contact.secondaryBeneficiary.customSplit.distribution;
          secondaryDistribution.map((eachSecondaryDistribution) => {
            if (!allPeople.includes(eachSecondaryDistribution.beneficiaryId)) {
              allPeople.push(eachSecondaryDistribution.beneficiaryId);
            }
          });
        }
      });
    }

    // * Residuary Distribution People
    if (userActiveWill && userActiveWill.residuaryDistribution) {
      userActiveWill.residuaryDistribution.distribution.map((eachDistribution) => {
        if (!allPeople.includes(eachDistribution.beneficiaryId)) {
          allPeople.push(eachDistribution.beneficiaryId);
        }
      });
    }

    // * Guardian
    if (userActiveWill && userActiveWill.guardians && userActiveWill.guardians.guardian) {
      if (!allPeople.includes(userActiveWill.guardians.guardian))
        allPeople.push(userActiveWill.guardians.guardian);
    }

    // * Backup Guarians
    if (userActiveWill && userActiveWill.backupGuardians) {
      userActiveWill.backupGuardians.map((eachBackupGuardian) => {
        if (!allPeople.includes(eachBackupGuardian)) allPeople.push(eachBackupGuardian);
      });
    }

    // * Executor
    if (userActiveWill && userActiveWill.executor) {
      if (!allPeople.includes(userActiveWill.executor)) allPeople.push(userActiveWill.executor);
    }

    // * Backup Executor
    if (userActiveWill && userActiveWill.backupExecutors) {
      userActiveWill.backupExecutors.map((eachBackupExecutor) => {
        if (!allPeople.includes(eachBackupExecutor)) allPeople.push(eachBackupExecutor);
      });
    }

    // * Witnesses
    if (userActiveWill && userActiveWill.witnesses) {
      userActiveWill.witnesses.map((witness) => {
        if (!allPeople.includes(witness)) allPeople.push(witness);
      });
    }

    // * Nominees
    if (userActiveWill?.assetDistribution) {
      let distributions = userActiveWill.assetDistribution;
      for (let i = 0; i < distributions.length; i++) {
        let assetDetails = getAssetDetailsFromId(distributions[i].assetId!!);
        if (assetDetails && assetDetails.nominees) {
          assetDetails.nominees.map((nominee) => {
            if (!allPeople.includes(nominee.contactId)) allPeople.push(nominee.contactId);
          });
        }
      }
    }

    return allPeople;
  };

  const inviteContact = async (contact: Contact) => {
    const isRoleInvited = await inviteMyAssignee({
      assigneePhoneNumber: contact?.phoneNumber ?? '',
      assignorName: userActiveWill?.testatorDetails?.fullName ?? '',
      assignorUserId: userActiveWill?.testatorDetails?.userId ?? '',
    });
    if (!isRoleInvited) {
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Failed to invite',
      };
      dispatch(showToast(toast));
    }
  };

  useEffect(() => {
    // if (userActiveWill?.guardians?.guardian) {
    //   let contactDetails = getContactDetailsById(userActiveWill.guardians.guardian);
    //   if (contactDetails) {
    //     setGuardianDetails(contactDetails);
    //   }
    // }
    // if (userActiveWill?.executor) {
    //   let contactDetails = getContactDetailsById(userActiveWill?.executor as string);
    //   if (contactDetails) {
    //     setExecutorDetails(contactDetails);
    //   }
    // }
    let allPeople = extractAllPeopleFromWill();
    let allPeopleDetails: Contact[] = getContactDetailsByIds(allPeople);
    setAllPeople(allPeopleDetails);
    console.log('All People', allPeople, allPeople.length);
  }, [userActiveWill]);
  return (
    <ContactClassCardWrapper>
      {allPeople &&
        allPeople.map((contact) => (
          <Grid lg={12} sm={12} md={12}>
            <Grid
              style={{ margin: '8px 0px' }}
              alignItems={'center'}
              justifyContent='space-between'
              display={'flex'}
              lg={12}
              sm={12}
              md={12}
            >
              <Grid alignItems={'center'} display={'flex'}>
                <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                  {contact!!.fullName[0]}
                </Avatar>
                <Grid item className='contactInfo'>
                  <div>
                    <p className='name font-semi-bold'>{contact!!.fullName}</p>
                    <p className='relation'>{contact!!.roles.join(', ')}</p>
                  </div>
                </Grid>
              </Grid>
              <Grid
                onClick={() => setShowEditActions(!showEditActions)}
                alignItems={'end'}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <MailOutlineIcon
                  className='social-icons'
                  onClick={async () => {
                    if (contact?.email) {
                      await inviteContact(contact);
                      Mixpanel.track('Person Notified', {
                        Method: 'Email',
                        "Person's Role": contact?.roles.join(', '),
                      });
                      Intercom.track('Person Notified', {
                        Method: 'Email',
                        "Person's Role": contact?.roles.join(', '),
                      });
                      (window as any).webengage.track("Person Notified", {
                        Method: 'Email',
                        "Person's Role": contact?.roles.join(', '),
                      }
                      );
                      setTimeout(function () {
                        window.location.href = `mailto:${
                          contact?.email
                        }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                          contact?.phoneNumber
                        }. https://getyellow.page.link/yellow-app%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                          userProfile?.fullName ? userProfile.fullName : ''
                        }`;
                        console.log('User Name', userProfile?.fullName);
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message:
                          'Email not added, kindly add email to this beneficiary and proceed',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />
                <WhatsAppIcon
                  className='social-icons'
                  onClick={async () => {
                    if (contact?.phoneNumber) {
                      await inviteContact(contact);
                      Mixpanel.track('Person Notified', {
                        Method: 'Whatsapp',
                        "Person's Role": contact?.roles.join(', '),
                      });
                      Intercom.track('Person Notified', {
                        Method: 'Whatsapp',
                        "Person's Role": contact?.roles.join(', '),
                      });
                      (window as any).webengage.track("Person Notified", {
                        Method: 'Whatsapp',
                        "Person's Role": contact?.roles.join(', '),
                      }
                      );
                      setTimeout(() => {
                        window.open(
                          `https://wa.me/${contact?.phoneNumber}/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${contact?.phoneNumber}. https://getyellow.page.link/yellow-app`,
                        );
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Phone Number not available for this user',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />

                <ContentCopyIcon
                  className='social-icons'
                  onClick={async () => {
                    await inviteContact(contact);
                    Mixpanel.track('Person Notified', {
                      Method: 'Copy text',
                      "Person's Role": contact?.roles.join(', '),
                    });
                    Intercom.track('Person Notified', {
                      Method: 'Copy text',
                      "Person's Role": contact?.roles.join(', '),
                    });
                    (window as any).webengage.track("Person Notified", {
                      Method: 'Copy text',
                      "Person's Role": contact?.roles.join(', '),
                    }
                    );
                    setIsSnackBarOpen(true);
                    navigator.clipboard.writeText(
                      `Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${contact?.phoneNumber}. https://getyellow.page.link/yellow-app`,
                    );
                  }}
                />
                <CopySnackBar
                  open={isSnackbarOpen}
                  closeSnackBar={() => setIsSnackBarOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      {/* {userActiveWill && userActiveWill?.guardians && guardianDetails && (
        <Grid lg={12} sm={12} md={12}>
          <Grid
            style={{ margin: '8px 0px' }}
            alignItems={'center'}
            justifyContent='space-between'
            display={'flex'}
            lg={12}
            sm={12}
            md={12}
          >
            <Grid alignItems={'center'} display={'flex'}>
              <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                {guardianDetails!!.fullName[0]}
              </Avatar>
              <Grid item className='contactInfo'>
                <div>
                  <p className='name font-semi-bold'>{guardianDetails!!.fullName}</p>
                  <p className='relation'>{guardianDetails!!.roles.join(', ')}</p>
                </div>
              </Grid>
            </Grid>
            <Grid
              onClick={() => setShowEditActions(!showEditActions)}
              alignItems={'end'}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <MailOutlineIcon
                className='social-icons'
                onClick={() => {
                  if (guardianDetails?.email) {
                    setTimeout(function () {
                      window.location.href = `mailto:${
                        guardianDetails?.email
                      }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                        guardianDetails?.phoneNumber
                      }. https://play.google.com/store/apps/details?id=com.getyellow.app%3A%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                        userProfile?.fullName ? userProfile.fullName : ''
                      }`;
                      console.log('User Name', userProfile?.fullName);
                    }, 0);
                  } else {
                    const toast: ToastMessage = {
                      type: ToastType.ERROR,
                      message: 'Email not added, kindly add email to this beneficiary and proceed',
                    };
                    dispatch(showToast(toast));
                  }
                }}
              />
              <WhatsAppIcon
                className='social-icons'
                onClick={() => {
                  if (guardianDetails?.phoneNumber) {
                    setTimeout(() => {
                      window.open(
                        `https://wa.me/${guardianDetails?.phoneNumber}/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${guardianDetails?.phoneNumber}. https://play.google.com/store/apps/details?id=com.getyellow.app`,
                      );
                    }, 0);
                  } else {
                    const toast: ToastMessage = {
                      type: ToastType.ERROR,
                      message: 'Phone Number not available for this user',
                    };
                    dispatch(showToast(toast));
                  }
                }}
              />

              <ContentCopyIcon
                className='social-icons'
                onClick={() => {
                  setIsSnackBarOpen(true);
                  navigator.clipboard.writeText(
                    `https://play.google.com/store/apps/details?id=com.getyellow.app`,
                  );
                }}
              />
              <CopySnackBar open={isSnackbarOpen} closeSnackBar={() => setIsSnackBarOpen(false)} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {userActiveWill && userActiveWill?.executor && executorDetails && (
        <Grid lg={12} sm={12} md={12}>
          <Grid
            style={{ margin: '8px 0px' }}
            alignItems={'center'}
            justifyContent='space-between'
            display={'flex'}
            lg={12}
            sm={12}
            md={12}
          >
            <Grid alignItems={'center'} display={'flex'}>
              <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                {executorDetails!!.fullName[0]}
              </Avatar>
              <Grid item className='contactInfo'>
                <div>
                  <p className='name font-semi-bold'>{executorDetails!!?.fullName}</p>
                  <p className='relation'>{executorDetails!!?.roles.join(', ')}</p>
                </div>
              </Grid>
            </Grid>
            <Grid
              onClick={() => setShowEditActions(!showEditActions)}
              alignItems={'end'}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <MailOutlineIcon
                className='social-icons'
                onClick={() => {
                  if (executorDetails!!?.phoneNumber) {
                    setTimeout(function () {
                      window.location.href = `mailto:${
                        executorDetails!!?.email
                      }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                        executorDetails!!?.phoneNumber
                      }. https://play.google.com/store/apps/details?id=com.getyellow.app%3A%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                        userProfile?.fullName ? userProfile.fullName : ''
                      }`;
                      console.log('User Name', userProfile?.fullName);
                    }, 0);
                  } else {
                    const toast: ToastMessage = {
                      type: ToastType.ERROR,
                      message: 'Email not added, kindly add email to this executor and proceed',
                    };
                    dispatch(showToast(toast));
                  }
                }}
              />
              <WhatsAppIcon
                className='social-icons'
                onClick={() => {
                  if (executorDetails!!?.phoneNumber) {
                    setTimeout(() => {
                      window.open(
                        `https://wa.me/${
                          executorDetails!!?.phoneNumber
                        }/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                          executorDetails!!?.phoneNumber
                        }. https://play.google.com/store/apps/details?id=com.getyellow.app`,
                      );
                    }, 0);
                  } else {
                    const toast: ToastMessage = {
                      type: ToastType.ERROR,
                      message: 'Phone Number not available for this user',
                    };
                    dispatch(showToast(toast));
                  }
                }}
              />

              <ContentCopyIcon
                className='social-icons'
                onClick={() => {
                  setIsSnackBarOpen(true);
                  navigator.clipboard.writeText(
                    `https://play.google.com/store/apps/details?id=com.getyellow.app`,
                  );
                }}
              />
              <CopySnackBar open={isSnackbarOpen} closeSnackBar={() => setIsSnackBarOpen(false)} />
            </Grid>
          </Grid>
        </Grid>
      )}

      {userActiveWill &&
        userActiveWill?.witnesses &&
        getContactDetailsByIds(userActiveWill.witnesses).map((witness) => (
          <Grid lg={12} sm={12} md={12}>
            <Grid
              style={{ margin: '8px 0px' }}
              alignItems={'center'}
              justifyContent='space-between'
              display={'flex'}
              lg={12}
              sm={12}
              md={12}
            >
              <Grid alignItems={'center'} display={'flex'}>
                <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                  {witness.fullName[0]}
                </Avatar>
                <Grid item className='contactInfo'>
                  <div>
                    <p className='name font-semi-bold'>{witness?.fullName}</p>
                    <p className='relation'>{witness?.roles.join(', ')}</p>
                  </div>
                </Grid>
              </Grid>
              <Grid
                onClick={() => setShowEditActions(!showEditActions)}
                alignItems={'end'}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <MailOutlineIcon
                  className='social-icons'
                  onClick={() => {
                    if (witness?.email) {
                      setTimeout(function () {
                        window.location.href = `mailto:${
                          witness?.email
                        }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                          witness?.phoneNumber
                        }. https://play.google.com/store/apps/details?id=com.getyellow.app%3A%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                          userProfile?.fullName ? userProfile.fullName : ''
                        }`;
                        console.log('User Name', userProfile?.fullName);
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Email not added, kindly add email to this witness and proceed',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />
                <WhatsAppIcon
                  className='social-icons'
                  onClick={() => {
                    if (witness?.phoneNumber) {
                      setTimeout(() => {
                        window.open(
                          `https://wa.me/${witness?.phoneNumber}/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${witness?.phoneNumber}. https://play.google.com/store/apps/details?id=com.getyellow.app`,
                        );
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Phone Number not available for this user',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />

                <ContentCopyIcon
                  className='social-icons'
                  onClick={() => {
                    setIsSnackBarOpen(true);
                    navigator.clipboard.writeText(
                      `https://play.google.com/store/apps/details?id=com.getyellow.app`,
                    );
                  }}
                />
                <CopySnackBar
                  open={isSnackbarOpen}
                  closeSnackBar={() => setIsSnackBarOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

      {getPrimaryDistributionBeneficiaries().length > 0 &&
        getPrimaryDistributionBeneficiaries().map((beneficiary) => (
          <Grid lg={12} sm={12} md={12}>
            <Grid
              style={{ margin: '8px 0px' }}
              alignItems={'center'}
              justifyContent='space-between'
              display={'flex'}
              lg={12}
              sm={12}
              md={12}
            >
              <Grid alignItems={'center'} display={'flex'}>
                <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                  {beneficiary.fullName[0]}
                </Avatar>
                <Grid item className='contactInfo'>
                  <div>
                    <p className='name font-semi-bold'>{beneficiary?.fullName}</p>
                    <p className='relation'>{beneficiary?.roles.join(', ')}</p>
                  </div>
                </Grid>
              </Grid>
              <Grid
                onClick={() => setShowEditActions(!showEditActions)}
                alignItems={'end'}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <MailOutlineIcon
                  className='social-icons'
                  onClick={() => {
                    if (beneficiary?.email) {
                      setTimeout(function () {
                        window.location.href = `mailto:${
                          beneficiary?.email
                        }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                          beneficiary?.phoneNumber
                        }. https://play.google.com/store/apps/details?id=com.getyellow.app%3A%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                          userProfile?.fullName ? userProfile.fullName : ''
                        }`;
                        console.log('User Name', userProfile?.fullName);
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message:
                          'Email not added, kindly add email to this beneficiary and proceed',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />
                <WhatsAppIcon
                  className='social-icons'
                  onClick={() => {
                    if (beneficiary?.phoneNumber) {
                      setTimeout(() => {
                        window.open(
                          `https://wa.me/${beneficiary?.phoneNumber}/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${beneficiary?.phoneNumber}. https://play.google.com/store/apps/details?id=com.getyellow.app`,
                        );
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Phone Number not available for this user',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />

                <ContentCopyIcon
                  className='social-icons'
                  onClick={() => {
                    setIsSnackBarOpen(true);
                    navigator.clipboard.writeText(
                      `https://play.google.com/store/apps/details?id=com.getyellow.app`,
                    );
                  }}
                />
                <CopySnackBar
                  open={isSnackbarOpen}
                  closeSnackBar={() => setIsSnackBarOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

      {getResiduaryAssetBeneficiaries().length > 0 &&
        getResiduaryAssetBeneficiaries().map((beneficiary) => (
          <Grid lg={12} sm={12} md={12}>
            <Grid
              style={{ margin: '8px 0px' }}
              alignItems={'center'}
              justifyContent='space-between'
              display={'flex'}
              lg={12}
              sm={12}
              md={12}
            >
              <Grid alignItems={'center'} display={'flex'}>
                <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>
                  {beneficiary.fullName[0]}
                </Avatar>
                <Grid item className='contactInfo'>
                  <div>
                    <p className='name font-semi-bold'>{beneficiary?.fullName}</p>
                    <p className='relation'>{beneficiary?.roles.join(', ')}</p>
                  </div>
                </Grid>
              </Grid>
              <Grid
                onClick={() => setShowEditActions(!showEditActions)}
                alignItems={'end'}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <MailOutlineIcon
                  className='social-icons'
                  onClick={() => {
                    if (beneficiary?.email) {
                      setTimeout(function () {
                        window.location.href = `mailto:${
                          beneficiary?.email
                        }?subject=Regarding the Will&body=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${
                          beneficiary?.phoneNumber
                        }. https://play.google.com/store/apps/details?id=com.getyellow.app%3A%0D%0A%0D%0AThanks and Regards%2C%0D%0A${
                          userProfile?.fullName ? userProfile.fullName : ''
                        }`;
                        console.log('User Name', userProfile?.fullName);
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message:
                          'Email not added, kindly add email to this beneficiary and proceed',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />
                <WhatsAppIcon
                  className='social-icons'
                  onClick={() => {
                    if (beneficiary?.phoneNumber) {
                      setTimeout(() => {
                        window.open(
                          `https://wa.me/${beneficiary?.phoneNumber}/?text=Hi, I have added you to an important role in my Will. To view and accept, please sign in to the Yellow app with your phone number ${beneficiary?.phoneNumber}. https://play.google.com/store/apps/details?id=com.getyellow.app`,
                        );
                      }, 0);
                    } else {
                      const toast: ToastMessage = {
                        type: ToastType.ERROR,
                        message: 'Phone Number not available for this user',
                      };
                      dispatch(showToast(toast));
                    }
                  }}
                />

                <ContentCopyIcon
                  className='social-icons'
                  onClick={() => {
                    setIsSnackBarOpen(true);
                    navigator.clipboard.writeText(
                      `https://play.google.com/store/apps/details?id=com.getyellow.app`,
                    );
                  }}
                />
                <CopySnackBar
                  open={isSnackbarOpen}
                  closeSnackBar={() => setIsSnackBarOpen(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))} */}

      {/* {[1, 2, 3].map((each) => (
        <Grid key={each} lg={12} sm={12} md={12}>
          <Grid
            style={{ margin: '8px 0px' }}
            alignItems={'center'}
            justifyContent='space-between'
            display={'flex'}
            lg={12}
            sm={12}
            md={12}
          >
            <Grid alignItems={'center'} display={'flex'}>
              <Avatar sx={{ height: '50px', width: '50px', margin: '0 auto' }}>K</Avatar>
              <Grid item className='contactInfo'>
                <div>
                  <p className='name font-semi-bold'>Kalyan</p>
                  <p className='relation'>CHILD, BENEFICIARY, GUARDIAN</p>
                </div>
              </Grid>
            </Grid>
            <Grid
              onClick={() => setShowEditActions(!showEditActions)}
              alignItems={'end'}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <MailOutlineIcon className='social-icons' />
              <WhatsAppIcon className='social-icons' />
              <ContentCopyIcon className='social-icons' />
            </Grid>
          </Grid>
        </Grid>
      ))} */}
    </ContactClassCardWrapper>
  );
};

export default ShowPeople;
