import { useAppSelector } from 'store/hooks';
import { selectUserStatus } from 'store/user/selectors';
import { UserStatus } from 'store/user/slice';
import { runMutation } from 'utils/graphQL';
import { GENERATE_PREVIEW_OF_WILL } from './queries';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import { debounce } from 'debounce';

export const useGenerateWill = () => {
  const userStatus = useAppSelector(selectUserStatus);
  return {
    generateWill: debounce(
      async (willId: string) => {
        if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) {
          console.log('Will creation incomplete');
          return;
        }

        console.log('Generate Will Pressed');
        try {
          const response = await runMutation({
            mutation: GENERATE_PREVIEW_OF_WILL,
            variables: {
              key: 'input',
              value: {
                willId,
                forPreview: true,
              },
            },
          });
          Mixpanel.track('Will Generated');
          Intercom.track('Will Generated');
          (window as any).webengage.track("Generate Will Clicked",{
            "Will Generated": "yes"
          });
          (window as any).webengage.user.setAttribute("Will Generated", true);
          console.log({ response });
          return response;
        } catch (exception) {
          console.log({ exception });
          return;
        }
      },
      10000,
      true,
    ),
  };
};
