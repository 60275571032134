import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import { AuthSlice, DEFAULT_ROLE, RequestLoader } from '../../utils/types';

export enum UserStatus {
  DETERMINING_AUTH_STATUS,
  LOGGED_IN,
  LOGGED_OUT,
  PROFILE_LOADED,
  CONFIG_LOADED,
  // YELLOW_PLUS_CONFIG_LOADED,
  PROFILE_INCOMPLETE,
  WILL_CREATION_INCOMPLETE,
  WILL_DOC_NOT_CREATED,
  PAYMENT_INCOMPLETE,
  FIRST_WILL_COMPLETED,
  YELLOW_PLUS_PLAN_LOADED,
  ACTION_SET_PROFILE_COMPLETED,
  ACTION_SET_WILL_DOC_CREATED,
  ACTION_SET_PAYMENT_COMPLETED,
  ACTION_SET_WILL_CREATION_COMPLETED,
  ACTION_SET_YELLOW_PLUS_PLAN_LOADING,
  ACTION_SET_PROFILE_NOT_LOADED,
}

export const initialState: AuthSlice = {
  authLoading: true,
  authUser: null,
  requestStatus: RequestLoader.NOT_STARTED,
  userProfile: null,
  isUserProfileLoaded: false,
  userContacts: [],
  content: null,
  userAssets: [],
  userLiabilities: [],
  will: null,
  accessRole: DEFAULT_ROLE,
  remainingProfileFields: [],
  config: null,
  yellowPlusConfig: {},
  isConfigLoading: false,
  showGuardianSection: true,
  userStatus: [UserStatus.DETERMINING_AUTH_STATUS],
  unloggedInUserSessionData: {},
  loggedOutConfig: null,
  yellowMembershipConfig: {},
  yellowCartData: {},
  yellowPaymentSummaryData: {},
  currentUserPlansResponse: {},
  paymentHistoryResponse: {},
  willRecipientMailSlice: {},
  willRecipients: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: reducers,
});

export const {
  initiateAuthFetch,
  addToUserStatus,
  setAuth,
  setAuthLoadingComplete,
  initiateProfileFetch,
  setProfile,
  setContent,
  setAuthFailed,
  updateProfile,
  updateUnloggedInUserSessionData,
  updateYellowPaymentSummaryData,
  updateActiveWill,
  setLoggedOutConfig,
  // addContact,
  // deleteContact,
  updateContactsList,
  addAsset,
  deleteAssset,
  setConfigLoadingStatus,
  setShowGuardianSection,
  updatePaymentInfo,
  setContacts,
  setYellowPlusConfig,
  setYellowPlusCart,
  setAddOnsForOthers,
  setAddOns,
  setYellowPlusCartPromocode,
  removeAllCartItems,
  setCurrentUserPlans,
  setPaymentHistory,
  invalidatePaymentData,
  sendWillTo,
  updateSendWillRequest,
  updatePreviousWillRecipients,
  updateWillRecipients,
  updateUserNotifications,
  showAddEvenForOthers,
} = slice.actions;

export const fetchYellowPlusConfig = () => ({
  type: 'FETCH_YELLOW_PLUS_CONFIG',
});
export const fetchYellowMembershipConfig = () => ({
  type: 'FETCH_YELLOW_MEMBERSHIP_CONFIG',
});

export const fetchYellowPlusCart = () => ({
  type: 'FETCH_YELLOW_PLUS_CART',
});

export const fetchYellowPaymentHistoryConfig = () => ({
  type: 'FETCH_YELLOW_PAYMENT_HISTORY_CONFIG',
});

export const fetchUserProfileAction = (payload?: any, resolve?: any, reject?: any) => ({
  type: 'FETCH_USER_PROFILE',
  payload,
  meta: { resolve, reject },
});

export const fetchCurrentUserPlans = (payload?: any, resolve?: any, reject?: any) => ({
  type: 'FETCH_CURRENT_USER_PLANS',
  payload,
  meta: { resolve, reject },
});

export const fetchLoggedOutConfig = () => ({
  type: 'FETCH_LOGGED_OUT_CONFIG',
});

export const getPaymentHistory = () => ({
  type: 'GET_PAYMENT_HISTORY',
});
export const initiateCart = () => ({
  type: 'INITIATE_CART',
});

export const fetchContentAction = () => ({
  type: 'FETCH_CONTENT',
});

export const fetchContactsAction = () => ({
  type: 'FETCH_CONTACTS',
});

export const fetchConfig = () => ({
  type: 'user/setAuthLoadingComplete',
});

export const getWillMailRecipients = () => ({
  type: 'GET_WILL_MAIL_RECIPIENTS',
});

export const startSendWillRequest = () => ({
  type: 'SEND_WILL',
});

export const fetchWillRecipients = () => ({
  type: 'FETCH_WILL_RECIPIENTS',
});

export const addWillRecipient = (payload: any) => ({
  type: 'ADD_WILL_RECIPIENT',
  payload,
});

export const fetchUserNotifications = () => ({
  type: 'FETCH_USER_NOTIFICATIONS'
});

// export const fetchGuardianShowStatus = () => ({
//   type: 'FETCH_GUARDIAN_SHOW_STATUS'
// })

export default slice.reducer;
