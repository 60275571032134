import { Avatar, Grid } from '@mui/material';

import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import yellowLogo from 'assets/svg/yellowLogo.svg';
import yellowAckoLogo from 'assets/images/yellowacko.png';
import CustomButton from 'common/Reusables/Button';
import { selectActiveWill, selectAuthUser, selectUserProfile } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MuiDrawer from 'common/Reusables/Drawer';
import Menu from './Menu';
// import downarrow from '../../assets/svg/arrow.svg';
// import { useDetectClickOutside } from 'react-detect-click-outside';
import { breakpoints } from 'styles/Breakpoints';
import { Pages } from 'routes';
// import { selectAuthStatus } from 'store/auth/slice';
// import { AuthStatus } from 'store/auth/types';
const NavbarWrapper = styled.div`
  padding: 20px 0px 20px;
  .howItWorks {
    width: 48%;
    height: 50px;
    border: 2px solid ${(props) => props.theme.primaryColor};
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      color: ${(props) => props.theme.primaryColor};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 12px;

      .playBtn {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
  .navItemsHolder {
    padding: 0 40px;
    .logo {
      height: 50px;
      max-height: 50px;
    }
    .nav-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      /* min-width: 100px; */
      flex-wrap: nowrap;
      // margin-right: 30px;
      max-width: fit-content;
      white-space: nowrap;
    }
    .item-menu {
      padding: 0;
    }
    .service-link {
      text-decoration: none;
      color: black;
    }
    /* .service-link:hover {
      color: white;
    } */
    .item-list {
      list-style: none;
      /* padding: 25px; */
      padding-left: 16px;
      padding-top: 16px;
      padding-bottom: 10px;
      font-family: 'General Sans';
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
    .item-list:hover {
      background-color: #1d2939;
      color: #fff;
    }
    .item-list:hover .service-link {
      color: #fff;
    }
    .item-list:hover .item-subtext {
      font-weight: 900 !important;
    }
    .item-subtext {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffd662;
    }
    .down-arrow-img {
      margin-left: 5px;
    }
    .dropdown-container {
      position: absolute;
      top: 24px;
      left: -0.5px;
      z-index: 999;
    }
    .dropdown {
      min-height: 150px;
      width: 244px;
      z-index: 1000 !important;
      background-color: white;
      box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
      border-radius: 4px;
    }

    .navItem {
      padding: 3px;
      color: ${(props) => props.theme.primaryColor};
    }
    .navActive {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
    }
    .line {
      display: none;
    }
    .service-container:hover .line {
      display: block;
      margin-top: 3px;
      height: 3px;
      background-color: #1d2939;
      border: none;
    }

    .service-container-spacing {
      height: 26px;
      padding: 3px;
      position: relative;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .navItemsHolder {
      padding: 0 40px;
    }
  }
`;

enum ACTIVE_STATES {
  HOME = 'HOME',
  PRODUCTS = 'PRODUCTS',
  ABOUT_US = 'ABOUT_US',
  FAQ = 'FAQ',
  BLOG = 'BLOG',
  MY_WILL = 'MY_WILL',
  SERVICES = 'SERVICES',
}

const Navbar = ({
  backgroundColorClass = 'background_lightBeige',
}: {
  backgroundColorClass?: string;
}) => {
  const authUser = useAppSelector(selectAuthUser);
  // const authStatus = useAppSelector(selectAuthStatus);
  const userProfile = useAppSelector(selectUserProfile);
  const location = useLocation();
  // const [services, setServices] = useState(false);
  const [currentActiveState, setActiveState] = useState<ACTIVE_STATES>(ACTIVE_STATES.HOME);
  const userActiveWill = useAppSelector(selectActiveWill);

  const [showDrawer, setShowDrawer] = useState(false);

  // const closeDropdown = () => {
  //   setServices(false);
  // };
  // const ref = useDetectClickOutside({ onTriggered: closeDropdown });
  useEffect(() => {
    if (location.pathname === '/my-will') setActiveState(ACTIVE_STATES.MY_WILL);
    if (location.pathname === '/generated-will') setActiveState(ACTIVE_STATES.MY_WILL);
    if (location.pathname === '/faq') setActiveState(ACTIVE_STATES.FAQ);
    if (location.pathname === '/blog') setActiveState(ACTIVE_STATES.BLOG);
    if (location.pathname === '/about-us') setActiveState(ACTIVE_STATES.ABOUT_US);
    if (location.pathname === '/products') setActiveState(ACTIVE_STATES.PRODUCTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shouldShowYellowPlusFor = (pathname: string) => {
    switch (pathname) {
      case Pages.MY_WILL:
      case Pages.WILL_EDITOR:
      case Pages.ONBOARDING:
        return true;
    }
    return false;
  };

  const shouldShowForCurrentPath = shouldShowYellowPlusFor(location.pathname);

  let logoToShow = yellowLogo;

  if (userProfile?.externalVendor === "ACKO") {
    logoToShow = yellowAckoLogo;
  }

  // const handleServices = () => {
  //   if (services === false) {
  //     setServices(true);
  //   } else {
  //     setServices(false);
  //   }
  // };

  return (
    <NavbarWrapper className={backgroundColorClass}>
      <Grid
        container
        justifyContent='space-between'
        flexWrap={'wrap'}
        className='navItemsHolder'
      >
        <Grid item lg={5}>
          <a href='' target='_blank'>
            <img
              className={`logo cursor-pointer`}
              src={logoToShow}
              alt='Yellow Logo'
              onClick={() => {
                // if (authStatus !== AuthStatus.LOGGED_IN) {
                if (!authUser) {
                  setTimeout(() => (window.location.href = 'https://getyellow.in/'), 200);
                }
              }}
            />
          </a>
        </Grid>
        {/* <Grid item lg={authStatus === AuthStatus.LOGGED_IN ? 3.5 : 4} className='nav-item'> */}
        <Grid item lg={authUser ? 3.5 : 4} className='nav-item'>
          {/* <NavItem
            title='Products'
            handle=Pages.MY_ACCOUNT
            isActive={currentActiveState === ACTIVE_STATES.PRODUCTS}
          /> */}
          {/* <NavItem
            title='About Us'
            handle='/about-us'
            isActive={currentActiveState === ACTIVE_STATES.ABOUT_US}
          /> */}
          {/* <OutsideClickHandle> */}
          {/* <Grid
            onClick={() => {
              handleServices();
            }}
            ref={ref}
            className='service-container service-container-spacing'
          > */}
          {/* <Grid>
              <NavItem title='Services' handle='' isActive={false} />
              <img src={downarrow} alt='down-arrow' className='down-arrow-img' />
              <hr className='line' />
            </Grid> */}
          {/* <Grid className='dropdown-container'>
              <div className='dropdown' style={{ display: services === true ? 'block' : 'none' }}>
                {' '}
                <ul className='item-menu'>
                  <Link
                    className='service-link'
                    to={authUser && authUser.id ? '/my-will' : '/login'}
                  >
                    <li className='item-list'>Yellow Will</li>
                  </Link>
                  <li className='item-list'>
                    Yellow Trusts <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                  <li className='item-list'>
                    Yellow Claims <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                 
                </ul>
              </div>
            </Grid> */}
          {/* </Grid> */}
          {/* </OutsideClickHandle> */}
          {shouldShowForCurrentPath && (
            <Grid className='navItem'>
              <NavItem
                title={'My Home'}
                handle={(userProfile?.externalVendor === undefined || userProfile.externalVendor === null)? Pages.DASHBOARD : Pages.VENDOR_DASHBOARD}
              // isActive={currentActiveState === ACTIVE_STATES.HOME}
              />
            </Grid>
          )}
          {/* <Grid className='navItem'> */}
          {/*   <NavItem */}
          {/*     title={'Pricing'} */}
          {/*     handle={Pages.YELLOW_PLUS} */}
          {/*     isActive={currentActiveState === ACTIVE_STATES.BLOG} */}
          {/*   /> */}
          {/* </Grid> */}
          {userActiveWill?.paymentInfo ? (
            <NavItem
              title='My Will'
              handle={Pages.MY_WILL}
            // isActive={currentActiveState === ACTIVE_STATES.BLOG}
            />
          ) : null}
          <NavItem
            title='Blog'
            handle='/blog'
            isActive={currentActiveState === ACTIVE_STATES.BLOG}
          />
          <NavItem title='FAQs' handle='/faq' isActive={currentActiveState === ACTIVE_STATES.FAQ} />
          {/* <Grid
            onClick={() => {
              handleServices();
            }}
            ref={ref}
            className='service-container service-container-spacing'
          >
            {/* <Grid>
              <NavItem title='Services' handle='' isActive={false} />
              <img src={downarrow} alt='down-arrow' className='down-arrow-img' />
              <hr className='line' />
            </Grid> */}
          {/* <Grid className='dropdown-container'>
              <div className='dropdown' style={{ display: services === true ? 'block' : 'none' }}>
                {' '}
                <ul className='item-menu'>
                  <Link
                    className='service-link'
                    to={authUser && authUser.id ? '/my-will' : '/login'}
                  >
                    <li className='item-list'>Yellow Will</li>
                  </Link>
                  <li className='item-list'>
                    Yellow Trusts <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                  <li className='item-list'>
                    Yellow Claims <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                 
                </ul>
              </div>
            </Grid> */}
          {/* </Grid> */}
          {/* {authStatus === AuthStatus.LOGGED_IN ? ( */}
          {authUser ? (
            <>
              {/* <div className='howItWorks cursor-pointer'>
                <a
                  className='content'
                  onClick={() => {
                    // navigate(Pages.REGISTER);
                    window.open('https://play.google.com/store/apps/details?id=com.getyellow.app');
                  }}
                >
                  <span>Download the app</span>
                </a>
              </div> */}
              {/* <CustomButton
                text='Download the app'
                className='getStartedCta'
                onClick={() => {
                  // navigate(Pages.REGISTER);
                  window.open('https://play.google.com/store/apps/details?id=com.getyellow.app');
                }}
                hoverColor='#1D2939'
                hoverBackgroundColor='#FFC632'
              /> */}
              <span className='cursor-pointer' onClick={() => setShowDrawer(true)}>
                <Avatar
                  src={userProfile?.profilePicture || ''}
                  sx={{ height: '50px', width: '50px' }}
                />
              </span>
            </>
          ) : (
            <>
              {/* <NavItem title='Sign In' handle='/login' /> */}
              <NavItem title='Create your Will' handle={Pages.LOGIN} isButton />
            </>
          )}
        </Grid>
      </Grid>
      <MuiDrawer
        open={showDrawer}
        hideDrawer={() => setShowDrawer(false)}
        width={'min(100%, 500px)'}
      >
        <Menu hideDrawer={() => setShowDrawer(false)} />
      </MuiDrawer>
    </NavbarWrapper>
  );
};

export default Navbar;

type NavItemProps = {
  title: string;
  handle: string;
  isButton?: boolean;
  isActive?: boolean;
};

const NavItem = ({ title, handle, isButton, isActive }: NavItemProps) => {
  const navigate = useNavigate();

  if (handle === '/blog') {
    return (
      <a className='navItem font-semi-bold' href='https://getyellow.in/resources' target='_blank'>
        {title}
      </a>
    );
  }

  if (handle === Pages.FAQ) {
    return (
      <a className='navItem font-semi-bold' href='https://getyellow.in/faq' target='_blank'>
        {title}
      </a>
    );
  }

  return isButton ? (
    <CustomButton text={title} handle={handle} onClick={() => navigate(handle)}></CustomButton>
  ) : (
    <Link to={handle} className={`${isActive && 'navActive'} navItem font-semi-bold`}>
      {title}
    </Link>
  );
};
