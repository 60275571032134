import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CustomButton from 'common/Reusables/Button';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import theme from 'styles/theme';
import { runMutation } from 'utils/graphQL';
import { CREATE_USER_WILL } from 'common/queries';
import { Will, WillTypes } from 'utils/types';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectActiveWill } from 'store/user/selectors';
import { fetchUserProfileAction } from 'store/user/slice';
// import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(75%, 600px)',
  maxWidth: 'min(75%, 600px)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '32px',
  borderRadius: '10px',
};

export const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
  .howItWorks {
    width: 48%;
    height: 50px;
    border: 2px solid ${theme.primaryColor};
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      color: ${theme.primaryColor};
      display: flex;
      justify-content: center;
      align-items: center;

      .playBtn {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
`;

export default function UpdateWillModal({
  handleOpenCloseModal,
  isShowModal,
  isAbleToUpdate,
}: {
  handleOpenCloseModal: Function;
  isShowModal: boolean;
  isAbleToUpdate: Function;
}) {
  const userActiveWill = useAppSelector(selectActiveWill);
  const dispatch = useTypedDispatch();

  const createNewWill = async () => {
    // setLoading(true);
    try {
      const {
        data: { createWill: updatedWill },
      }: any = await runMutation({
        mutation: CREATE_USER_WILL,
        variables: {
          key: 'input',
          value: {
            type: WillTypes.PREMIUM_WILL,
            willCompletionProgress: 0,
            documentId: userActiveWill?.documentId,
          } as Will,
        },
      });
      if (updatedWill) {
        dispatch(fetchUserProfileAction());
      }
      return updatedWill;
    } catch (error) {
      // setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        open={isShowModal}
        onClose={() => handleOpenCloseModal()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <p className='heading6'>Update your Will</p>
            <CloseIcon
              style={{ color: '#000', fontSize: '24px', cursor: 'pointer' }}
              onClick={() => handleOpenCloseModal()}
            />
          </Grid>
          <p style={{ margin: '24px 0px', marginBottom: '12px' }}>
            To update your Will, you will need to generate a new Will document and
            follow the steps you took previously to have it signed and witnessed in
            order to make it legally valid once again.
          </p>
          {/* {userActiveWill?.isAmongFirstCustomer &&
            <p style={{ margin: '24px 0px', marginTop: '0px' }}>
              As one of our first 5k users we are happy to inform you that you will not
              be charged for any updates you make; however we recommend that you revisit
              your Will often and continue to update it regularly to ensure it always remains current.
            </p>} */}

          <ButtonWrapper>
            <CustomButton
              text={'Yes, I want update my Will'}
              className='continueWillBtn'
              onClick={async () => {
                await createNewWill();
                isAbleToUpdate();
                handleOpenCloseModal();
              }}
              style={{ width: '290px' }}
            />
            <div className='howItWorks cursor-pointer' style={{ width: '290px' }}>
              <a className='content' onClick={() => handleOpenCloseModal()}>
                <span>Do it later</span>
              </a>
            </div>
          </ButtonWrapper>
        </Box>
      </Modal>
    </div>
  );
}
