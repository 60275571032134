import { styled as muiStyled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import theme from 'styles/theme';
import { AssetType } from 'utils/types';

// import { AssetType, LiabilitySubType } from 'utils/types';

export const HtmlTooltip = muiStyled(({ className, open, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} open={open} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF8DD',
    color: '#101828;',
    minWidth: '200px',
    maxWidth: '320px',
    fontSize: 16,
    border: '1px solid #FFC632;',
    padding: 0,
    // padding: '30px 20px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFF8DD',
    '&:before': {
      border: '1px solid #FFC632',
    },
  },
}));

export const TootipTitle = () => (
  <div>
    <p>
      A financial asset is a liquid asset that gets its value from a contractual right or ownership
      claim. Cash, stocks, bonds, mutual funds, and bank deposits are all good examples of financial
      assets.
    </p>
    <br />
    <p>
      Unlike land, property, commodities, or other tangible physical assets, financial assets do not
      necessarily have inherent physical worth or even a physical form. Rather, their value reflects
      factors of supply and demand in the marketplace in which they trade, as well as the degree of
      risk they carry
    </p>
    <br />
    <p>See Also :</p>
    <br />
    <a
      href='https://www.investopedia.com/terms/f/financialasset.asp'
      style={{ color: theme.tertiaryColor, textDecoration: 'none' }}
    >
      https://www.investopedia.com/terms/f/financialasset.asp
    </a>
  </div>
);

const TooltipWrapper = styled.div`
  height: 350px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 15px;
  /* width: 320px; */
  p {
    margin: 10px 0px;
  }

  strong {
    font-family: Roboto Bold !important;
  }

  ul {
    li {
      margin: 5px 0px;
    }
  }
`;

export const getTooltipTitleByAssetType = (type: AssetType) => {
  if (type === AssetType.IMMOVABLE_PROPERTY)
    return (
      <TooltipWrapper>
        <p>
          Immovable property consists of land and/or building(s) that you own. This can include
          residential, commercial or agricultural property.
        </p>
        <p>
          <strong>Residential property</strong> is a flat, bungalow or any kind of house used for
          dwelling/personal use.
        </p>
        <p>
          <strong>Commercial property</strong> is any space, area, floor or building that is used or
          rented for commercial purposes. E.g. Office, shop, restaurant, hotel, salon, warehouse,
          etc.
        </p>
        <p>
          <strong>Agricultural property</strong> Agricultural property includes farmland,
          plantations and any house constructed on land marked for agricultural use
        </p>
        <p>
          You should not include property that you have rented or leased in your name.
          <strong> Include only </strong>
          property that you own either solely or jointly (i.e. have bought, inherited or been
          gifted)
        </p>
        <p>
          <strong> Expert tip #1: </strong>
          Ideally, the revenue records maintained with the sub-registrar / collector should reflect
          you as the owner. If this is not the case, you may still include it in your will. However
          you should try and have the records mutated in your name in your lifetime, as this will
          simplify inheritance.
        </p>
        <p>
          <strong>Some clarity around unique circumstances</strong>
        </p>
        <p>
          Let’s say you're giving tuition classes in your home or renting it on Airbnb – is this
          commercial property? No, because your home is classified as residential in the revenue
          records. Similarly, if you're growing vegetables in your home garden and even selling
          them, the property doesn't automatically become commercial/agricultural but remains
          residential.
        </p>
        <p>
          Another common situation is when people buy property together, typically married couples.
          Please look at the purchase deed/conveyance document to see if there is any specified
          percentage of division of property. –E.g. the husband owns 30% and wife owns 70%. If not,
          it is assumed that the property is held equally – so if there are 2 owners, each will hold
          50%.
        </p>
        <p>
          <strong> Expert tip #2: </strong>
          If you're ever looking to buy any kind of property with another person(s), it's a good
          idea to mention the percentage each of you owns. This can be anything and is not
          necessarily tied to the amount of money each of you paid for the property. You should
          avoid using terms like ‘jointly held’ or ‘jointly owned’ – there is a common misconception
          that this entails equal ownership in all cases.
        </p>
      </TooltipWrapper>
    );
  if (type === AssetType.BUSINESS)
    return (
      <TooltipWrapper>
        <p>
          Business assets are assets that you own and that are related to your business. This will
          include:
        </p>
        <ul>
          <li>
            Any partnership interest held in an LLP/firm (e.g. if you're a partner in a CA firm), a
            sole proprietorship etc.
          </li>
          <li>Assets you personally own for business purposes, e.g. steel plant or machinery</li>
          <li>Intellectual property rights (IPRs) like copyright, trademark, patents etc.</li>
        </ul>
        <p>
          Although business assets include a combination of both immovable and financial assets,
          business assets should be thought of and planned for separately from your personal assets.
          This is because your business may be sold or liquidated in future, or you may need to
          incur liabilities or take loans against it. Except in the case of a sole proprietorship
          model, your business is treated as a separate entity. E.g. you typically file different
          tax returns for your business and generally treat it as separate from yourself and your
          personal assets.
        </p>
      </TooltipWrapper>
    );
  if (type === AssetType.FINANCIAL)
    return (
      <TooltipWrapper>
        <p>
          This includes liquid assets contained in bank accounts (e.g. savings accounts, fixed
          deposits), demat accounts, equity/preference shares, mutual funds, bonds, debentures,
          portfolio management schemes, insurance policies, provident fund accounts, pension funds,
          ESOPs, and any other kind of investment or cash.
        </p>
        <p>
          These can be in physical form (e.g. physical share certificates or cash) or dematerialised
          form. Anything that represents money, either presently or maturing later or which does not
          have a set monetary value until it is converted into cash, should be included here.
        </p>
        <p>
          <strong>Expert tip: </strong> Joint bank/demat accounts are quite common. If the account
          is held on an ‘either or survivor’/ ‘former or survivor’ basis, then on demise of one
          account-holder, the other joint account-holder(s) shall be entitled to the bank account,
          and not the heirs. It is therefore a good idea to be aware of the bank/financial
          institution’s policy.
        </p>
      </TooltipWrapper>
    );
  if (type === AssetType.OTHER)
    return (
      <TooltipWrapper>
        <p>
          This includes miscellaneous assets. For instance, valuables like art, jewelry, antiques,
          heirlooms, artifacts, furniture (e.g. a precious carpet or desk), or personal effects like
          cars, watches and garments.
        </p>
        <p>
          <strong>Expert tip #1: </strong> For jewelry, it is advised that you itemize each article
          and give a simple description (e.g. gold necklace with 5 rubies) along with an image. For
          art, mention the artist and name of the painting; you can also upload an image. The app
          will automatically create an inventory of this for you. It may also be a good idea, if
          possible, to gift some of your jewelry gradually during your lifetime, especially if
          you're not planning to itemize it. There's no need to mention the value or date of
          purchase for any of these items.
        </p>
        <p>
          If you're the coparcener or karta of an HUF (Hindu Undivided Family), you should bequeath
          your undivided interest in the HUF through the Will. You can’t bequeath the HUF assets
          separately since they are jointly owned by all coparceners with no demarcated share. If
          you have received assets from HUF, either as a distribution or upon partition, that does
          not get covered here as that forms part of your personal / self-acquired property. It’s a
          good idea to dissolve HUFs, however, due to increasing ambiguity in applicable law and
          changing family structures.
        </p>
      </TooltipWrapper>
    );
  return <></>;
};
const RecommendedWrapper = styled.div`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;
export const recommendedMessage = () => {
  return (
    <RecommendedWrapper>
      <div>Available through Premium Will</div>
    </RecommendedWrapper>
  );
};

// const getTooltipTitleByLiabilityType = (type: LiabilitySubType) => {
//   if (type === LiabilitySubType.HOME_LOAN) return <div></div>;
//   if (type === LiabilitySubType.PERSONAL_LOAN) return <div></div>;
//   if (type === LiabilitySubType.VEHICLE_LOAN) return <div></div>;
//   return <></>;
// };
