import { PayloadAction } from "@reduxjs/toolkit";
import { runMutation } from 'utils/graphQL';
import { FETCH_LOGGED_OUT_PLANS_CONFIG, FETCH_PAID_PLANS, INITIATE_CART_PAYMENT, UPDATE_USER_PROFILE, VALIDATE_COUPON } from "common/queries";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { Pages } from "routes";
import { selectActiveWill, selectConfig, selectUserProfile, selectUserStatus } from "store/user/selectors";
import { addToUserStatus, updateActiveWill, updateProfile, UserStatus } from "store/user/slice";
import { runQuery } from "utils/graphQL";
import { InitiateCartPaymentResponse, InitiatePaymentResponse } from "utils/paymentSummaryTypes";
import { PaymentInput, UserProfile, Will, WILL_TYPES } from "utils/types";
import { addYessToCart, fetchPlanResponse, initiatePayment, loadConfig, loadUsersPlans, PaymentsPrerequisites, resetState, selectDOBNRI, selectedPlans, selectPromoCode, setConfigResponse, setDobNri, setPreRequisites, setPricingResponse, setPromoCode, setPurchasedPlans, setSelectedPlans, setUserOnboarding } from ".";
import { ConfigResponse, FetchPaidPlansResponse, Plan } from "./types";
import { MemberDetails } from "Pages/NewPayments/MemberDetailsFormModal";
import { gql } from "@apollo/client";
import { PaymentReason } from "Pages/Payments";

function* reset() {
  yield put(resetState());
}

function* onPlansSelectedByQueryParams(action: PayloadAction<any>) {
  const queryParams = action.payload.params;
  const selectedPlans = queryParams["selectedPlans"];

  if (selectedPlans === undefined) {
    yield put(loadConfig());
    yield put(setPreRequisites(PaymentsPrerequisites.NO_PLANS_SELECTED));
    return;
  }

  const plansArray = selectedPlans.split(",");
  yield put(setSelectedPlans(plansArray));
  yield put(setPreRequisites(PaymentsPrerequisites.PLANS_SELECTED));
  yield put(fetchPlanResponse());
}

function* onUserLoggedOut(action: PayloadAction<UserStatus>) {
  if (action.payload === UserStatus.LOGGED_OUT) {
    yield put(setPreRequisites(PaymentsPrerequisites.NOT_LOGGED_IN));
  }

  if (action.payload === UserStatus.LOGGED_IN) {
    yield put(setPreRequisites(PaymentsPrerequisites.LOGGED_IN));
  }
}

function* onUserNotOnboarded(action: PayloadAction<UserStatus>) {
  if (action.payload !== UserStatus.PROFILE_LOADED) return;

  const userProfile: UserProfile = yield select(selectUserProfile);
  const userStatus: UserStatus[] = yield select(selectUserStatus);

  const isUserProfileIncomplete =
    userStatus.includes(UserStatus.PROFILE_INCOMPLETE) ||
    (userProfile.isNRI === undefined ||
      userProfile.isNRI === null);

  if (isUserProfileIncomplete) {
    yield put(setPreRequisites(PaymentsPrerequisites.NOT_ONBOARDED));
  } else {
    yield put(setPreRequisites(PaymentsPrerequisites.ONBOARDED));
  }
}

function* checkDOBNRIMatch() {
  const userStatus: UserStatus[] = yield select(selectUserStatus);
  const userProfile: UserProfile = yield select(selectUserProfile);
  const plansSelected: Plan[] = yield select(selectedPlans);

  if (userStatus.includes(UserStatus.LOGGED_OUT)) return;
  if (!userStatus.includes(UserStatus.PROFILE_LOADED)) return;

  const isUserProfileIncomplete =
    userStatus.includes(UserStatus.PROFILE_INCOMPLETE) ||
    (userProfile.isNRI === undefined ||
      userProfile.isNRI === null);
  if (isUserProfileIncomplete) return;

  const { dob, isNRI: userIsNRI }: { dob: string, isNRI: boolean } = yield select(selectDOBNRI);


  const activeWill: Will = yield select(selectActiveWill);

  const { isNRI } = userProfile;

  console.log({ isNRI, userIsNRI });
  if (dob === undefined || dob === null || userIsNRI === undefined || userIsNRI === null) {
    yield put(loadConfig());
    return;
  }
  const [date, month, year] = dob.split("/");

  const td = activeWill === null ? new Date(userProfile?.dateOfBirth as string) : new Date(activeWill.testatorDetails?.dateOfBirth as string) ;

  // const td = new Date(activeWill.testatorDetails?.dateOfBirth as string);
  // const td = new Date(userProfile?.dateOfBirth as string);
  // const td = userStatus.includes(UserStatus.WILL_DOC_NOT_CREATED) ? new Date(userProfile?.dateOfBirth as string) : new Date(activeWill.testatorDetails?.dateOfBirth as string);

  const newTDOB = new Date(td.getFullYear(), td.getMonth(), td.getDate()).valueOf();
  const userDOBString = new Date(`${year}/${month}/${date}`).valueOf();
  console.log({ newTDOB, userDOBString });
  if (newTDOB === userDOBString && isNRI === userIsNRI) {
    yield put(loadConfig());
    yield put(fetchPlanResponse());
    yield put(setPreRequisites(PaymentsPrerequisites.NO_DOB_ISNRI_MISMATCH));
    if (plansSelected.length === 0) {
      yield put(setPreRequisites(PaymentsPrerequisites.NO_PLANS_SELECTED));
    } else {
      yield put(setPreRequisites(PaymentsPrerequisites.PLANS_SELECTED));
    }
  } else {
    yield put(setPreRequisites(PaymentsPrerequisites.DOB_ISNRI_MISMATCH));
  }
}

function* onDOBNRI(action: PayloadAction<any>) {
  const queryParams = action.payload.params;
  const dob = queryParams["dob"];
  const isNRI = queryParams["isNRI"];

  if (dob !== undefined && isNRI !== undefined) {
    yield put(setDobNri({ dob, isNri: isNRI === "true" }));
  }
}

function* updateUserProfile(action: PayloadAction<Partial<MemberDetails>>) {
  //@ts-ignore
  const { remainingProfileFields, profile }: any = yield call(updateUserProfileRequest, action.payload);
  const plansSelected: Plan[] = yield select(selectedPlans);
  yield put(updateProfile({ profile, remainingProfileFields }));

  if (remainingProfileFields.length === 0 && (profile.isNRI !== undefined || profile.isNRI !== null)) {
    yield put(loadConfig());
    yield put(fetchPlanResponse());
    yield put(setPreRequisites(PaymentsPrerequisites.ONBOARDED));
    if (plansSelected.length === 0) {
      yield put(setPreRequisites(PaymentsPrerequisites.NO_PLANS_SELECTED));
    } else {
      yield put(setPreRequisites(PaymentsPrerequisites.PLANS_SELECTED));
    }
  }
}

const updateUserProfileRequest = async ({ fullName, dateOfBirth, email, isNRI, mailingAddress }: Partial<MemberDetails>) => {
  try {
    const {
      data: {
        updateUserProfile: { profile, remainingProfileFields },
      },
    }: {
      data: {
        updateUserProfile: { profile: UserProfile; remainingProfileFields: Array<string> };
      };
    } = await runMutation({
      mutation: UPDATE_USER_PROFILE,
      variables: {
        key: 'input',
        value: {
          ...(fullName ? { fullName: fullName } : {}),
          ...(dateOfBirth ? { dateOfBirth: new Date(dateOfBirth).toISOString() } : {}),
          ...(email ? { email: email } : {}),
          ...((isNRI === undefined || isNRI === null) ? {} : { isNRI: !isNRI }),
          ...(mailingAddress ? { mailingAddress: mailingAddress } : {}),
        },
      },
    });

    console.log("updateUserProfileRequest")
    console.log("updateUserProfileRequest", { profile, remainingProfileFields });


    // Mixpanel.track('Onboarding Completed');
    // Intercom.track('Onboarding Completed');
    //

    return { profile, remainingProfileFields };

    // if (remainingProfileFields.length === 0) {
    //   dispatch(addToUserStatus(UserStatus.ACTION_SET_PROFILE_COMPLETED));
    // }
  } catch (error) {
    console.error({ error });
  }

}

function* onLoadConfig() {
  const { dob, isNRI }: { dob: string, isNRI: boolean } = yield select(selectDOBNRI);

  let mmddyyyyDOB = "";
  if (dob) {
    const [dd, mm, yyyy] = dob.split("/");
    mmddyyyyDOB = `${mm}/${dd}/${yyyy}`;
  }

  const response: ConfigResponse = yield call(() => runQuery({
    query: FETCH_LOGGED_OUT_PLANS_CONFIG,
    input: {
      key: 'input',
      value: {
        dob: mmddyyyyDOB || "10/10/1995",
        isNRI: isNRI || false,
      }
    }
  }));
  yield put(setConfigResponse(response));
  yield put(fetchPlanResponse());
}

export enum MainPlanModules {
  WILL = 'WILL',
  UNLIMITED_UPDATES = 'UNLIMITED_UPDATES',
  SMART = 'SMART',
  LAWYER_CALL = 'LAWYER_CALL',
  SHIPPING_AND_COURIER = 'SHIPPING_AND_COURIER',
  YESS = 'YESS'
}

export const planModuleToPlanId = {
  "lawyer_call": MainPlanModules.LAWYER_CALL,
  "smart": MainPlanModules.SMART,
  "unlimited_updates": MainPlanModules.UNLIMITED_UPDATES,
  "will": MainPlanModules.WILL,
  "shippingAndCourier": MainPlanModules.SHIPPING_AND_COURIER,
  "yess": MainPlanModules.YESS
}

function* onFetchPlanResponse() {
  const plansSelected: Plan[] = yield select(selectedPlans);

  if (plansSelected.length === 0) return;
  yield put(setPreRequisites(PaymentsPrerequisites.SUCCESS));

  const PLAN_IDS = ["starter", "pro"];
  const modules = plansSelected.filter(plan => !PLAN_IDS.includes(plan.id));
  const plans = plansSelected.filter(plan => PLAN_IDS.includes(plan.id));

  const cartItemsInput = plans.map(plan => ({
    itemId: plan.id,
    itemType: "PLAN"
  }));

  const modulesInput = modules.map(mod => ({
    //@ts-ignore
    id: planModuleToPlanId[mod.id],
    validityInYears: 1,
    useCount: mod.id === "smart" ? -1 : 1,
    enabled: true
  }));

  //@ts-ignore
  const willPrice : any = yield select(selectConfig);

  const promoCode: string | undefined = yield select(selectPromoCode);

  const couponInput = {
    couponCode: promoCode,
    couponType: "YELLOW_PLUS"
  }

  try {
    if(modulesInput.length === 1 && modulesInput[0].id === MainPlanModules.WILL){
      const userProfile: UserProfile = yield select(selectUserProfile);

      let willUpdatePrice = 1;
      if(promoCode !== undefined){

        // @ts-ignore
        const sendDataToServer: any = yield call(() => runMutation({
          mutation: VALIDATE_COUPON,
          variables: {
            key: 'input',
            value: promoCode as string,
          },
        }));
        const { discount } = sendDataToServer.data.validateCouponByCode;
        const calcDiscountAmount = Math.round((willPrice!!.configs.mobilePlans['Update Premium Will'].basePrice / 100) * parseFloat(discount));

        willUpdatePrice = willUpdatePrice + (willPrice!!.configs.mobilePlans['Update Premium Will'].basePrice - calcDiscountAmount) * 1.18;
      }
      else{
        willUpdatePrice = willPrice!!.configs.mobilePlans['Update Premium Will'].grossAmount;
      }
      const currentWillId = userProfile.wills[userProfile.wills.length - 1].isActive ? userProfile.wills[userProfile.wills.length - 1].will_id : null;
      const cartResponse: InitiatePaymentResponse = yield call(() => runMutation({
        mutation: gql`
        mutation initiatePayment($input:PaymentInput!){
          initiatePayment(input:$input){
            orderId
            receiptId
          }
        }`,
        variables: {
          key: "input",
          value: {
            amount: willUpdatePrice * 100,
            willType: WILL_TYPES.PREMIUM_WILL,
            willId: currentWillId,
            paymentReason: 'Will Update',
            couponCode: promoCode ? promoCode : undefined
          }
        }
      }));
      console.log({ modules, plans });
      console.log({ cartResponse });
      yield put(setPricingResponse(cartResponse));
    }else{
      const cartResponse: InitiateCartPaymentResponse = yield call(() => runMutation({
        mutation: INITIATE_CART_PAYMENT,
        variables: {
          key: "input",
          value: {
            cartItemsInput,
            modulesInput,
            couponInput: promoCode ? couponInput : undefined
          }
        }
      }));
      console.log({ modules, plans });
      console.log({ cartResponse });
      yield put(setPricingResponse(cartResponse));
    }
  } catch (exc) {
    console.error(exc);
    if(modulesInput.length === 1 && modulesInput[0].id === MainPlanModules.WILL){
      const userProfile: UserProfile = yield select(selectUserProfile);
      const currentWillId = userProfile.wills[userProfile.wills.length - 1].isActive ? userProfile.wills[userProfile.wills.length - 1].will_id : null;
      const cartResponse: InitiatePaymentResponse = yield call(() => runMutation({
        mutation: gql`
        mutation initiatePayment($input:PaymentInput!){
          initiatePayment(input:$input){
            orderId
            receiptId
          }
        }`,
        variables: {
          key: "input",
          value: {
            amount: willPrice!!.configs.mobilePlans['Update Premium Will'].grossAmount * 100,
            willType: WILL_TYPES.PREMIUM_WILL,
            willId: currentWillId,
            paymentReason: 'Will Update',
            couponCode: promoCode ? promoCode : undefined
          }
        }
      }));
      console.log({ modules, plans });
      console.log({ cartResponse });
      yield put(setPricingResponse(cartResponse));
    }else{
      const cartResponse: InitiateCartPaymentResponse = yield call(() => runMutation({
        mutation: INITIATE_CART_PAYMENT,
        variables: {
          key: "input",
          value: {
            cartItemsInput,
            modulesInput,
            couponInput: undefined
          }
        }
      }));
      if(cartResponse?.data?.initiateCartPayment?.couponDiscountAmount){
        cartResponse.data.initiateCartPayment.couponApplicationError = "-1";
        yield put(setPricingResponse(cartResponse));
      }
    }
  }
}

function* onInitiatePayment() {
  const latestWill: Will = yield select(selectActiveWill);
  // console.log('ns - latestWill - saga: ', latestWill);
  const updatedWill: Will = {
    ...latestWill,
    paymentInfo: {
      paidWill: true,
      paymentId: "abcd",
      gracePeriod: ""
    }
  }
  // console.log('ns - updatedWill - saga: ', updatedWill);
  yield put(updateActiveWill({ will: updatedWill }));
  try {
    yield call((documentId: string) => runMutation({
      mutation: gql`
          mutation initiatePayment($input: PaymentInput!) {
            initiatePayment(input: $input) {
              orderId
              receiptId
            }
          }
        `,
      variables: {
        key: 'input',
        value: {
          amount: 0,
          willType: WILL_TYPES.PREMIUM_WILL,
          willId: documentId,
          paymentReason: PaymentReason.WILL_GENERATION,
        } as PaymentInput,
      },
    }), latestWill.documentId
    );
  } catch {
    console.log("Nothing here");
  }

  yield put({ type: "FETCH_USER_PROFILE" });
}

function* onLoadUsersPlans() {
  const paidPlansResponse: FetchPaidPlansResponse = yield call(() => runQuery({ query: FETCH_PAID_PLANS }));
  yield put(setPurchasedPlans(paidPlansResponse.data.getPaidItems));
}

function* onPlansSelected() {
  yield put(setPreRequisites(PaymentsPrerequisites.SUCCESS));
  yield put(fetchPlanResponse());
}

function* onLocationChanged(action: PayloadAction<any>) {
  if (action.payload.trackedPageName !== Pages.PAYMENT) {
    yield reset();
    return;
  }

  yield onPlansSelectedByQueryParams(action);
  yield onDOBNRI(action);
  // yield onPaymentPageLoaded(action);
}

function* onAddToUserStatusChanged(action: any) {
  yield onUserLoggedOut(action);
  yield onUserNotOnboarded(action);
  yield checkDOBNRIMatch();
}

export default function* paymentsSaga() {
  yield takeLatest(setSelectedPlans.type, onPlansSelected);
  yield takeLatest("LOCATION_CHANGED", onLocationChanged);
  yield takeLatest(addToUserStatus.type, onAddToUserStatusChanged);
  yield takeLatest(loadConfig.type, onLoadConfig);
  yield takeLatest(fetchPlanResponse.type, onFetchPlanResponse);
  yield takeLatest(setUserOnboarding.type, updateUserProfile);
  yield takeLatest(addYessToCart.type, onPlansSelected);
  yield takeLatest(setPromoCode.type, onFetchPlanResponse);
  yield takeLatest(initiatePayment.type, onInitiatePayment);
  yield takeLatest(loadUsersPlans.type, onLoadUsersPlans);
}

