import { useState, useRef } from 'react';
import { Grid } from '@mui/material';
import Header from '../../../../common/Header';
import { DashboardWrapper } from '../../../Dashboard/Dashboard';
import { WillSigningIntroductoryPage } from './WillSigningIntroductoryPage';
import { WillSigningUploadingPage } from './WillSigningUploadingPage';
import MuiDrawer from '../../../../common/Reusables/Drawer';
import { AssetSubTypesWrapper, HelpWrapper, QueryHelpCard } from '../../NewMyWill';
import OverlayHead from '../../CreateWill/Steps/OverlayHead';
import book from '../../../../assets/svg/book.svg';
import contact from '../../../../assets/svg/menuItem-contact.svg';
import { Intercom } from '../../../../utils/intercom';
import Loading from '../../../../common/Loading';
import isBoolean from 'lodash.isboolean';
import { useNavigate } from 'react-router-dom';
import helpIcon from '../../../../assets/images/willStepsHelpIcon.png';
import { Mixpanel } from '../../../../utils/mixpanel';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation } from 'react-router-dom';
import Navbar from 'Pages/Home/Navbar';
import { Pages } from 'routes';
import { useVendor } from 'common/useVendor';

export default function WillSigningVideoFlow({
  showNotifyOthersOverlay,
  showFuneralArrangements,
}: {
  showNotifyOthersOverlay: Function;
  showFuneralArrangements: Function;
}) {
  const themew = useTheme();
  const lessThanSmall = useMediaQuery(themew.breakpoints.down('sm'));

  const navigate = useNavigate();
  const headerRef = useRef(null);
  const [isUploadingVideo, setIsUploadingVideo] = useState<boolean>(false);
  const [showInfoScreen, setShowInfoScreen] = useState<boolean>(false);
  const [justCompletedUploading, setJustCompletedUploading] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<string | boolean>(false);

  const search = new URLSearchParams(useLocation().search);
  const { isAcko } = useVendor();

  const renderHelpIcon = () => {
    const onHelpClickRightSide = () => {
      setShowInfoScreen(true);
      Mixpanel.track('Question mark button clicked', {
        card_name: 'Will signing',
      });
    };

    return lessThanSmall ? (
      <HelpOutlineIcon onClick={onHelpClickRightSide} />
    ) : (
      <img
        style={{ height: '60px', cursor: 'pointer' }}
        src={helpIcon}
        alt='Help Icon'
        onClick={onHelpClickRightSide}
      />
    );
  };
  return (
    <>
      <>
        <DashboardWrapper>
          <Grid
            container
            sm={12}
            className='container'
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              height: '100vh',
              width: '100vw',
            }}
            alignItems={'stretch'}
          >
            <Grid
              ref={headerRef}
              lg={12}
              sm={12}
              className={`${lessThanSmall ? 'w-100 p-16px' : 'navbar'}`}
              style={{
                height: 'min-content',
                borderBottom: lessThanSmall ? 'none' : '2px solid rgba(128,128,128,0.2)',
              }}
            >
              <div className={'hide_in_mobile'}>
                <Navbar backgroundColorClass='background_white' />
              </div>
              <div className={'show_in_mobile p-16px'}>
                <Header
                  backgroundColorClass={'background_white'}
                  title={'Will Signing Video'}
                  backButton={true}
                  backButtonOnClick={() => navigate(Pages.MY_WILL)}
                  // @ts-ignore
                  centerItems={true}
                  renderRightHandSide={renderHelpIcon()}
                  hideBackNavigation={search.get('hideBackNavigation') === 'true'}
                />
              </div>
              {/* <Header */}
              {/*   backgroundColorClass={'background_white'} */}
              {/*   // @ts-ignore */}
              {/*   title={'Will Signing Video'} */}
              {/*   backButton={true} */}
              {/*   backButtonOnClick={() => navigate(Pages.MY_WILL)} */}
              {/*   centerItems={true} */}
              {/*   // @ts-ignore */}
              {/*   renderRightHandSide={renderHelpIcon()} */}
              {/* /> */}
            </Grid>
            <Grid
              container
              lg={12}
              sm={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                // @ts-ignore
                height: `calc(100% - ${headerRef?.current?.offsetHeight || 70}px)`,
              }}
            >
              {isUploadingVideo ? (
                <WillSigningUploadingPage
                  setIsUploadingVideo={setIsUploadingVideo}
                  setJustCompletedUploading={setJustCompletedUploading}
                  renderHelpIcon={renderHelpIcon}
                />
              ) : (
                <WillSigningIntroductoryPage
                  setIsUploadingVideo={setIsUploadingVideo}
                  showNotifyOthersOverlay={showNotifyOthersOverlay}
                  showFuneralArrangements={showFuneralArrangements}
                  justCompletedUploading={justCompletedUploading}
                  setJustCompletedUploading={setJustCompletedUploading}
                  setShowLoading={setShowLoading}
                  renderHelpIcon={renderHelpIcon}
                />
              )}
            </Grid>
          </Grid>
        </DashboardWrapper>
        <MuiDrawer
          open={showInfoScreen}
          hideDrawer={() => setShowInfoScreen(false)}
          width={'min(100%, 500px)'}
        >
          <AssetSubTypesWrapper>
            <OverlayHead
              heading='Record the signing of your Will'
              onClick={() => setShowInfoScreen(false)}
              setShowHelp={() => setShowInfoScreen(true)}
            />
            <p className='notify-help-text'>
              Although this step is optional, in the event that your Will is contested, a record of
              the signing of your Will in the presence of Witnesses is an important piece of
              evidence that can provide proof of the contents of the Will in question, as well as
              your state of mind at the time of signing
            </p>
          </AssetSubTypesWrapper>
          <HelpWrapper container>
            {!isAcko && (
              <Grid
                item
                lg={12}
                className='queries_section'
                display={'flex'}
                flexDirection='column'
                justifyContent='space-around'
                alignItems={'center'}
              >
                <p className='subText font-semi-bold'>Have a specific query in mind?</p>
                <QueryHelpCard
                  icon={book}
                  text='Explore FAQs'
                  onClick={() => window.open('https://getyellow.in/faq', '_blank')}
                />
                <QueryHelpCard
                  icon={contact}
                  text='Contact Us'
                  onClick={Intercom.hideOverLayAndShowIntercom({
                    setShowOverlay: setShowInfoScreen,
                  })}
                />
              </Grid>
            )}
          </HelpWrapper>
        </MuiDrawer>
        {showLoading && (
          <Loading backgroundColor={isBoolean(showLoading) ? 'rgba(0, 0, 0, 0.8)' : showLoading} />
        )}
      </>
    </>
  );
}
