import { Grid } from '@mui/material';
import { useAppSelector } from '../../../../../store/hooks';
import { selectActiveWill } from '../../../../../store/user/selectors';
import { Will } from '../../../../../utils/types';
import { WillSigningIntroductoryFirstLeftPane } from './WillSigningIntroductoryFirstLeftPane';
import { WillSigningIntroductoryFirstRightPane } from './WillSigningIntroductoryFirstRightPane';
import CongratulationsJustCompletedWindow from './CongratulationsJustCompletedWindow';
import { useEffect, useState } from 'react';
import { SigningDocuments } from '../../../../../utils/willsigningDocumentsType';
import hash from 'object-hash';
import isEqual from 'lodash.isequal';
import { runQuery } from '../../../../../utils/graphQL';
import { GET_LAST_UPLOADED_VIDEO } from '../../../../../common/queries';
import to from 'await-to-js';
export const WillSigningIntroductoryPage = ({
  setIsUploadingVideo,
  showNotifyOthersOverlay,
  showFuneralArrangements,
  justCompletedUploading,
  setJustCompletedUploading,
  setShowLoading,
  renderHelpIcon,
}: {
  setIsUploadingVideo: Function;
  showNotifyOthersOverlay: Function;
  showFuneralArrangements: Function;
  justCompletedUploading: boolean;
  setJustCompletedUploading: Function;
  setShowLoading: Function;
  renderHelpIcon: Function;
}) => {
  const activeWill = useAppSelector(selectActiveWill) as unknown as Will;
  const [signingDocuments, setSigningDocuments] = useState<SigningDocuments | undefined>(
    activeWill?.signingDocuments,
  );
  const [lastWillWithVideo, setLastWillWithVideo] = useState<Will | undefined>(
    activeWill?.signingDocuments ? activeWill : undefined,
  );
  /*{ videoURL: 'a' } || */ const videoIsAdded = !!signingDocuments?.videoURL;
  useEffect(() => {
    (async () => {
      if (
        activeWill?.signingDocuments &&
        !isEqual(activeWill?.signingDocuments, signingDocuments)
      ) {
        setSigningDocuments(activeWill?.signingDocuments);
      } else {
        setShowLoading('#FFF');
        const { data: { getLatestWillWithSigningVideo = undefined } = {} } =
          (await to(runQuery({ query: GET_LAST_UPLOADED_VIDEO })))[1] || {};
        setShowLoading(false);
        if (getLatestWillWithSigningVideo) {
          setLastWillWithVideo(getLatestWillWithSigningVideo);
          setSigningDocuments(getLatestWillWithSigningVideo?.signingDocuments);
        }
      }
    })();
  }, [activeWill?.signingDocuments ? hash(activeWill.signingDocuments) : undefined]);
  return (
    <>
      <Grid
        lg={videoIsAdded ? 12 : 7.5}
        md={videoIsAdded ? 12 : 7.5}
        sm={12}
        className='content background-fcfcfc'
        style={{
          backgroundColor: '#fcfcfc',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: 0,
        }}
      >
        <WillSigningIntroductoryFirstLeftPane
          signingDocuments={signingDocuments}
          setSigningDocuments={setSigningDocuments}
          showNotifyOthersOverlay={showNotifyOthersOverlay}
          showFuneralArrangements={showFuneralArrangements}
          setIsUploadingVideo={setIsUploadingVideo}
          lastWillWithVideo={lastWillWithVideo}
          setLastWillWithVideo={setLastWillWithVideo}
          setShowLoading={setShowLoading}
          renderHelpIcon={renderHelpIcon}
        />
      </Grid>
      {!videoIsAdded && (
        <Grid
          lg={4.5}
          md={4.5}
          sm={12}
          xs={12}
          className='planSection '
          style={{
            backgroundColor: '#e7e9ea',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <WillSigningIntroductoryFirstRightPane setIsUploadingVideo={setIsUploadingVideo} />
        </Grid>
      )}
      <CongratulationsJustCompletedWindow
        isOpenModal={justCompletedUploading}
        handleModalClose={() => setJustCompletedUploading(false)}
      />
    </>
  );
};
