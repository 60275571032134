import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import styled from 'styled-components';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { ActionsWrapper, EachAccessLinkWrapper, ActionsWrapperElementsRow, HorizontalLine } from 'Pages/MyWill/NewMyWill';
import modifyWill from 'assets/svg/modifyWill.svg';
import { Pages } from 'routes';
import { useNavigate } from 'react-router-dom';
// import PrintAndCourier from 'Pages/MyWill/PostWillGeneration/PrintAndCourier';
// import theme from 'styles/theme';
import medicalCertificate from 'assets/svg/med_certificate.svg';
import instructions from 'assets/svg/instruction.svg';
import medicalFitnessCertificate from 'assets/Medical-Fitness-Certificate.pdf';
import yellowBooklet from 'assets/Yellow-Instruction-Booklet.pdf';

const AccordianWrapper = styled(Grid)`
  //border-top: 1px solid #e4e4e4;
  margin: 0;
  padding: 0;
  margin-top: 12px;
  box-sizing: border-box;
  margin-bottom: 50px;

  .accordian-styles {
    margin: 0;
    box-shadow: none;
    padding: 0;
  }
`;


export default function OthersAccordian({
  setShowWillSigningPage,
  setShowPrintPage,
  setIsUpdateLocation,
}: {
  setShowWillSigningPage: Function;
  setShowPrintPage: Function;
  setIsUpdateLocation: Function;
}) {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const navigate = useNavigate();
  return (
    <AccordianWrapper>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded((prev) => !prev)}
        className='accordian-styles'
        sx={{
          '&.MuiAccordion-root': {
            // backgroundColor: 'red',
            padding: '0px !important',
            margin: '0px !important',
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx = {{padding: '0px !important'}}
          // style={{padding: '0px !important', bgcolor: 'red'}}
        >
          <ActionsWrapperElementsRow
            alignItems='center'
            justifyContent={'start'}
            width='100%'
          >
            <p className="font-semi-bold"
            style={{ color: '#646464', fontSize: '13px', margin: '8px 0px', whiteSpace: 'nowrap' }}>
              Other Actions
            </p>
            <HorizontalLine />
          </ActionsWrapperElementsRow>
        </AccordionSummary>
        <AccordionDetails style={{ margin: 0, padding: 0 }}>
          <ActionsWrapper>
            <ActionsWrapperElementsRow>
              <EachAccessLinkWrapper
                lg={2}
                display='flex'
                flexDirection={'column'}
                alignItems='center'
                justifyContent={'center'}
                onClick={() => {
                  navigate(Pages.WILL_EDITOR, { replace: false });
                }}
              >
                <img src={modifyWill} alt='Modify Will' />
                <p 
                // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                >
                  Modify Will</p>
              </EachAccessLinkWrapper>
              <EachAccessLinkWrapper
                lg={2}
                display='flex'
                flexDirection={'column'}
                alignItems='center'
                justifyContent={'center'}
              >
                <a
                  href={medicalFitnessCertificate}
                  target='_blank'
                  download
                  style={{ color: '#000', textAlign: 'center' }}
                >
                  <img src={medicalCertificate} alt='Medical Certificate' />
                  <p 
                  // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                  >
                    Medical Certificate
                  </p>
                </a>
              </EachAccessLinkWrapper>

              <EachAccessLinkWrapper
                lg={2}
                display='flex'
                flexDirection={'column'}
                alignItems='center'
                justifyContent={'center'}
              >
                <a
                  href={yellowBooklet}
                  target='_blank'
                  download
                  style={{ color: '#000', textAlign: 'center' }}
                >
                  <img src={instructions} alt='Instruction Booklet' />
                  <p 
                  // style={{ fontSize: '13px', textAlign: 'center', marginTop: '8px' }}
                  >
                    Instruction Booklet
                  </p>
                </a>
              </EachAccessLinkWrapper>
            </ActionsWrapperElementsRow>
          </ActionsWrapper>
        </AccordionDetails>
      </Accordion>
    </AccordianWrapper>
  );
}
