import styled from 'styled-components';

import { breakpoints } from 'styles/Breakpoints';
import theme from 'styles/theme';
import { useAppSelector } from 'store/hooks';
// import { selectUserProfile } from 'store/user/selectors';
import { useDispatch } from "react-redux";
import {
  selectConfig,
  addYessToCart
} from "store/payments";

import CustomButton from 'common/Reusables/Button';
import { Plan } from 'store/payments/types';
import yesSupport from 'assets/svg/pricingFlow/payment_cart_yes_support.svg';
import { useState } from 'react';
import YessDetailsModal from 'Pages/YessPlus/QuickCart/YessDetailsModal';
import { useNavigate } from 'react-router-dom';
import { Pages } from 'routes';

const YessSupportWrapper = styled.div`
  // width:100%;
  padding: 1.25rem 1rem;
  background: #EDEDED;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 0.75rem;
  border-radius: 12px;

  @media screen and (max-width: ${breakpoints.sm}) {
    .yessLogo{
      display: none;
    }
  }

  .yessSupportContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    // gap: 0.5rem;

    @media screen and (max-width: ${breakpoints.sm}) {
      gap: 1rem;
    }

    .header{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // gap: 4.5rem;

      // @media screen and (max-width: ${breakpoints.sm}) {
      //   flex-direction: column;
      //   align-items: start;
      //   gap: 1rem;
      // }

      .headerLeft{
        width: 75%;
        display:flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        // gap: 13.625rem;

        @media screen and (max-width: ${breakpoints.sm}) {
          width: auto;
          flex-direction: column;
          align-items: start;
          gap: 0.5rem;
        }
      }

      .headerRight{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // gap: 4.5rem;

        // @media screen and (max-width: ${breakpoints.sm}) {
        //   flex-direction: column;
        //   align-items: start;
        //   gap: 0.5rem;
        // }
      }

      h2{
        color: ${theme.pricingHeadingColor};
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.375rem; /* 22px*/
      }
  
      p{
        color: ${theme.pricingHeadingColor};
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
  
        span{
          font-weight: 400;

          @media screen and (max-width: ${breakpoints.sm}) {
            font-size: 0.75rem;
          }
        }
      }
  
      .addButton{
        width: 6.8rem;
        height: auto !important;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;

        @media screen and (max-width: ${breakpoints.sm}) {
          width: auto;
          padding: 0.5rem 1.5rem;
        }
      }
    }

    .text{
      width: 45%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media screen and (max-width: ${breakpoints.sm}) {
        width: auto;
      }

       p{
        color: rgba(16, 24, 40, 0.64);
        font-family: Roboto;
        font-size: 0.875rem !important;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 20px */
       }

       .detailsButton{
        color: #1270E8;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;	
        cursor: pointer;
      }
    }

    
    
  }
`;

const YessSupport = ({
  selectedPlans = [],
  isCart = true
}: {
  selectedPlans?: Plan[],
  isCart?: boolean
}) => {
  const dispatch = useDispatch();
  const yessSelected = selectedPlans.map(pl => pl.id).includes("yess");
  const config = useAppSelector(selectConfig);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const navigate = useNavigate();

  const addYessHandler = () => dispatch(addYessToCart());
  const buyNowHandler = () => navigate(Pages.PAYMENT + "?selectedPlans=yess");

  if (config === undefined && isCart) return <></>;

  // @ts-ignore
  const yessPrice = config?.data.fetchPlanByUserInfo.plans.find(plan => plan.id === 'yess').price.finalPrice.toLocaleString('en-US', {
    style: 'currency', currency: 'INR', currencyDisplay: 'code', minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  // @ts-ignore
  return (
    <YessSupportWrapper>
      {
        showDetailsModal &&
        <YessDetailsModal
          YessPrice={yessPrice || ""}
          setShowDetailsModal={setShowDetailsModal}
          showDetailsModal={showDetailsModal}
        />
      }
      <img className="yessLogo" src={yesSupport} alt="YES Image" />
      <div className="yessSupportContent">
        <div className="header">
          {
            isCart ? (
              <>
                <div className="headerLeft">
                  <h2>Yellow SMART Remote Support</h2>
                  <p>{yessPrice}
                    <span> (Yearly subscription)</span>
                  </p>
                </div>
                <div className="headerRight">
                  <CustomButton
                    style={yessSelected ? {
                      display: 'none'
                    } : {}}
                    text='Add'
                    className='addButton'
                    onClick={addYessHandler}
                  />
                </div>
              </>

            ) : (
              <h2>Yellow SMART Remote Support</h2>
            )
          }
          {/* {
            isCart &&
            <>
              <p>{yessPrice}
                <span> (Yearly subscription)</span>
              </p>
              <div className="headerRight">
                <CustomButton
                  style={yessSelected ? {
                    display: 'none'
                  } : {}}
                  text='Add'
                  className='addButton'
                  onClick={addYessHandler}
                />
              </div>
            </>
          } */}
        </div >
        <div className="text">

          {isCart ?
            <p>Ensures your family get timely information, resources, and support to help them execute your Will easily.</p>
            :
            <p>Ensure your family gets timely information, resources, and support to execute your Will.</p>
          }
          {isCart ?
            <p
              className="detailsButton"
              onClick={() => setShowDetailsModal(true)}
            >
              View details
            </p>
            :
            <p
              className="detailsButton"
              onClick={buyNowHandler}
            >
              Buy Now &rarr;
            </p>
          }
        </div>
      </div >
    </YessSupportWrapper>
  );
};

export default YessSupport;
