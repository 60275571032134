import axios from 'axios';

export const handlePincodeChange = (pincodeData: any) => {
  const {
    pincode,
    setShowLoader,
    clearErrors,
    setPincodeErrorMessage,

    setError,
    setValue,
  } = pincodeData;
  if (pincode !== '') {
    if (pincode.length === 6) {
      setPincodeErrorMessage('');
      setShowLoader(true);
      getCityByPincode(pincode)
        .then((res) => {
          if (res?.city) {
            setShowLoader(false);
            clearErrors('address.city');
            clearErrors('address.state');
            if (setValue) {
              setValue('address.city', res.city);
              setValue('address.state', res.state);
            }
          } else {
            // setPincodeErrorMessage('Invalid pincode');
            // setError('address.pinCode', { type: 'focus' });
            setValue('address.city', '');
            setValue('address.state', '');

            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setPincodeErrorMessage('Pincode must be 6 characters');
      setError('address.pinCode', { type: 'focus' });
      setValue('address.city', '');
      setValue('address.state', '');
    }
  } else {
    setPincodeErrorMessage('This field is required.');
  }
};

export const handlePincodeChangeForShipping = (pincodeData: any) => {
  const {
    pincode,
    setShowLoader,
    clearErrors,
    setPincodeErrorMessage,

    setError,
    setValue,
  } = pincodeData;
  if (pincode !== '') {
    if (pincode.length === 6) {
      setPincodeErrorMessage('');
      setShowLoader(true);
      getCityByPincode(pincode)
        .then((res) => {
          if (res?.city) {
            setShowLoader(false);
            clearErrors('mailingAddress.city');
            clearErrors('mailingAddress.state');
            if (setValue) {
              setValue('mailingAddress.city', res.city);
              setValue('mailingAddress.state', res.state);
            }
          } else {
            // setPincodeErrorMessage('Invalid pincode');
            // setError('mailingAddress.pinCode', { type: 'focus' });
            setValue('mailingAddress.city', '');
            setValue('mailingAddress.state', '');

            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setPincodeErrorMessage('Pincode must be 6 characters');
      setError('mailingAddress.pinCode', { type: 'focus' });
      setValue('mailingAddress.city', '');
      setValue('mailingAddress.state', '');
    }
  } else {
    setPincodeErrorMessage('This field is required.');
  }
};

export const getCityByPincode = async (pin: string) => {
  // for now bypassing the API since the server is not working
  try {
    const response = await axios.get(`https://api.postalpincode.in/pincode/${pin}`, { timeout: 5000 });
    if (response?.data[0]?.PostOffice) {
      const { District, State } = response.data[0]?.PostOffice[0];
      return { city: District, state: State };
    } else {
      return { city: '', state: '' };
    }
  } catch (error) {
    console.log(error);
    return { city: '', state: '' };
  }
};
