import { ConfirmationResult } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const configDev = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(configDev);

export const signInWithPhoneNumber = (phoneNumber: string) => {
  const RECAPTCHA_VERIFIER = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    size: 'invisible',
  });

  return firebase.auth().signInWithPhoneNumber(`${phoneNumber}`, RECAPTCHA_VERIFIER);
};

export const validateOTP = (confirmationResult: ConfirmationResult, otp: string) =>
  confirmationResult.confirm(otp);

export default firebase;
