import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages } from "routes";
import { useAppSelector } from "store/hooks";
import { initiatePayment, selectUserHasPlan } from "store/payments";

export const useRedirectToPaymentOrMyWill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userHasPlan = useAppSelector(selectUserHasPlan);

  return {
    redirectToPaymentOrMyWill: () => {
      if (userHasPlan) {
        (window as any).webengage.track("Generate Will Clicked",{
          "Will Generated": "yes"
        });
        dispatch(initiatePayment());
        setTimeout(() => navigate(Pages.MY_WILL), 1000);
      } else {
        navigate(Pages.PAYMENT, { state: { fromWillEditor: true } });
      }
    }
  }
}
