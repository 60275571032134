import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import { breakpoints } from './Breakpoints';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: Roboto Regular;
    src: url('/Roboto-Regular.ttf');
  }

  @font-face {
    font-family: Roboto Light;
    src: url('/Roboto-Light.ttf');
  }

  @font-face {
    font-family: Roboto Bold;
    src: url('/Roboto-Bold.ttf');
  }

  @font-face {
    font-family: Roboto Medium;
    src: url('/Roboto-Medium.ttf');
  }

  @font-face {
    font-family: Merriweather Bold;
    src: url('/MerriweatherBold.ttf');
  }

  @font-face {
    font-family: Merriweather Regular;
    src: url('/MerriweatherRegular.ttf');
  }

  /* @font-face {
      font-family: General Sans Regular;
      src: url('/GeneralSans-Regular.ttf');
  }

  @font-face {
      font-family: General Sans Semibold;
      src: url('/GeneralSans-Semibold.ttf');
  } */

  * {
    margin: 0;
    /* font-family: Roboto Regular !important; */
    /*font-family: Roboto Regular !important;*/
    font-family: 'Roboto',sans-serif;
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #ffffff;
    scroll-behavior: smooth !important;
  }

  ::-moz-selection {
    color: black;
    background: #FFD662;
  }

  ::selection {
    color: black;
    background: #FFD662;
  }

  .App {
    min-height: 100%;
    display: block;
  }

  #rf-popup-modal-nbgI4X{
    div{
    padding: 0px !important;
  embed{
    height:100vh !important;
  }
  }
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: 1.4;
  }

  .p-7px {
    padding: 7px;
  }

  .p-10px {
    padding: 10px;
  }

  .p-16px {
    padding: 16px !important;
  }

  .p-24px {
    padding: 24px !important;
  }

  .p-32px {
    padding: 32px;
  }

  .pr-8px {
    padding-right: 8px !important;
  }

  .pr-16px {
    padding-right: 16px !important;
  }

  .pr-24px {
    padding-right: 24px !important;
  }
  
  .pr-32px {
    padding-right: 32px !important;
  }

  .pb-5px {
    padding-bottom: 5px !important;
  }
  .pb-10px {
    padding-bottom: 10px !important;
  }
  
  .pb-15px {
    padding-bottom: 15px !important;
  }

  .pb-40px {
    padding-bottom: 40px !important;
  }

  .pb-60px {
    padding-bottom: 60px !important;
  }

  .pb-70px {
    padding-bottom: 70px !important;
  }

  .pb-80px {
    padding-bottom: 80px !important;
  }

  .pb-100px {
    padding-bottom: 100px;
  }

  .border-none{
    border: none;
  }
  
  .border-radius-15px {
    border-radius: 15px;
  }

  .pl-15px {
    padding-left: 15px;
  }

  .pr-24px {
    padding-right: 24px;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-visible {
    overflow: visible;
  }

  .overflow-scroll {
    overflow: scroll;
  }

  .overflow-y-scroll {
    overflow-y: scroll;
  }

  .overflow-auto {
    overflow: auto;
  }

  .resize-both {
    resize: both;
  }

  .hide_scrollbars {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }

  .hide_scrollbars::-webkit-scrollbar {
    display: none !important;
  }

  .flex {
    display: flex !important;
  }
  .grid {
    display: grid !important;
  }

  .flex1 {
    flex: 1;
  }

  .inline {
    display: inline;
  }

  .block {
    display: block;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .none {
    display: none;
  }

  .table {
    display: table;
  }

  .table-row {
    display: table-row;
  }

  .absolute-top-left {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .absolute-top-right {
    position: absolute;
    top: 0;
    right: 0;
  }

  .absolute-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .absoluteFill {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
  }

  .absoluteFillOnly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .fixedFillOnly {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vw;
    width: 100vw;
  }

  .object-fit-cover {
    object-fit: cover;
  }

  .object-fit-contain {
    object-fit: contain;
  }

  .zIndex1 {
    z-index: 1;
  }

  .zIndex2 {
    z-index: 2;
  }
  
  .zIndex20 {
    z-index: 20 !important;
  }

  .relative {
    position: relative;
  }
  
  .fixed {
    position: fixed !important;
  }
  
  .fixed-bottom-0 {
    bottom: 0 !important;
  }
  
  .fixed-left-0 {
    left: 0 !important;
  }
  
  .fixed-right-0 {
    right: 0 !important;
  }

  .flex-row {
    flex-direction: row;
  }


  .flex-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-col {
    flex-direction: column !important;
  }

  .flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-grow {
    flex-grow: 1 !important;
  }

  .flex-basis-content {
    flex-basis: max-content;
  }

  .items-center {
    align-items: center;
  }

  .align-self-center {
    align-self: center;
  }

  .items-flex-end {
    align-items: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  } 
  
  .justify-end {
    justify-content: flex-end;
  }

  .gap-5px {
    gap: 5px;
  }
  
  .gap-8px {
    gap: 8px;
  }

  .gap-10px {
    gap: 10px;
  }
  
  .gap-12px {
    gap: 12px;
  }

  .gap-16px {
    gap: 16px;
  }

  .gap-24px {
    gap: 24px;
  }

  .gap-29px {
    gap: 29px;
  }

  .gap-32px{
    gap: 32px;
  }

  .gap-34px {
    gap: 34px;
  }

  .gap-40px {
    gap: 40px;
  }

  .mt-15px {
    margin-top: 15px
  }

  .mt-40px {
    margin-top: 40px
  }

  .mb-5px {
    margin-bottom: 5px
  }
  
  .mb-10px {
    margin-bottom: 10px
  }
  
  .mb-12px {
    margin-bottom: 12px
  }
  
  .mb-15px {
    margin-bottom: 15px
  }

  .mb-40px {
    margin-bottom: 40px !important;
  }

  .mb-5px {
    margin-bottom: 5px !important;
  }

  .mb-24px {
    margin-bottom: 24px !important;
  }

  .mb-25px {
    margin-bottom: 25px !important;
  }

  .pl-24px {
    padding-left: 24px;
  }

  .pr-4px {
    padding-right: 4px;
  }

  .py-4px {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  
  .py-8px {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .py-16px {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .pt-5px {
    padding-top: 5px;
  }
  
  .pt-8px {
    padding-top: 8px;
  }

  .pt-2px {
    padding-top: 2px;
  }

  .pt-15px {
    padding-top: 15px;
  }

  .pt-25px {
    padding-top: 25px;
  }

  .pt-40px {
    padding-top: 40px;
  }

  .pt-60px {
    padding-top: 60px;
  }

  .headerFeatureTextBackgroundColor {
    background-color: #FFDB5D;
  }

  .headerFeatureTextBorder {
    border: 2px solid #FFDB5D;
  }

  .headerFeatureTextBottomColor {
    background-color: #FFC920;
  }

  .bg-amber_400 {
    background-color: rgb(251 191 36);
  }

  .bg-gray_200 {
    --tw-bg-opacity: 1;
    background-color: rgb(229 231 234 / var(--tw-bg-opacity));
  }

  .bg-bluegray_900_51 {
    background-color: #1d293951;
  }

  .rounded-radius0 {
    border-radius: 0px;
  }
  
  .rounded-radius24 {
    border-radius: 24px;
  }

  .rounded-radius-bottom0 {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rounded-radius-top0 {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .rounded-radius12 {
    border-radius: 12px;
  }
  
  .rounded-radius50p {
    border-radius: 50%;
  }

  .rounded-radius4 {
    border-radius: 4px;
  }

  .unselectable {
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .w-100 {
    width: 100% !important;
  }

  .w-90 {
    width: 90% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-40 {
    width: 40% !important;
  }

  .w-33 {
    width: 33% !important;
  }

  .w-67 {
    width: 67% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .h-50 {
    height: 50% !important;
  }

  .h-33 {
    height: 33% !important;
  }

  .max-w-100 {
    max-width: 100% !important;
  }

  .min-w-100 {
    min-width: 100% !important;
  }

  .max-w-70 {
    max-width: 70% !important;
  }
  
  .max-w-60 {
    max-width: 60% !important;
  }
  
  .max-w-50 {
    max-width: 50% !important;
  }
  
  .max-w-80 {
    max-width: 80% !important;
  }

  .max-w-30 {
    max-width: 30% !important;
  }

  .border-box {
    box-sizing: border-box !important;
  }

  .h-100 {
    height: 100% !important;
  }  
  
  .h-100vh {
    height: 100vh !important;
  }

  .h-auto {
    height: auto;
  }

  .h-max-content {
    height: max-content;
  }
  
  .h-min-content {
    height: min-content;
  }

  .w-33 {
    width: 33%;
  }

  .w-80px {
    width: 80px;
  }

  .h-1px {
    height: 1px;
  }

  .h-0px {
    height: 1px;
  }

  .w-1px {
    width: 1px;
  }

  .h-12px {
    height: 12px;
  }

  .h-16px {
    height: 16px;
  }
  
  .h-24px {
    height: 24px;
  }
  
  
  .h-32px {
    height: 32px;
  }

  .h-100px {
    height: 100px !important;
  }
  
  .w-100px {
    width: 100px !important;
  }
  
  .h-200px {
    height: 200px !important;
  }

  .w-32px {
    width: 32px !important;
  }
  
  .w-12px {
    width: 12px !important;
  }
  
  .w-16px {
    width: 16px !important;
  }

  .menuIcon {
    height: 20px !important;
    width: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .min-h-100 {
    min-height: 100%;
  }

  .min-h-80 {
    min-height: 80%;
  }

  .min-h-70px {
    min-height: 70px;
  }

  .min-h-50px {
    min-height: 50px;
  }

  .min-h-40px {
    min-height: 50px;
  }
  
  .min-h-16px {
    min-height: 16px;
  } 
  
  .min-w-16px {
    min-width: 16px;
  }
  
  .min-w-32px {
    min-width: 32px;
  }
  
  .min-h-32px {
    min-height: 32px;
  }

  .max-w-808px {
    max-width: 808px;
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .font-semibold {
    font-weight: 600;
  }
  
  .nowrap{
    white-space: nowrap;
  }
  
 .pre{
    white-space: pre;
  }

  .text-28px{
    font-size: 1.75rem !important;
  }

  .text-16px {
    font-size: 16px;
  }

  .text-align-justify {
    text-align: justify
  }

  .text-14px {
    font-size: 14px;
  }

  .text-white {
    color: white;
  }
  
  .text-black {
    color: black;
  }

  .text-transparent {
    color: transparent;
  }

  .background-transparent {
    background-color: transparent;
  }
  
  .transparent-button{
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  }

  .text-blue_500 {
    //color: #0D7BB9 !important;
    color: #1270E8 !important;
    font-weight: 500;
  }

  .text-blue_700 {
    //color: #0D7BB9 !important;
    color: #1270E8 !important;
    font-weight: 600;
  }
  
  .text-renews-at-blue{
    color: #17449B;
  }

  .text-darkergray {
    color: rgba(16, 24, 40, 0.64);
  }

  .text-bluegray_900 {
    color: rgb(29 41 57 / var(--tw-text-opacity));
  }

  .text-bluegray_900_51 {
    color: rgb(29 41 51 / var(--tw-text-opacity));
  }

  .text-alphagray {
    // color: rgba(29, 41, 57, 0.32) !important;
    color: #767B85;
  }

  .text-gray_900_70 {
    color: #10182870;
  }

  .text-gray_900_7a {
    color: #1018287a;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .underline {
    text-decoration-line: underline;
  }

  .w-auto {
    width: auto !important;
  }
  
  .w-80 {
    width: 80%;
  } 
  
  .w-66 {
    width: 66%;
  }
  
  .w-50 {
    width: 50%;
  }

  .w-fit-content {
    width: fit-content;
  }
  
  .w-fit-content-80 {
    width: fit-content(80%);
  }
  
  .w-min-content {
    width: min-content !important;
  }

  .h-fit-content {
    height: fit-content;
  }

  .h-32px {
    height: 32px !important;
  }

  .h-24px {
    height: 24px;
  }

  .h-16px {
    height: 16px !important;
  }

  .w-24px {
    width: 24px;
  }

  .h-12px {
    height: 12px;
  }

  .w-12px {
    width: 12px;
  }

  .h-3px {
    height: 3px;
  }

  .w-3px {
    width: 3px;
  }

  .h-250px {
    height: 250px;
  }

  .w-250px {
    width: 250px;
  }
  
  .m-0{
    margin:0 !important;
  }

  .m-5p {
    margin: 5%;
  } 
  
  .m-5px {
    margin: 5px;
  }

  .m-8px {
    margin: 8px;
  }
  
  .m-12px {
    margin: 12px;
  }

  .m-24px {
    margin: 24px;
  }

  .my-24px {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .my-44px {
    margin-top: 44px;
    margin-bottom: 44px;
  }

  .mb-47px {
    margin-bottom: 47px;
  }

  .mt-20 {
    margin-top: 20%;
  }
  
  .mt-12px {
    margin-top: 12px;
  }

  .mb-10px {
    margin-bottom: 10px;
  }
  
  .mb-5px {
    margin-bottom: 5px;
  }

  .pl-56px {
    padding-left: 56px;
  }

  .py-56px {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  .py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .pl-24px {
    padding-left: 24px;
  }
  
  .pl-16px {
    padding-left: 16px;
  }
  
  .pl-10px {
    padding-left: 10px;
  }
  
  .pl-8px {
    padding-left: 8px;
  }

  .py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .px-32px {
    padding-right: 32px;
    padding-left: 32px;
  }
  
  .px-24px {
    padding-right: 24px;
    padding-left: 24px;
  }

  .px-64px {
    padding-right: 64px;
    padding-left: 64px;
  }

  .px-8px {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .px-16px {
    padding-right: 16px;
    padding-left: 16px;
  }

  .py-24px {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .h-30px {
    height: 30px;
    max-height: 30px;
  }

  .w-30px {
    width: 30px;
    max-width: 30px;
  }

  .green-dot {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: #41AA58;
  }

  .red-dot {
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: #BA1B1B;
  }

  .bulletedNumerals {
    width: 24px;
    height: 24px;
    background: #FEE071;
    border-radius: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bullerNumeralsText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 5px;
  }

  .text-actual-price-cancelled {
    color: black;
    text-decoration-line: line-through;
    text-decoration-color: red;
  }

  .payments-month-name {
    font-style: normal;
    font-weight: 400;
    font-size: 20px !important;
    line-height: 28px;
    color: #101828;
  }

  .my-account-side-panel-select-tab-border {
    border-right: 8px solid #000000;
  }

  .background-color-active-light-green {
    background: #E7F6EA;
  }

  .background-color-pale-yellow {
    background: #F8F8E4;
  }

  .background-warning-pink {
    background: #FFEEEE;
  }

  .background-discount-teal {
    background: #418D97;
  }

  .background-addon-teal {
    background: #E7F6EA;
  }

  .background-per-day-yellow {
    background: rgb(255, 214, 86);
  }

  .plan-item:hover {
    border: 2px solid #101828;
  }

  .border-selected-plan {
    border: 2px solid #101828;
  }

  .border-unselected-plan-small {
    border: 2px solid white;
  }

  .border-unselected-plan {
    border: 2px solid transparent;
  }

  .text-color-active-light-green {
    color: #41AA58;
  }

  .border-color-active-light-green {
    border: 2px solid #00A388;
  }

  .border-color-inactive-white {
    border: 2px solid white;
  }

  .border-color-black {
    border: 1px solid black;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-color-darkgrey {
    color: #767B85;

  }

  .text-color-brown {
    color: #7D6100;
  }

  .text-color-warning-red {
    color: #BA1B1B;
  }

  .border-separate {
    border-collapse: separate;
  }

  .border-separate {
    border-collapse: separate;
  }

  .border-spacing-0-75rem {
    border-spacing: 0.75rem
  }

  .border-left-none {
    border-left: none;
  }


  /* Backgrounds and Colors */
  .background_semitransparent_blue {
    background: rgba(16, 24, 40, 0.85);
  }

  .background_lightBeige {
    background-color: ${theme.light_beige};
  }

  .background_darkBeige {
    background-color: ${theme.dark_beige};
  }

  .background_white {
    background-color: ${theme.white};
  }

  .background_black {
    background-color: black;
  }

  .background-yellow-brand-yellow {
    background-color: #FFDB5D;
  }

  .background-lightgray {
    background-color: #FCFCFC;
  }

  .background-plans-desktop-gray {
    background-color: #F8F8FB !important;
  }

  .background-darkergray {
    background: #1018280A;
  }

  .background-navyblue {
    background: #101828
  }

  .background_secondary {
    background-color: ${theme.secondaryColor};
  }

  .inputBackground {
    background-color: rgba(71, 84, 103, 0.05);
  }

  .color_primary {
    color: ${theme.primaryColor}
  }

  .color_black {
    color: #000000;
  }

  /* Padding & margin */
  .p-0,
  .padding-0 {
    padding: 0;
  }

  .p-4px {
    padding: 4px;
  }

  .p-8px {
    padding: 8px;
  }
  
  .p-12px {
    padding: 12px;
  }

  .pr-8px {
    padding-right: 8px;
  }

  .pr-16px {
    padding-right: 16px;
  }

  .p-16px {
    padding: 16px;
  }

  .p-24px {
    padding: 24px;
  }
  
  .p-28px {
    padding: 28px;
  }

  .pt-20 {
    padding-top: 20%;
  }

  .margin-0 {
    margin: 0;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-6px {
    margin-top: 6px;
  }

  .mt-16px {
    margin-top: 16px;
  }

  .-translate-y-50p {
    transform: translateY(-75%);
  }

  .mt-32px {
    margin-top: 32px;
  }

  .ml-8px {
    margin-left: 8px;
  }

  .ml-16px {
    margin-left: 16px;
  }

  .ml-24px {
    margin-left: 24px;
  }
  
  .ml-32px {
    margin-left: 32px;
  }

  .-mt-16px {
    margin-top: -16px;
  }

  .mr-8px {
    margin-right: 8px;
  }
  
  .mr-5px {
    margin-right: 5px;
  }

  .mr-24px {
    margin-right: 24px;
  }

  .font-merriweather {
    font-family: "Merriweather Regular" !important;
  }

  .font-roboto {
    font-family: "Roboto Regular" !important;
  }

  .font-roboto-bold {
    font-family: "Roboto Bold" !important;
  }

  .font-roboto-light {
    font-family: "Roboto Light" !important;
  }

  .font-roboto-medium {
    font-family: "Roboto Medium" !important;
  }

  /* Headings */
  .heading1 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading1};
  }

  .heading2 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading2};
  }

  .heading3 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading3};
  }

  .heading4 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading4};
  }

  .heading5 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading5};
  }

  .heading6 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.heading6};
  }

  .heading7 {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-size: ${theme.normal};
  }


  .heading1generic {
    font-size: ${theme.heading1} !important;
  }

  .heading2generic {
    font-size: ${theme.heading2} !important;
  }

  .heading3generic {
    font-size: ${theme.heading3} !important;
  }

  .heading4generic {
    font-size: ${theme.heading4} !important;
  }

  .heading5generic {
    font-size: ${theme.heading5} !important;
  }

  .heading6generic {
    font-size: ${theme.heading6} !important;
  }

  .heading7generic {
    font-size: ${theme.normal} !important;
  }

  .text-24px {
    font-size: 24px;
  } 
  
  .text-20px {
    font-size: 20px !important;
  }
  
  .text-14px {
    font-size: 14px;
  }

  .text-12px {
    font-size: 12px;
  }

  .-rotate-90 {
    rotate: -90deg;
  }

  .rotate-90 {
    rotate: 90deg;
  }

  .rotate-180 {
    rotate: 180deg;
  }

  /* Text Utils */
  .text {
    font-size: ${theme.text};
  }

  .subText {
    font-size: ${theme.subText};
  }

  .xs_text {
    font-size: ${theme.xs_text};
  }

  .text-center {
    text-align: center;
  }
  
  .aspect-ratio-1-1{
    aspect-ratio : 1 / 1 !important;
  }
  
  .w-100vmax{
    width:100vmax;
  }
  
  .w-100vw {
    width: 100vw;
  }
  
  .w-100vw-32px {
    width: calc(100vw - 32px);
  }

  .justify-text {
    text-align: justify;
    text-justify: inter-word;
  }

  .center-justified {
    text-align: justify;
    text-justify: inter-word;
    text-align-last: center;
  }

  /* Font Weights */

  .font-normal {
    font-weight: 200 !important;

  }

  .font-semi-bold {
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-weight: 600;
  }

  .font-light {
    font-weight: 200;
  }

  .font-bold {
    font-weight: 700;
  }

  .font-extra-bold {
    font-weight: 900;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .testament {
    .react-pdf__Page__canvas {
      border-radius: 12px;
    }
  }

  /* Misc */
  .hightlight_word {
    background-color: #FFC632;

    font-size: 20px;
    font-family: Roboto Bold !important;
    /* font-family: Roboto Bold !important; */
    font-weight: 600;
  }

  .mediaController {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    height: 100px;
    object-fit: cover;
  }

  select {
    z-index: 9;
    position: relative;
  }

  .help_icon {
    cursor: pointer;
    height: 45px;
    width: 45px;
    max-height: 45px;
    max-width: 45px;
  }
  
  .force-wrapping {
    word-break: break-word !important;
    white-space: normal !important;
  }
  
  .show_in_mobile_flex,
  .show_in_mobile_inline,
  .show_in_mobile {
    display: none !important;
  }

  .hide_in_mobile {
    display: block  !important;
  }
  
  .hide_in_mobile_flex {
    display: flex  !important;
  }
  
  .single-line{
    line-clamp: 1;
  }
  
  @media screen and (max-width: ${breakpoints.sm}) {
    .hide_in_mobile_flex ,
    .hide_in_mobile {
      display: none !important;
    }
    .show_in_mobile {
      display: block !important;
    }
    .show_in_mobile_flex {
      display: flex !important;
    }
    .show_in_mobile_inline {
      display: inline !important;
    }
  }
  
  .permanentScrollbar {
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    
    scrollbar-width: auto;
    scrollbar-color: #000000 #ffffff;
    

    /* Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }

  button {
    font-size: 16px;
  }

  select {
    -webkit-appearance: none;
    color: #000000;
  }
`;

export default GlobalStyle;
